import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";
import moment from "moment";
import React from "react";
import RobotoBold from "../../Fuentes/Roboto/Roboto-Bold.ttf";
import RobotoRegular from "../../Fuentes/Roboto/Roboto-Regular.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: RobotoRegular,
      fontWeight: "normal",
    },
    {
      src: RobotoBold,
      fontWeight: "bold",
    },
  ],
  ignoreChars: (char) => !/[a-zA-Z0-9]/.test(char),
});

export const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    backgroundColor: "#fff",
  },
  pageA4: {
    fontFamily: "Roboto",
    backgroundColor: "#fff",
    margin: "30 25",
  },
  pageLetter: {
    fontFamily: "Roboto",
    backgroundColor: "#fff",
    margin: "30 25",
  },
  contenedor: {
    display: "table",
    width: "100%",
  },
  contenedorTicket: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    width: "100%",
    height: "100%",
  },
  fila: {
    flexDirection: "row",
  },
  viewProveedorPrecio: {
    width: "100%",
    display: "flex",
    marginBottom: 2,
    flexDirection: "row",
  },
  viewCodeBar: {
    width: "100%",
    height: 20,
    display: "flex",
    marginBottom: 4,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  viewUbicacionFecha: {
    width: "100%",
    display: "flex",
    marginBottom: 2,
    flexDirection: "row",
    alignItems: "center",
  },
});

export const mostrarNombreSucursal = (
  nombreSucursal,
  fontSize,
  maxCharBaseFontSize,
  marginBottom,
) => {
  const elipsis = nombreSucursal.length > maxCharBaseFontSize;

  const textoTruncado = elipsis
    ? nombreSucursal.substring(0, maxCharBaseFontSize - 3) + "..."
    : nombreSucursal;

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginBottom: marginBottom,
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {textoTruncado}
      </Text>
    </View>
  );
};

export const mostrarDivisorDeCodigos = (fontSize) => {
  return (
    <View
      style={{
        flex: 0.2,
        alignItems: "center",
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "normal",
          testAlign: "center",
        }}
      >
        |
      </Text>
    </View>
  );
};

export const mostrarCodigosArticulo = (
  configuracionEtiqueta,
  articulo,
  baseFontSize,
  marginBottom,
) => {
  const codigos = [];
  if (configuracionEtiqueta.codigoProveedor && articulo.codProveedor) {
    codigos.push(articulo.codProveedor);
  }
  if (configuracionEtiqueta.codigoOriginal && articulo.codOriginal) {
    codigos.push(articulo.codOriginal);
  }
  if (configuracionEtiqueta.codigoAuxiliar && articulo.codAuxiliar) {
    codigos.push(articulo.codAuxiliar);
  }

  const fontSize = codigos.length === 3 ? baseFontSize : baseFontSize + 2;

  const format = codigos.length === 3 ? "space-between" : "center";

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: format,
        marginBottom: marginBottom,
      }}
    >
      {codigos.map((codigo, index) => (
        <React.Fragment key={index}>
          <Text
            style={{
              fontSize: fontSize,
              fontWeight: "bold",
            }}
          >
            {codigo}
          </Text>
          {index < codigos.length - 1 && mostrarDivisorDeCodigos(baseFontSize)}
        </React.Fragment>
      ))}
    </View>
  );
};

export const mostrarDescripcionArticulo = (
  descripcion,
  fontSize,
  maxCharsBaseFontSize = 55,
  marginBottom,
) => {
  let textoDescripcion = descripcion;

  if (textoDescripcion.length > maxCharsBaseFontSize) {
    textoDescripcion =
      textoDescripcion.substring(0, maxCharsBaseFontSize - 3) + "...";
  }

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        whiteSpace: "nowrap",
        marginBottom: marginBottom,
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "normal",
          textAlign: "center",
        }}
      >
        {textoDescripcion}
      </Text>
    </View>
  );
};

export const mostrarDescripcionArticuloExtendida = (
  descripcion,
  fontSize,
  maxCharsBaseFontSize = 120,
  maxCharsMidFontSize = 140,
) => {
  let textoDescripcion = descripcion;

  if (
    descripcion.length > maxCharsMidFontSize &&
    descripcion.length <= maxCharsBaseFontSize
  ) {
    textoDescripcion =
      descripcion.substring(0, maxCharsMidFontSize - 3) + "...";
  }
  if (descripcion.length > maxCharsBaseFontSize) {
    textoDescripcion =
      descripcion.substring(0, maxCharsBaseFontSize - 3) + "...";
  }

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        whiteSpace: "nowrap",
        marginBottom: 4,
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "normal",
          textAlign: "center",
        }}
      >
        {textoDescripcion}
      </Text>
    </View>
  );
};

const handleFormatNombreProveedor = (
  nombreProveedor = "",
  mostrarNombre,
  numeroProveedor = "",
  mostrarNumero,
) => {
  const nombreProveedorFormateado = mostrarNombre
    ? nombreProveedor
      ? nombreProveedor
      : ""
    : "";
  const numeroProveedorFormateado = mostrarNumero
    ? numeroProveedor
      ? numeroProveedor
      : ""
    : "";
  const mostrarGuion = nombreProveedorFormateado && numeroProveedorFormateado;

  return `${numeroProveedorFormateado}${mostrarGuion ? " -" : ""} ${nombreProveedorFormateado}`;
};

export const mostrarNombreProveedor = (
  nombreProveedor,
  mostrarNombre,
  numeroProveedor,
  mostrarNumero,
  fontSize,
) => {
  return (
    <View
      style={{
        display: "block",
        flexGrow: 1,
        marginBottom: 2,
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          justifySelf: "flex-start",
        }}
      >
        {handleFormatNombreProveedor(
          nombreProveedor,
          mostrarNombre,
          numeroProveedor,
          mostrarNumero,
        )}
      </Text>
    </View>
  );
};

export const mostrarPrecioArticulo = (
  configuracionEtiqueta,
  articulo,
  margin,
  fontSize,
) => {
  const precioAMostrar = () => {
    let precioArticulo = 0;

    switch (configuracionEtiqueta.precioAMostrar) {
      case "venta":
        precioArticulo = articulo.precios.venta;
        break;
      case "costo":
        precioArticulo = articulo.precios.costo;
        break;
      case "lista":
        precioArticulo = articulo.precios.lista;
        break;
      default:
        break;
    }

    if (configuracionEtiqueta.cifrarPrecio) {
      precioArticulo = cifrarPrecio(precioArticulo);
    } else {
      precioArticulo = `$${parseInt(precioArticulo).toLocaleString("ES-ar")}`;
    }

    return precioArticulo;
  };

  const cifrarPrecio = (precio) => {
    const mapeo = {
      1: "E",
      2: "C",
      3: "L",
      4: "U",
      5: "Y",
      6: "I",
      7: "N",
      8: "T",
      9: "A",
      0: "R",
      ".": "/",
    };

    const precioCifrado = precio
      .toString()
      .split("")
      .map((caracter) => mapeo[caracter] || caracter)
      .join("");
    return precioCifrado;
  };

  return (
    <View
      style={{
        display: "block",
        flexGrow: 1,
        marginBottom: margin,
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "bold",
          alignSelf: "flex-end",
        }}
      >
        {precioAMostrar()}
      </Text>
    </View>
  );
};

export const viewCodBarra = (
  configuracionEtiqueta,
  articulo,
  mostrarLogotipoSucursal = false,
) => {
  const handleGenerarCodigoBarras = () => {
    const codigos = {
      proveedor: articulo.codProveedor,
      original: articulo.codOriginal,
      auxiliar: articulo.codAuxiliar,
    };

    return codigos[configuracionEtiqueta.codigoBarrasDesde] || "000";
  };

  const canvas = document.getElementById("barcode");

  JsBarcode("#barcode", handleGenerarCodigoBarras(), {
    lineColor: "#000",
    width: 4,
    height: 80,
    displayValue: false,
  });

  const pngUrl = canvas.toDataURL("image/png");

  return (
    <View
      style={
        !mostrarLogotipoSucursal
          ? {
              width: "100%",
              maxWidth: "100%",
            }
          : {
              width: "80%",
              maxWidth: "80%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }
      }
    >
      <Image src={pngUrl} />
    </View>
  );
};

export const logoSucursal = (pngUrl) => {
  return (
    <Image
      src={pngUrl && `data:image/png/jpg;base64,${pngUrl}`}
      style={{ width: "65%" }}
    />
  );
};

export const mostrarLogoSucursal = (imagenSucursal) => {
  return (
    <View
      style={{
        flexDirection: "row",
        width: "20%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Image
        style={{ width: "100%", objectFit: "contain" }}
        src={imagenSucursal && `data:image/png/jpg;base64,${imagenSucursal}`}
      />
    </View>
  );
};

export const mostrarUbicacionArticulo = (
  repuestoSucursal,
  fontSize,
  maxChars = 50,
) => {
  if (!repuestoSucursal) return null;

  const texto = [];

  if (repuestoSucursal.deposito) {
    texto.push(repuestoSucursal.deposito.descripcion);
  }
  if (repuestoSucursal.zona) {
    texto.push(` - ${repuestoSucursal.zona.descripcion}`);
  }
  if (repuestoSucursal.estante) {
    texto.push(` - ${repuestoSucursal.estante.descripcion}`);
  }
  if (repuestoSucursal.fila) {
    texto.push(` - ${repuestoSucursal.fila.descripcion}`);
  }

  const textoConcatenado = texto.join("");
  const textoTruncado =
    textoConcatenado.length > maxChars
      ? `${textoConcatenado.substring(0, maxChars - 3)}...`
      : textoConcatenado;

  return (
    <View
      style={{
        display: "flex",
        whiteSpace: "nowrap",
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "normal",
          textAlign: "left",
        }}
      >
        {textoTruncado}
      </Text>
    </View>
  );
};

export const mostrarFechaImpresion = (fontSize) => {
  return (
    <View
      style={{
        display: "flex",
        whiteSpace: "nowrap",
        flex: 1,
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "normal",
          textAlign: "right",
        }}
      >
        {moment().format("DD-MM-YYYY")}
      </Text>
    </View>
  );
};

export const getPageSize = (ancho, alto) => {
  if (ancho === 100 && alto === 50) {
    return {
      width: alto / 0.36,
      height: ancho / 0.36,
      maxWidth: alto / 0.36,
      maxHeight: ancho / 0.36,
      position: "relative",
    };
  }
  return {
    width: ancho / 0.36,
    height: alto / 0.36,
    maxWidth: ancho / 0.36,
    maxHeight: alto / 0.36,
  };
};

export const getPages = (articulos, filasArray, columnasArray) => {
  const etiquetasPorPagina = filasArray.cant * columnasArray.cant;

  const etiquetas = articulos.flatMap((art) =>
    Array.from({ length: art.cantidadEtiquetas }, () => art),
  );

  const pages = [];
  for (let i = 0; i < etiquetas.length; i += etiquetasPorPagina) {
    pages.push(etiquetas.slice(i, i + etiquetasPorPagina));
  }

  return pages;
};
