import React from "react";
import ModalConfirm from "../../../../components/ait-reusable/ModalConfirm/ModalConfirm";

export default function ModalCancelacionPago({
  confirmCancelPago,
  setConfirmCancelPago,
  pagoSelected,
  deleteOrden,
  deletePago,
  loading,
}) {
  const textTitle = () => {
    if (pagoSelected.tipo_registro === "orden_carga_saldo") {
      return "Confirmación de anulación de carga de saldo";
    } else if (pagoSelected.tipo_registro === "orden_retiro_saldo") {
      return "Confirmación de anulación de retiro de saldo";
    } else {
      return "Confirmación de anulación de cobro";
    }
  };

  const textBody = () => {
    if (
      pagoSelected.tipo_registro === "orden_carga_saldo" &&
      pagoSelected.conciliacion_ventas_id
    ) {
      return (
        <p>
          ¿Está seguro de anular la carga de saldo de $
          {Number(pagoSelected.monto.toFixed(2)).toLocaleString("es-AR")}?
          <br />
          <br />
          Esta carga de saldo se utilizó para saldar la conciliación N° $
          {pagoSelected.conciliacion_ventas_id}.
          <br />
          Si la anulás, también se anulará automaticamenta la conciliación N° $
          {pagoSelected.conciliacion_ventas_id} y todos sus pagos asociados, por
          lo que los comporbantes asociados volverán a estar pendientes de pago.
        </p>
      );
    } else {
      return `¿Está seguro de anular el cobro ${
        pagoSelected.tipo_movimiento
          ? `de tipo "${pagoSelected.tipo_movimiento}"`
          : ""
      } de un monto de $${Number(pagoSelected.monto.toFixed(2)).toLocaleString(
        "es-AR",
      )}?`;
    }
  };

  return (
    <ModalConfirm
      open={confirmCancelPago}
      handleClose={() => setConfirmCancelPago(false)}
      title={textTitle()}
      body={textBody()}
      handleSubmit={
        pagoSelected.tipo_registro.includes("orden") ? deleteOrden : deletePago
      }
      buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
      disabled={loading}
      openBackdrop={loading}
    />
  );
}
