import React from "react";
import { View, Text } from "@react-pdf/renderer";

export default function LineaSeparacionTicket() {
  return (
    <View>
      <Text style={{ marginLeft: 10, marginRight: 10 }}>
        --------------------------------
      </Text>
    </View>
  );
}
