import { useEffect, useState } from "react";
import request from "../../../../requests/request";
import { updateStockDuplicados } from "../../../../requests/urls";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";

export const useStockDuplicados = (
  useDataDuplicados,
  setOpenModal,
  useArtRepetidos,
) => {
  const [artCorrecto, setArtCorrecto] = useState(null);
  const [artEliminados, setArtEliminados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [descripcion, setDescripcion] = useState(null);
  const [auxiliar, setAuxiliar] = useState(null);

  const handleChangeCorrecto = (item) => {
    let index = artEliminados.indexOf(
      artEliminados.find(
        (e) => e.idRepuestoProveedor === item.idRepuestoProveedor,
      ),
    );

    if (index !== -1) {
      //El articulo seleccionado esta dentro del array de artEliminados por ende se debe eliminar de ese array
      artEliminados.splice(index, 1);
    }

    if (
      artCorrecto &&
      item.idRepuestoProveedor === artCorrecto.idRepuestoProveedor
    ) {
      setArtCorrecto(null);
    } else {
      setArtCorrecto(item);
    }
  };

  const isArtCorrecto = (item) => {
    let isCorrecto = false;

    if (
      artCorrecto &&
      item.idRepuestoProveedor === artCorrecto.idRepuestoProveedor
    ) {
      isCorrecto = true;
    }

    return isCorrecto;
  };

  const handleChangeEliminados = (item) => {
    let copyEliminados = artEliminados.slice();

    let index = artEliminados.indexOf(
      artEliminados.find(
        (e) => e.idRepuestoProveedor === item.idRepuestoProveedor,
      ),
    );

    if (index === -1) {
      //El articulo seleccionado no esta dentro del array de artEliminados.
      //Se agrega al array
      copyEliminados.push(item);
    } else {
      //El articulo seleccionado esta dentro del array de artEliminados.
      //Se elimina del array
      copyEliminados.splice(index, 1);
    }

    setArtEliminados(copyEliminados);
  };

  const isArtEliminado = (item) => {
    let selected = artEliminados.filter(
      (x) => x.idRepuestoProveedor === item.idRepuestoProveedor,
    )[0];

    return selected ? true : false;
  };

  const confirmTransferStock = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "PUT",
        url: updateStockDuplicados,
        data: getDataTransfer(),
      });
      if (response.status === 201) {
        useDataDuplicados.setArtSelected(null);
        useDataDuplicados.setDuplicados([]);
        setOpenModal(false);
        setArtCorrecto(null);
        setArtEliminados([]);
        setLoading(false);
        useArtRepetidos.getArticulosRepetidos();
        successNotification(
          "Stock transferido y artículos eliminados correctamente",
        );
      } else {
        setLoading(false);
        errorNotification("Error al transferir el stock, por favor reintente");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      errorNotification("Error al transferir el stock, por favor reintente");
    }
  };

  const getDataTransfer = () => {
    let newCorrecto = artCorrecto;
    newCorrecto.idRepuestoSucursal = newCorrecto.repuesto_sucursal
      ? newCorrecto.repuesto_sucursal.idRepuestoSucursal
      : null;

    let newEliminados = artEliminados.slice();

    newEliminados.forEach((e) => {
      e.idRepuestoSucursal = e.repuesto_sucursal
        ? e.repuesto_sucursal.idRepuestoSucursal
        : null;
    });

    let sumaStock = artEliminados.slice().reduce((suma, item) => {
      let stock = item.repuesto_sucursal
        ? Number(item.repuesto_sucursal.stockDisponible)
        : 0;
      return suma + stock;
    }, 0);

    return {
      articulo_correcto: newCorrecto,
      eliminados: newEliminados,
      cantidad_stock: sumaStock,
      descripcion: descripcion ? descripcion : null,
      auxiliar: auxiliar ? auxiliar : null,
    };
  };

  const handleChangeDescripcion = () => {
    if (artCorrecto && artEliminados.length == 1) {
      if (descripcion != null) {
        setDescripcion(null);
      } else {
        if (artEliminados[0].descripcionProveedor) {
          setDescripcion(artEliminados[0].descripcionProveedor);
        } else {
          setDescripcion("");
        }
      }
    }
  };

  const handleChangeAuxiliar = () => {
    if (artCorrecto && artEliminados.length == 1) {
      if (auxiliar != null) {
        setAuxiliar(null);
      } else {
        if (artEliminados[0].codAuxiliar) {
          setAuxiliar(artEliminados[0].codAuxiliar);
        } else {
          setAuxiliar("");
        }
      }
    }
  };

  return {
    useStock: {
      artCorrecto,
      handleChangeCorrecto,
      isArtCorrecto,
      handleChangeEliminados,
      isArtEliminado,
      confirmTransferStock,
      artEliminados,
      setArtCorrecto,
      setArtEliminados,
      loading,
      descripcion,
      auxiliar,
      handleChangeDescripcion,
      handleChangeAuxiliar,
    },
  };
};
