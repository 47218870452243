import React, { useEffect, useState } from "react";
import {
  Tab,
  Box,
  Tabs,
  Chip,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { CustomTabPanel, validacionFormEmpleado } from "./Utils";
import { crearEmpleado, editarEmpleado } from "../../../../requests/urls";
import { formatDataParaBack } from "../util";
import { useFormik } from "formik";
import PermsCard from "./componentes/PermsCard";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import VistaDeFormulario from "./componentes/VistaDeFormulario";
import { PERMISOS } from "../../../../constantes";

export default function ModalEmpleado({
  empleado,
  permisos,
  openModal,
  textoModal,
  accionModal,
  disabledInputs,
  handleCloseModal,
  handleEnviarData,
}) {
  const [tabSelected, setTabSelected] = useState(0);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const permisosPorDefecto = [PERMISOS.INFORME_VENTAS_VER_TODAS_LAS_VENTAS];

  const formik = useFormik({
    initialValues: {
      password: "",
      rol: empleado ? empleado.idRol : "",
      email: empleado ? empleado.email : "",
      nombre: empleado ? empleado.nombre : "",
      apellido: empleado ? empleado.apellido : "",
      username: empleado ? empleado.username : "",
      permisos: empleado ? empleado.permisos : permisosPorDefecto,
      documento: empleado ? empleado.numeroDocumento : "",
      nacimiento: empleado ? empleado.fechaNacimiento : "",
      telefono: empleado ? Number(empleado.telefonoContacto) : "",
      cuentas: empleado ? empleado.cuentas : [],
    },
    onSubmit: (values, { resetForm }) => {
      try {
        setDisabledSubmit(true);
        if (accionModal === "POST") {
          // Al crear el el empleado le vamos a asignar el permiso de VENTAS_ARTICULO_SIN_STOCK
          if (formik.values.rol === 2) {
            values.permisos.push(PERMISOS.VENTAS_ARTICULO_SIN_STOCK);
          }

          handleEnviarData(
            crearEmpleado,
            formatDataParaBack(values),
            resetForm,
            setDisabledSubmit,
            "Se creó el empleado correcctamente",
            "Error al crear el empleado, reintente",
          );
        } else if (accionModal === "PUT") {
          handleEnviarData(
            editarEmpleado(empleado.idEmpleado),
            formatDataParaBack(values),
            resetForm,
            setDisabledSubmit,
            "Se editó el empleado correcctamente",
            "Error al editar el empleado, reintente",
          );
        }
      } catch (error) {
        console.log(error);
        setDisabledSubmit(false);
      }
    },
    validationSchema: validacionFormEmpleado(disabledInputs),
  });

  const handleChangeTab = (event, newValue) => {
    setTabSelected(newValue);
  };

  useEffect(() => {
    formik.resetForm();
  }, []);

  return (
    <Dialog fullWidth maxWidth="md" open={openModal} onClose={handleCloseModal}>
      <DialogTitle>{textoModal}</DialogTitle>
      <Tabs
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        value={tabSelected}
        onChange={handleChangeTab}
        aria-label="basic tabs example"
      >
        <Tab
          label={
            <Typography style={{ fontSize: 14 }}>
              Información Principal
            </Typography>
          }
        />
        {formik.values.rol === 2 ? (
          <Tab
            label={
              <Box display="flex" alignItems="center" style={{ gap: 2 }}>
                <Typography style={{ fontSize: 14, marginRight: 5 }}>
                  Permisos
                </Typography>
                <Chip
                  size="small"
                  label="Nuevo"
                  color="primary"
                  variant="outlined"
                  style={{ borderRadius: "0.2rem", fontSize: 10 }}
                />
              </Box>
            }
          />
        ) : null}
      </Tabs>
      <DialogContent style={{ padding: "0rem 2rem" }}>
        <CustomTabPanel value={tabSelected} index={0}>
          <VistaDeFormulario
            formik={formik}
            empleado={empleado}
            disabledInputs={disabledInputs}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabSelected} index={1}>
          <PermsCard
            permisos={formik.values.permisos}
            cuentas={formik.values.cuentas}
            isNewSalesPerson={formik.values.rol === 2 && accionModal === "POST"}
            onPermissions={(permissions) =>
              formik.setFieldValue("permisos", permissions)
            }
            onCuentas={(cuentas) => formik.setFieldValue("cuentas", cuentas)}
          />
        </CustomTabPanel>
      </DialogContent>
      <DialogActions
        style={{ padding: "0rem 2rem 1rem 2rem", display: "flex", gap: 10 }}
      >
        <ButtonCancelar
          click={handleCloseModal}
          message="Cancelar"
          disabled={null}
        />
        <ButtonAceptar
          click={() => formik.handleSubmit()}
          message="Aceptar"
          disabled={disabledSubmit}
        />
      </DialogActions>
    </Dialog>
  );
}
