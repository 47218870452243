import React, { useState, useEffect } from "react";
import { Grid, Card, TextField } from "@material-ui/core";
import AutocompleteEmpleado from "../../../../components/ait-reusable/AutocompleteEmpleado";
import { useSelector } from "react-redux";
import ButtonGeneral from "../../../../components/ait-reusable/Button/ButtonGeneral";
import request from "../../../../requests/request";
import { conciliacionVentas } from "../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";
import { parseConciliarVentas } from "./componentes/FomularioCargaMedioPago/utils";
import { formatCurrency, parseCurrency } from "../../../../utils/parsers";
import { Showmodal } from "../../../../components/ait-reusable/PDF/Components/PreviewPdf";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../../components/ait-reusable/HandlePdf/handlePdf";
import { conciliacionVentasServices } from "../../../../services/conciliacionVentas";
import useValidacionMensajeErrorAFIP from "../../../../customHooks/useValidacionMensajeErrorAFIP";
import ModalConfirm from "../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import ModalEnvioComprobante from "./componentes/Modal/ModalEnvioComprobante";
import ModalWhatsApp from "../../../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import { getPdfBlob } from "../../../../components/ait-reusable/ModalWhatsApp/uploadPdf";

export default function OthersComponents({
  dataEmpleado,
  observacion,
  setObservacion,
  selectionList,
  //tipo,
  //getValueSaldar,
  newMontos,
  //convertNumeroSinMiles,
  facturas,
  closePago,
  setMontos,
  setErrormonto,
  //montocobrar,
  setMontocobrar,
  cliente,
  getFacturasAdeudadas,
  updateResumen,
  setDataErrorAFIP,
  montoTotalCargado,
  //montoASaldar,
  formikMediosPago,
}) {
  const { empleado } = useSelector((state) => state.loginReducer);
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [errorResponsable, setErrorResponsable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preview, setpreview] = useState(false);
  const [dataPdf, setdataPdf] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [tipoImpresion, settipoImpresion] = useState("");
  const [clientData, setClientData] = useState({});
  const [responseData, setResponseData] = useState({});
  const { getDataInfoErrorNC } = useValidacionMensajeErrorAFIP();
  const [openModalPagoMayor, setOpenModalPagoMayor] = useState(false);
  const [openModalActions, setOpenModalActions] = useState(false);
  const [openModalWhatsApp, setOpenModalWhatsApp] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [tipoPDFWP, setTipoPDFWP] = useState("");

  const { setSelection } = selectionList;

  const montoSobrante = montoTotalCargado - newMontos.subtotal;

  const disabled = () => {
    let disabled = false;
    if (disabled) return true;
    // Si en monto cargado en los medios de pago es igual o menor 0
    if (montoTotalCargado <= 0) return true;
    // Si el formulario de medios de pago no es valido
    if (!formikMediosPago.isValid) {
      return true;
    }

    // Si el formulario de medios de pago esta en proceso de validacion
    if (formikMediosPago.isValidating) {
      return true;
    }
    // Si no hay ventas seleccionadas
    if (selectionList.selection.length === 0) return true;

    return disabled;
  };

  const handleSubmit = async () => {
    if (dataEmpleado.useEmpleado.empleado) {
      setLoading(true);
      if (!disabled()) {
        try {
          const data = {
            cliente_sucursal_id: cliente.idClienteDeSucursal,
            descuentoPorcentaje: newMontos.descuentoPorcentaje,
            ventas: facturas,
            responsable:
              dataEmpleado && dataEmpleado.useEmpleado.empleado.idEmpleado,
            is_pago_con_descuento: newMontos.descuentoUnico > 0 ? true : false,
            observacion: observacion === "" ? null : observacion,
            informacion_medios_pago: formikMediosPago.values,
          };
          const parsedData = parseConciliarVentas(data);
          const response = await request({
            method: "POST",
            url: conciliacionVentas,
            data: parsedData,
          });
          await handleResponse(response);
          setLoading(false);
        } catch (err) {
          console.log(err);
          let msgError = JSON.stringify(err.message);

          if (
            msgError.includes(
              "Errores al validar las siguientes notas de crédito",
            )
          ) {
            actionErrorNC(msgError);
          } else {
            errorNotification("Ha ocurrido un error durante el registro.");
          }
          setLoading(false);
        }
      }
    } else {
      setErrorResponsable(true);
    }
  };

  const toggle = () => {
    closePago();
    setpreview(!preview);
  };

  const actionErrorNC = (msgError) => {
    const data = getDataInfoErrorNC(msgError);
    const erroresARCA = data.filter((d) =>
      d.mensajeError.includes("Error de ARCA"),
    );

    errorNotification(
      "Factura generada con éxito pero ocurrieron errores en las validaciones de AFIP de notas de crédito.",
      3000,
    );

    setDataErrorAFIP((prev) => ({
      ...prev,
      facturas: erroresARCA,
      open: true,
    }));
  };

  const handleResponse = async (response) => {
    if (response.status === 201) {
      setMontocobrar("");
      setSelection([]);
      setMontos.setNewMontos({
        subtotal: 0,
        descuento: 0,
        descuentoUnico: 0,
      });
      setObservacion("");
      dataEmpleado.useEmpleado.empleado &&
        localStorage.setItem(
          "responsable_venta",
          dataEmpleado.useEmpleado.empleado.idEmpleado,
        );
      setErrormonto("");
      successNotification("El pago se ha realizado con éxito.");

      const responseData =
        await conciliacionVentasServices.obtenerConciliacionVentas({
          conciliacionVentasId: response.data.id,
        });

      const clientData = {
        razon_social: responseData.cliente_sucursal.razon_social,
        telefono_contacto: responseData.cliente_sucursal.telefono_contacto,
      };

      setClientData(clientData);
      setResponseData(responseData);
      setOpenModalActions(true);
    } else {
      errorNotification("Ha ocurrido un error durante el registro.");
      setLoading(false);
    }
    getFacturasAdeudadas();
    updateResumen();
  };

  const handleSubmitModalPagoMayor = () => {
    if (montoTotalCargado > newMontos.subtotal) {
      setOpenModalPagoMayor(true);
    } else {
      handleSubmit();
    }
  };

  const handleModalConfirmSubmit = async (type, action) => {
    if (type === "PagoMayor") {
      setOpenModalPagoMayor(false);
      handleSubmit();
    }
    if (type === "EnvioComprobante") {
      const opcionesImpresion = {
        A4: "conciliacionVentasA4",
        Ticket: "conciliacionVentasTicket",
        Whatsapp: "conciliacionVentasWPA4",
      };
      let tipoImpresionPDF = opcionesImpresion[action];
      printPDFConciliacion(responseData, tipoImpresionPDF);
    }
  };

  const printPDFConciliacion = async (responseData, tipoImpresionPDF) => {
    setLoadingPDF(true);
    try {
      if (tipoImpresionPDF === "conciliacionVentasWPA4") {
        setTipoPDFWP("conciliacionVentasA4");
        setdataPdf(responseData);
        setOpenModalActions(false);
        setOpenModalWhatsApp(true);
      } else {
        const blobPdf = await getBlobPdf(
          tipoImpresionPDF,
          responseData,
          configGeneral,
        );
        if (configGeneral.previsualizacion) {
          imprimirPdf(blobPdf);
          setOpenModalActions(false);
          closePago();
        } else {
          settipoImpresion(tipoImpresionPDF);
          setdataPdf(responseData);
          setOpenModalActions(false);
          setpreview(true);
          imprimirPdf(blobPdf);
        }
        setLoadingPDF(false);
      }
    } catch (error) {
      console.log(error);
      setdataPdf([]);
      setpreview(false);
      setLoadingPDF(false);
    }
  };

  const modalMsg = (
    <>
      {`Estás por registrar un pago de `}
      <strong>{formatCurrency(montoTotalCargado)}</strong>,
      {` que supera el total de la deuda `}
      <strong>{formatCurrency(newMontos.subtotal)}</strong>.
      <br />
      {`Si confirmás la operación, se generará automáticamente una carga de saldo por el monto sobrante `}
      <strong>{formatCurrency(montoSobrante)}</strong>
      {` en la billetera del cliente que podrá usarse para saldar otros pagos en un futuro.`}
    </>
  );

  useEffect(() => {
    if (pdfUrl) {
      setOpenModalWhatsApp(true);
    } else if (dataPdf) {
      getPdfBlob(tipoPDFWP, dataPdf, configGeneral).then((res) => {
        if (res) {
          setPdfUrl(res.secure_url);
          setLoadingPDF(false);
        }
      });
    }
  }, [pdfUrl, dataPdf]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2} className="p-2">
              <Grid item xs={12}>
                <AutocompleteEmpleado
                  useEmpleado={dataEmpleado.useEmpleado}
                  useQueryEmpleado={dataEmpleado.useQueryEmpleado}
                  errorResponsable={errorResponsable}
                  setErrorResponsable={setErrorResponsable}
                  empleado={empleado}
                  setLoading={setLoading}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  size="small"
                  style={{ backgroundColor: "white" }}
                  name="observacion"
                  fullWidth
                  multiline
                  inputProps={{ maxLength: 150 }}
                  label="Observación"
                  value={observacion}
                  onChange={(event) => setObservacion(event.target.value)}
                  helperText={`${observacion.length} de 150 caracteres`}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <ButtonGeneral
            fullwidth={true}
            message={`Registrar pago por ${parseCurrency(montoTotalCargado)}`}
            disabled={disabled() || loading}
            click={handleSubmitModalPagoMayor}
          />
        </Grid>
      </Grid>
      {preview && (
        <Showmodal
          tipo={tipoImpresion}
          preview={preview}
          toggle={toggle}
          data={dataPdf}
        />
      )}
      <ModalConfirm
        open={openModalPagoMayor}
        handleClose={() => setOpenModalPagoMayor(false)}
        title="Pago mayor al monto de deuda"
        body={modalMsg}
        handleSubmit={() => handleModalConfirmSubmit("PagoMayor")}
        buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
      />
      <ModalEnvioComprobante
        open={openModalActions}
        handleClose={() => {
          setOpenModalActions(false);
          closePago();
        }}
        handleSubmit={handleModalConfirmSubmit}
      />
      {openModalWhatsApp && clientData && dataPdf && pdfUrl && (
        <ModalWhatsApp
          open={openModalWhatsApp}
          close={() => {
            setOpenModalWhatsApp(false);
            setdataPdf(null);
            setPdfUrl(null);
            setTipoPDFWP(null);
            closePago();
          }}
          nombre={clientData && clientData.razon_social}
          telefono={clientData && clientData.telefono_contacto}
          mensajePdf={`¡Hola ${clientData.razon_social}! Recibimos tu pago para saldar las compras que estaban pendientes en tu cuenta. Te enviamos el comprobante en formato PDF, por favor hace click en el siguiente link para visualizarlo: `}
          urlPdf={pdfUrl}
        />
      )}
      <CircularBackdrop openBackdrop={loading || loadingPDF} />
    </>
  );
}
