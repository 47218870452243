import React, { useState } from "react";
import ModalReutilizable from "../Modal";
import { Grid, Typography } from "@material-ui/core";
import { validarComprobante } from "../../../requests/urls";
import request from "../../../requests/request";
import { errorNotification, successNotification } from "../../Notifications";
import { AlertReusable } from "../Alerts/AlertReusable";
import TitleModal from "./TitleModal";
import useValidacionMensajeErrorAFIP from "../../../customHooks/useValidacionMensajeErrorAFIP";

export default function ModalValidacionCAE({
  open,
  handleClose,
  idVenta,
  isNewVenta,
  msgErrorValidacionAFIP,
}) {
  const [disabled, setDisabled] = useState(false);
  const [newMensajeError, setNewMensajeError] = useState(null);
  const { getMensajeErrorValidacionFactura } = useValidacionMensajeErrorAFIP();

  const getContentModal = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontSize: 17 }}
          >
            {isNewVenta
              ? `La venta Nº ${
                  idVenta || ""
                } se generó con éxito pero no se pudo validar con ARCA y está pendiente de CAE.`
              : `Se registró la factura pero ocurrió un error al validar la misma con ARCA y quedó pendiente de CAE.`}
          </Typography>
        </Grid>
        {msgErrorValidacionAFIP !== "" && !newMensajeError && (
          <Grid item xs={12}>
            <AlertReusable severity={"error"} text={msgErrorValidacionAFIP} />
          </Grid>
        )}
        {newMensajeError && (
          <Grid item xs={12}>
            <AlertReusable severity={"error"} text={newMensajeError} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontSize: 17 }}
          >{`¿Desea reintentar la generación de CAE ahora? Si no lo desea en este momento, la factura quedará registrada pero pendiente de CAE, y se podrá reintentar generar el CAE desde el informe de ventas.`}</Typography>
        </Grid>
      </Grid>
    );
  };

  const validateCAE = async () => {
    setDisabled(true);
    try {
      const response = await request({
        method: "PUT",
        url: validarComprobante,
        data: {
          ventas: [idVenta],
        },
        params: {
          paginated: 0,
        },
      });
      handleResponseValidate(response);
    } catch (error) {
      let msgError = JSON.stringify(error.response.data);
      if (msgError.includes("Error al validar la factura")) {
        let errorValidacionAFIP = getMensajeErrorValidacionFactura(msgError);
        setNewMensajeError(errorValidacionAFIP);

        errorNotification(
          "No se pudo generar el CAE, ocurrió el siguiente error en ARCA: " +
            errorValidacionAFIP,
          6000,
        );
      } else {
        errorNotification(
          "El número de CAE no se ha generado, intente más tarde.",
        );
      }
      setDisabled(false);
    }
  };

  const handleResponseValidate = (res) => {
    if (res.data.status === 201) {
      successNotification("El número de CAE se ha generado con éxito.");
      handleClose();
    } else {
      errorNotification(
        "El número de CAE no se ha generado, intente más tarde.",
      );
      setDisabled(false);
    }
  };
  return (
    <ModalReutilizable
      open={open}
      handleClose={handleClose}
      title={<TitleModal title={`Validación de CAE de factura`} />}
      content={getContentModal()}
      messageAceptar={"Generar CAE"}
      messageCancelar={"Cancelar"}
      handleSubmit={validateCAE}
      openBackdrop={disabled}
    />
  );
}
