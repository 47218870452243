import { pdf } from "@react-pdf/renderer";
import React from "react";
import EtiquetasA4 from "../../../../components/ait-reusable/Etiquetas/EtiquetasA4";
import EtiquetasCarta from "../../../../components/ait-reusable/Etiquetas/EtiquetasCarta";
import EtiquetasTicket from "../../../../components/ait-reusable/Etiquetas/EtiquetasTicket";

export const textSizes = {
  "30x15": "Solo cód. Prov. Aux. y cód. barra",
  "42x29": "Solo cód. Proveedor, descripción, cód. barra",
  "50x25": "Solo cód. Prov. Orig., negocio, descripción, cód. barra",
  "80x25": "Solo cód. Prov. Orig., negocio, descripción, cód. barra",
  "100x50":
    "Logo, Cód. barra, Cód. auxiliar, Cód. proveedor, descripción, Fecha impresión",
};

export const hanldeBlobPDFTicket = async ({
  medidas,
  sucursal,
  articulo,
  configGeneral,
  configuracion,
}) => {
  const blob = await pdf(
    <EtiquetasTicket
      alto={medidas.alto}
      ancho={medidas.ancho}
      sucursal={sucursal}
      articulo={articulo}
      configGeneral={configGeneral}
      configuracionEtiqueta={configuracion}
    />,
  ).toBlob();

  const blobUrl = window.URL.createObjectURL(blob);
  return window.open(blobUrl);
};

export const hanldeBlobPDFA4 = async ({
  medidas,
  sucursal,
  articulo,
  filasArray,
  columnasArray,
  configGeneral,
  configuracion,
}) => {
  const blob = await pdf(
    <EtiquetasA4
      alto={medidas.alto}
      ancho={medidas.ancho}
      articulo={articulo}
      sucursal={sucursal}
      filasArray={filasArray}
      columnasArray={columnasArray}
      configGeneral={configGeneral}
      configuracionEtiqueta={configuracion}
    />,
  ).toBlob();

  const blobUrl = window.URL.createObjectURL(blob);
  return window.open(blobUrl);
};

export const hanldeBlobPDFCarta = async ({
  medidas,
  sucursal,
  articulo,
  filasArray,
  columnasArray,
  configGeneral,
  configuracion,
}) => {
  const blob = await pdf(
    <EtiquetasCarta
      alto={medidas.alto}
      ancho={medidas.ancho}
      articulo={articulo}
      sucursal={sucursal}
      filasArray={filasArray}
      columnasArray={columnasArray}
      configGeneral={configGeneral}
      configuracionEtiqueta={configuracion}
    />,
  ).toBlob();

  const blobUrl = window.URL.createObjectURL(blob);
  return window.open(blobUrl);
};
