import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { parseDate } from "../../../../../utils/parsers";

export default function ClienteTicket({ data }) {
  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "space-between",
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <Text style={styles.text}>{`Nro.: ${data.id}`}</Text>
      <Text style={styles.text}>
        {`Fecha: ${parseDate(data.fecha_hora, "DD/MM/YYYY HH:mm")}`}
      </Text>
      <Text style={styles.text}>
        {`Sr.(es): ${
          data.cliente_sucursal.razon_social
            ? data.cliente_sucursal.razon_social
            : "---"
        }`}
      </Text>
      <Text style={styles.text}>
        {`Condición de IVA: ${
          data.cliente_sucursal.estado_tributario
            ? data.cliente_sucursal.estado_tributario
            : "---"
        }`}
      </Text>
      <Text style={styles.text}>
        {`Domicilio: ${
          data.cliente_sucursal.domicilio
            ? data.cliente_sucursal.domicilio
            : "---"
        }`}
      </Text>
      <Text style={styles.text}>
        {`DNI/CUIT: ${
          data.cliente_sucursal.cuit_dni
            ? data.cliente_sucursal.cuit_dni
            : "---"
        }`}
      </Text>
      <Text style={styles.text}>{`Condición de pago: Cuenta corriente`}</Text>
    </View>
  );
}
