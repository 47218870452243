import { errorNotification } from "../../../components/Notifications";
import axios from "axios";
import request from "../../../requests/request";
import {
  getClientesDeudasSucursal,
  getClientesPorComisionMecanico,
  getInformeTotalesMedioPago,
  getProveedorUltimaActualizacion,
  getExcelDeudaPendienteProveedores,
} from "../../../requests/urls";
import moment from "moment";
import { restarMeses } from "./utils";

const dateToday = new Date();

export const getInformeVentaMensual = async (periodo) => {
  let url = "/api/venta/total-vendido/informe/";
  let params = {
    month: periodo.month,
    year: periodo.year,
  };

  let fileName = `Informe_ventas_mensual${periodo.month}_${periodo.year}.xlsx`;
  RequestExport(url, params, fileName);
};

export const getInformeVentaMedioDePago = async (datesMediosPago) => {
  let url = getInformeTotalesMedioPago;
  let params = {
    desde: datesMediosPago.fromDate,
    hasta: datesMediosPago.toDate,
  };
  let fileName = `Informe_ventas_${datesMediosPago.fromDate}_${datesMediosPago.toDate}.xlsx`;
  RequestExport(url, params, fileName);
};

export const getInformeAuditoria = async (filter) => {
  let url = "/api/movimientoDeCaja/informe/auditoria/";
  let params = {
    month: filter.month,
    year: filter.year,
    type: filter.type,
  };
  let fileName = `Informe_Auditoria_Movimiento_de_Caja_de_${
    filter.type === 1 ? "Entrada" : "Salida"
  }_${filter.month}_${filter.year}.xlsx`;
  RequestExport(url, params, fileName);
};

export const getInformeVentaPorArticulo = async (
  filter,
  datesVentaArticulo,
) => {
  let url = "/api/venta/articulos-monto-vendido/informe/";
  let params = {
    provider: filter.provider.idProveedor,
    desde: datesVentaArticulo.fromDate,
    hasta: datesVentaArticulo.toDate,
  };
  let fileName = `Informe_Venta_por_Producto_${datesVentaArticulo.fromDate}_${datesVentaArticulo.toDate}.xlsx`;
  RequestExport(url, params, fileName);
};

export const getInformeTopArticulosVendidos = async (
  filter,
  datesVentaArticulo,
  cantidadSelect,
) => {
  let url = "/api/articulos/top-articulos-vendidos/informe/";
  let params = {
    proveedor: filter.provider ? filter.provider.idProveedor : null,
    desde: datesVentaArticulo.fromDate,
    hasta: datesVentaArticulo.toDate,
    top_cantidad: cantidadSelect,
  };
  let fileName = `Informe_ranking_articulos_vendidos_${
    datesVentaArticulo.fromDate
  }_${datesVentaArticulo.toDate}_${
    filter.provider ? filter.provider.razonSocial : "Todos los proveedores"
  }.xlsx`;
  RequestExport(url, params, fileName);
};

const RequestExport = (url, params, fileName) => {
  axios({
    url: url,
    params: params,
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
      errorNotification(
        "Ocurrio un error en el proceso intente nuevamente en unos minutos",
      );
    });
};

export async function getMotivoMov() {
  let data = [];
  try {
    const response = await request({
      method: "GET",
      url: `/api/movimientoDeCaja/motivos/`,
      params: {
        entrada: "",
        all: 1,
      },
    });
    data = response.data;
  } catch (err) {
    console.log(err);
  }
  return data;
}

export const getComprasPagadas = async (filter) => {
  let url = "/api/compras/export-pagadas/";
  let params = {
    month: filter.month,
    year: filter.year,
    proveedor: filter.provider ? filter.provider.idProveedor : null,
  };
  let fileName = `ComprasPagadas.xlsx`;
  RequestExport(url, params, fileName);
};

export const getClienteDeuda = async (dates) => {
  let url = getClientesDeudasSucursal;

  let params = {
    desde: dates.fromDate,
    hasta: dates.toDate,
    is_historico: 0,
  };

  let fileName = `DeudasClientes.xlsx`;
  RequestExport(url, params, fileName);
};

export const getClientesComision = async (dates) => {
  let url = getClientesPorComisionMecanico;
  let params = {
    desde: dates.fromDate,
    hasta: dates.toDate,
  };
  let fileName = "ClientesPorComision.xlsx";
  RequestExport(url, params, fileName);
};

export const getActualizacionProveedor = async () => {
  let url = getProveedorUltimaActualizacion;
  let params = null;
  let fileName = `ÚltimasActualizacionesProveedores.xlsx`;
  RequestExport(url, params, fileName);
};

export const getDeudaPendienteProveedores = async (
  fechaHasta,
  setIsLoading,
) => {
  setIsLoading(true);
  let url = getExcelDeudaPendienteProveedores;
  let params = {
    fecha_hasta: fechaHasta,
  };
  let fileName = `Deuda pendiente proveedores hasta ${moment(fechaHasta).format("DD-MM-YYYY")}.xlsx`;
  RequestExport(url, params, fileName);
};
