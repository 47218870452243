import React, { useState } from "react";
import ModalReutilizable from "../Modal";
import { Grid, Typography } from "@material-ui/core";
import request from "../../../requests/request";
import { validateNotasCredito } from "../../../requests/urls";
import { errorNotification, successNotification } from "../../Notifications";
import { AlertReusable } from "../Alerts/AlertReusable";
import TitleModal from "./TitleModal";

export default function ModalValidacionCAENotaCredito({
  open,
  handleClose,
  dataErrorCAE,
}) {
  const [disabled, setDisabled] = useState(false);

  const { idComprobante, isNotaCredito, msgErrorValidacionAFIP } = dataErrorCAE;
  const getContentNC = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontSize: 17 }}
          >
            {isNotaCredito
              ? `Se generó la nota de crédito con ID ${idComprobante} con éxito pero no se pudo validar con ARCA y está pendiente de CAE.`
              : `La nota de crédito correspondía a una factura (ID venta ${idComprobante}) pendiente de CAE por lo que se intentó generar el CAE de la factura pero no se pudo validar con ARCA y está pendiente de CAE.`}
          </Typography>
        </Grid>
        {msgErrorValidacionAFIP !== "" && (
          <Grid item xs={12}>
            <AlertReusable severity={"error"} text={msgErrorValidacionAFIP} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontSize: 17 }}
          >
            {`¿Desea reintentar la generación de CAE de la nota de crédito ${!isNotaCredito ? "y de su factura" : ""}? Si no lo desea en este momento, la misma quedará registrada pero pendiente de CAE, y se podrá reintentar generar el CAE desde el informe de notas de crédito.`}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const validateCAE = async () => {
    setDisabled(true);
    try {
      let data = [{ nota: idComprobante, anulacion_fcem: "" }];

      const response = await request({
        method: "PUT",
        url: validateNotasCredito,
        data: {
          notas_credito: data,
        },
        params: {
          paginated: 0,
        },
      });
      handleResponseValidate(response);
    } catch (error) {
      errorNotification(
        "El número de CAE no se ha generado, intente más tarde.",
      );
      setDisabled(false);
    }
  };

  const handleResponseValidate = (res) => {
    if (res.data.status === 201) {
      successNotification("El número de CAE se ha generado con éxito.");
      handleClose();
    } else {
      errorNotification(
        "El número de CAE no se ha generado, intente más tarde.",
      );
      setDisabled(false);
    }
  };

  const props = {
    messageAceptar: "Generar CAE",
    messageCancelar: "Cancelar",
    handleSubmit: validateCAE,
  };

  return (
    <ModalReutilizable
      open={open}
      handleClose={handleClose}
      content={getContentNC()}
      title={<TitleModal title={`Validación de CAE de nota de crédito`} />}
      openBackdrop={disabled}
      {...props}
    />
  );
}
