import { Document, Page, View } from "@react-pdf/renderer";
import React from "react";
import { LabelComponent } from "../SingleEtiqueta/SizeLabel";
import { getPages, styles } from "../SingleEtiqueta/utils";

export default function EtiquetasCartaBulk({
  articulos,
  sucursal,
  ancho,
  alto,
  filasArray,
  columnasArray,
  configGeneral,
  configuracionEtiqueta,
}) {
  const Component = LabelComponent(ancho + "x" + alto);

  const pages = getPages(articulos, filasArray, columnasArray);

  const renderEtiqueta = (articulo) => {
    return (
      <View style={{ padding: 5 }}>
        <Component
          key={articulo.id}
          sucursal={sucursal}
          articulo={articulo}
          configGeneral={configGeneral}
          configuracionEtiqueta={configuracionEtiqueta}
        />
      </View>
    );
  };

  return (
    <Document title="Etiquetas Carta">
      {pages.map((etiquetasPagina, pageIndex) => (
        <Page key={`page-${pageIndex}`} size="LETTER" style={styles.pageLetter}>
          <View style={styles.contenedor}>
            {Array.from({ length: filasArray.cant }).map((_, filaIndex) => (
              <View style={styles.fila} key={`fila-${pageIndex}-${filaIndex}`}>
                {Array.from({ length: columnasArray.cant }).map(
                  (_, colIndex) => {
                    const etiquetaIndex =
                      filaIndex * columnasArray.cant + colIndex;
                    const etiqueta = etiquetasPagina[etiquetaIndex];
                    if (!etiqueta) return null;
                    return renderEtiqueta(etiqueta);
                  },
                )}
              </View>
            ))}
          </View>
        </Page>
      ))}
    </Document>
  );
}
