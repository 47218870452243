import React from "react";
import { Grid } from "@material-ui/core";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import Alert from "@material-ui/lab/Alert";

export default function DetalleStockFinal({ useStock }) {
  const stockInicial =
    useStock.artCorrecto && useStock.artCorrecto.repuesto_sucursal
      ? useStock.artCorrecto.repuesto_sucursal.stockDisponible
      : 0;

  const getStockFinal = () => {
    let sumaStock = useStock.artEliminados.reduce((suma, item) => {
      let stock = item.repuesto_sucursal
        ? Number(item.repuesto_sucursal.stockDisponible)
        : 0;
      return suma + stock;
    }, 0);

    return Number(stockInicial) + sumaStock;
  };

  const getMensaje = () => {
    let stock = getStockFinal();
    if (!useStock.artCorrecto.repuesto_sucursal) {
      return `El artículo seleccionado se agregará a la sucursal con stock igual a ${stock}.`;
    } else {
      return `El stock del artículo seleccionado se actualizará a ${stock}.`;
    }
  };

  return (
    <Grid
      container
      spacing={2}
      style={{ padding: 10, justifyContent: "space-between" }}
    >
      {useStock.artCorrecto &&
        useStock.artEliminados.length > 0 &&
        getStockFinal() <= 0 && (
          <Grid item xs={12} lg={12} style={{ marginLeft: 10 }}>
            <Alert severity="info">
              <strong style={{ fontSize: 16 }}>{getMensaje()}</strong>
            </Alert>
          </Grid>
        )}
      <Grid item xs={6} lg={6} style={{ marginLeft: 10 }}>
        {useStock.artCorrecto && (
          <>
            <h6 className="fontBold mb-0">ARTÍCULO A MANTENER:</h6>
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              CÓDIGO ARTÍCULO:{" "}
            </span>
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              {useStock.artCorrecto ? useStock.artCorrecto.codProveedor : "---"}
            </span>
            <br />
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              CÓDIGO ORIGINAL:{" "}
            </span>
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              {useStock.artCorrecto ? useStock.artCorrecto.codOriginal : "---"}
            </span>
            <br />
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              CÓDIGO AUXILIAR:{" "}
            </span>
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              {useStock.auxiliar == null
                ? useStock.artCorrecto && useStock.artCorrecto.codAuxiliar
                  ? useStock.artCorrecto.codAuxiliar
                  : "---"
                : useStock.auxiliar
                  ? useStock.auxiliar
                  : "---"}
            </span>
            <br />
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              DESCRIPCIÓN:{" "}
            </span>
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              {useStock.descripcion == null
                ? useStock.artCorrecto &&
                  useStock.artCorrecto.descripcionProveedor
                  ? useStock.artCorrecto.descripcionProveedor
                  : "---"
                : useStock.descripcion
                  ? useStock.descripcion
                  : "---"}
            </span>
            <br />
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              STOCK FINAL:{" "}
            </span>
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              {getStockFinal()}
            </span>
            <br />
          </>
        )}
      </Grid>
      <Grid
        item
        xs={3}
        lg={3}
        style={{ display: "flex", alignItems: "center" }}
      >
        <ButtonAceptar
          message={"TRANSFERIR STOCK"}
          fullwidth={true}
          disabled={
            useStock.artCorrecto && useStock.artEliminados.length > 0
              ? false
              : true
          }
          click={() => useStock.confirmTransferStock()}
        />
      </Grid>
    </Grid>
  );
}
