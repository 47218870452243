import React from "react";
import { TextField, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

export const useStyles = makeStyles((theme) => ({
  inputFecha: {
    "& .MuiOutlinedInput-input": {
      paddingRight: 1,
      paddingTop: 15,
    },
  },
}));

export default function FilterFechaHasta({
  fechaDeudaProveedor,
  setFechaDeudaProveedor,
}) {
  const classes = useStyles();
  const dateToday = moment().format("YYYY-MM-DD");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ padding: 0, paddingTop: 15 }}>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          id="date"
          label="Fecha"
          type="date"
          className={classes.inputFecha}
          inputProps={{ max: dateToday }}
          name="fechaHasta"
          value={fechaDeudaProveedor || ""}
          onChange={(e) => {
            setFechaDeudaProveedor(e.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
}
