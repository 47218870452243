import React, { Fragment } from "react";
import { Text, View } from "@react-pdf/renderer";
import { formatCurrency } from "../../../../../utils/parsers";
import { styles } from "../../assets/css/styleTicket";

export default function FormaPagoTicket({ data }) {
  return (
    <View style={styles.containerRow}>
      <View style={styles.containerColumn}>
        <Text style={styles.textbold}>FORMAS DE PAGO:</Text>
        {data.orden_carga_saldo.detalles_orden.map((orden, index) => (
          <Fragment key={index}>
            {orden.medio_pago.nombre === "Efectivo" ? (
              <Text style={styles.text}>
                {orden.medio_pago.nombre}: {formatCurrency(orden.monto)}
              </Text>
            ) : null}

            {orden.medio_pago.nombre === "Tarjeta Débito" ? (
              <>
                <Text style={styles.text}>
                  {orden.pagos_tarjeta.id_tarjeta.nombre}
                  {orden.pagos_tarjeta.cantidad_cuotas === 1
                    ? ` (1 cuota)`
                    : ` (${orden.pagos_tarjeta.cantidad_cuotas} cuotas)`}
                  : {formatCurrency(orden.monto)}
                </Text>
                <Text style={styles.textAditionalItem}>
                  {orden.pagos_tarjeta.monto_recargo
                    ? `*Intereses: ${formatCurrency(orden.pagos_tarjeta.monto_recargo)}`
                    : null}
                </Text>
              </>
            ) : null}

            {orden.medio_pago.nombre === "Tarjeta Crédito" ? (
              <>
                <Text style={styles.text}>
                  {orden.pagos_tarjeta.id_tarjeta.nombre}
                  {orden.pagos_tarjeta.cantidad_cuotas === 1
                    ? " (1 cuota)"
                    : ` (${orden.pagos_tarjeta.cantidad_cuotas} cuotas)`}
                  : {formatCurrency(orden.monto)}
                </Text>
                <Text style={styles.textAditionalItem}>
                  {orden.pagos_tarjeta.monto_recargo
                    ? `*Intereses: ${formatCurrency(orden.pagos_tarjeta.porcentaje * orden.monto)}`
                    : null}
                </Text>
                <Text style={styles.textAditionalItem}>
                  {orden.pagos_tarjeta.monto_recargo
                    ? `*Total tarjeta: ${formatCurrency(orden.monto + orden.pagos_tarjeta.porcentaje * orden.monto)}`
                    : null}
                </Text>
              </>
            ) : null}

            {orden.medio_pago.nombre === "Cheque" ? (
              <>
                <Text style={styles.text}>
                  {orden.medio_pago.nombre}: {formatCurrency(orden.monto)}
                </Text>
                <Text style={styles.textAditionalItem}>
                  *Nro.: {orden.pago_cheque.numero_cheque}
                </Text>
              </>
            ) : null}

            {orden.medio_pago.nombre === "Transferencia Bancaria" ? (
              <Text style={styles.text}>
                {orden.medio_pago.nombre}: {formatCurrency(orden.monto)}
              </Text>
            ) : null}

            {orden.medio_pago.nombre === "Mercado pago" ? (
              <Text style={styles.text}>
                {orden.medio_pago.nombre}: {formatCurrency(orden.monto)}
              </Text>
            ) : null}

            {orden.medio_pago.nombre === "Link de pago" ? (
              <>
                <Text style={styles.text}>
                  {orden.medio_pago.nombre}
                  {orden.pagos_tarjeta.cantidad_cuotas === 1
                    ? " (1 cuota)"
                    : ` (${orden.pagos_tarjeta.cantidad_cuotas} cuotas)`}
                  : {formatCurrency(orden.monto)}
                </Text>
                <Text style={styles.textAditionalItem}>
                  {orden.pagos_tarjeta.monto_recargo
                    ? `*Intereses: ${formatCurrency(orden.pagos_tarjeta.porcentaje * orden.monto)}`
                    : null}
                </Text>
                <Text style={styles.textAditionalItem}>
                  {orden.pagos_tarjeta.monto_recargo
                    ? `*Total Pago: ${formatCurrency(orden.monto + orden.pagos_tarjeta.porcentaje * orden.monto)}`
                    : null}
                </Text>
              </>
            ) : null}

            {orden.medio_pago.nombre === "QR" ? (
              <Text style={styles.text}>
                {orden.medio_pago.nombre}: {formatCurrency(orden.monto)}
              </Text>
            ) : null}

            {orden.medio_pago.nombre === "DEBIN" ? (
              <Text style={styles.text}>
                {orden.medio_pago.nombre}: {formatCurrency(orden.monto)}
              </Text>
            ) : null}

            {orden.medio_pago.nombre === "MODO" ? (
              <Text style={styles.text}>
                {orden.medio_pago.nombre}: {formatCurrency(orden.monto)}
              </Text>
            ) : null}
          </Fragment>
        ))}
      </View>
    </View>
  );
}
