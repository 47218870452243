import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Grid,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import moment from "moment";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import { useSelector } from "react-redux";
import { getHistorialPedido } from "../../../../../requests/urls";
import request from "../../../../../requests/request";

const useStyles = makeStyles({
  subtitle: {
    fontWeight: "bold",
    marginRight: 5,
  },
  borderlessRow: {
    borderBottom: 0,
  },
  cancelButton: {
    color: "white",
    fontSize: 12,
  },
});

export default function ModalHistorialPedido({
  open,
  pedido = null,
  onClose,
  idPedido,
}) {
  const classes = useStyles();

  const { dataForm } = useSelector((state) => state.pedido);

  const [historial, setHistorial] = useState([]);

  const handleGetHistorial = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getHistorialPedido(idPedido),
      });
      let historialResponse = response.data.data.items;
      setHistorial(historialResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formatHistorial = (historial) => {
    return (
      historial &&
      historial.map((d) => {
        const baseDetail = {
          creado: moment(d.creado).format("DD-MM-YYYY HH:mm:ss"),
          codProv: d.codigo_proveedor ? d.codigo_proveedor : "---",
          detalle: d.detalle,
          responsable_completo: d.responsable_completo,
        };

        return baseDetail;
      })
    );
  };

  const handleFormatColumnas = () => {
    const columnas = ["Fecha", "Código Prov.", "Acción", "Responsable"];

    return columnas;
  };

  useEffect(() => {
    if (open && idPedido) {
      handleGetHistorial();
    }
  }, [open, idPedido]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        Historial de pedido n°{idPedido}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container>
          <Grid container item justify={"center"} className="mb-2">
            <Grid item xs={6} md={6}>
              <Box display="flex">
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Proveedor
                </Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  {pedido
                    ? pedido.proveedor.razonSocial
                    : dataForm.proveedor.razonSocial}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6} md={6} item>
              <Box display="flex">
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Fecha de emisión:{" "}
                  {pedido
                    ? moment(pedido.emision).format("DD-MM-YYYY HH:mm:ss")
                    : moment(dataForm.emision).format("DD-MM-YYYY HH:mm:ss")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box display="flex" mt={1}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Fecha de entrega:{" "}
                  {pedido
                    ? moment(pedido.entrega).format("DD-MM-YYYY")
                    : moment(dataForm.entrega).format("DD-MM-YYYY")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item container>
            <ReusableTable
              columns={handleFormatColumnas()}
              items={formatHistorial(historial)}
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => onClose()}
          >
            SALIR
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
