import React, { useState } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import ModalReutilizable from "../../../components/ait-reusable/Modal/index";
import request from "../../../requests/request";
import { validateNotasCredito } from "../../../requests/urls";
import {
  successNotification,
  errorNotification,
} from "../../../components/Notifications";
import { AlertReusable } from "../../../components/ait-reusable/Alerts/AlertReusable";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import useValidacionMensajeErrorAFIP from "../../../customHooks/useValidacionMensajeErrorAFIP";

const MensajeModalFCEM = () => {
  return (
    <>
      <span>
        <span style={{ fontWeight: "bold" }}>S:</span> El cliente{" "}
        <span style={{ fontWeight: "bold" }}>RECHAZÓ</span> el comprobante
        facturado.
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>N:</span> El cliente{" "}
        <span style={{ fontWeight: "bold" }}>ACEPTO</span> el comprobante
        facturado.
      </span>
    </>
  );
};

export default function ModalValidateCAE({
  open,
  handleClose,
  nota,
  setDataErrorCAE,
}) {
  const [disabled, setDisabled] = useState(false);
  const [messageError, setMessageError] = useState(null);
  const [anulacionFCEM, setAnulacionFCEM] = useState("N");
  const { getDataErrorVentasCAE, getDataInfoErrorAnulacion } =
    useValidacionMensajeErrorAFIP();

  const valoresFCEMA = [
    {
      id: 0,
      value: "S",
    },
    {
      id: 1,
      value: "N",
    },
  ];
  const ventaNC = nota.venta
    ? nota.venta
    : nota.ventas_agrupadas && nota.ventas_agrupadas.length > 0
      ? nota.ventas_agrupadas[0]
      : null;
  const tipoComprobanteVenta = ventaNC ? ventaNC.tipo_comprobante.nombre : "";

  const validateCAE = async () => {
    setDisabled(true);
    try {
      const response = await request({
        method: "PUT",
        url: validateNotasCredito,
        data: {
          notas_credito: [
            {
              nota: nota.idNotaCredito,
              anulacion_fcem:
                tipoComprobanteVenta === "FCEM A" ? anulacionFCEM : "",
            },
          ],
        },
        params: {
          paginated: 0,
        },
      });
      handleResponseValidate(response);
    } catch (error) {
      errorNotification("Ocurrió un error al validar la nota crédito");
      setDisabled(false);
      if (Array.isArray(error.response.data)) {
        const errorData = error.response.data.join(" ");
        let message = null;
        if (errorData.includes("Error al validar la factura")) {
          let data = getDataErrorVentasCAE(errorData);

          if (data && data[0]) {
            setDataErrorCAE({
              open: true,
              idComprobante: data[0].idVenta,
              isNotaCredito: false,
              msgErrorValidacionAFIP: data[0].mensajeError,
            });
            handleClose();
          }
        } else if (errorData.includes("Error al validar la nota de crédito")) {
          let data = getDataInfoErrorAnulacion(errorData);

          setDataErrorCAE({
            open: true,
            idComprobante: data.idNC,
            isNotaCredito: true,
            msgErrorValidacionAFIP: data.mensajeError,
          });
          handleClose();
        } else if (errorData.includes("No se pudo validar la factura")) {
          message =
            "Ocurrió un error al buscar la factura asociada a esta nota crédito.";
        } else if (
          errorData.includes("Factura validada") ||
          errorData.includes("No se pudo generar la nota de credito en afip")
        ) {
          message =
            "La factura asociada a la nota crédito estaba pendiente de CAE y fue validada correctamente pero ocurrió un error al registrar la nota de crédito en ARCA.";
        } else if (errorData.includes("Boxer fallida")) {
          message =
            "La nota crédito se validó correctamente con ARCA pero no se pudo actualizar la misma en Boxer. Comuníquese con soporte.";
        } else {
          message = "Ocurrió un error al validar la nota crédito con ARCA.";
        }
        setMessageError(message);
      }
    }
  };

  const handleResponseValidate = (res) => {
    if (res.data.status === 201) {
      successNotification("El número de CAE se ha generado con éxito.");
      handleClose();
    } else {
      errorNotification(
        "El número de CAE no se ha generado, intente más tarde.",
      );
      setDisabled(false);
    }
  };

  return (
    <ModalReutilizable
      open={open}
      handleClose={handleClose}
      content={
        messageError ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AlertReusable text={messageError} severity={"error"} />
            </Grid>
            <Grid item xs={12}>
              <h5
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  textAlign: "center",
                }}
              >
                {"¿Desea volver a intentar?"}
              </h5>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={2}>
              {/* Si la nc esta asociada a una factura y esta no tiene nroFactura debe mostrar mensaje que tiene que generar cae de la factura */}
              {nota.idFactura && !nota.idFactura.nroFactura && (
                <Grid item xs={12}>
                  <AlertReusable
                    text={
                      "Esta nota crédito está asociada a una factura pendiente de CAE por lo que primero se validará la factura y luego la nota de crédito."
                    }
                    severity={"info"}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <h5
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    textAlign: "center",
                  }}
                >
                  {`¿Esta seguro que desea generar un número de CAE para este comprobante?`}
                </h5>
              </Grid>
            </Grid>
            {tipoComprobanteVenta === "FCEM A" && (
              <Grid
                container
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <AlertReusable
                    severity={"info"}
                    text={
                      "Esta nota de crédito está asociada a una Factura FCEM A por lo que debe seleccionar un valor de anulación."
                    }
                  />
                </Grid>
                <Grid item xs={10} md={5} style={{ paddingTop: "1em" }}>
                  <TextField
                    select
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={"Valor de anulación"}
                    value={anulacionFCEM || ""}
                    onChange={(e) => {
                      setAnulacionFCEM(e.target.value);
                    }}
                  >
                    {valoresFCEMA.map((x, i) => (
                      <MenuItem value={x.value} key={x.id}>
                        {x.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={1} style={{ paddingTop: "1em" }}>
                  <TooltipMoreInfoMaterial
                    position={"right"}
                    titleTooltip={<MensajeModalFCEM />}
                    color="rgb(8, 150, 255)"
                  />
                </Grid>
              </Grid>
            )}
          </>
        )
      }
      title={"Validar Nota de Crédito"}
      messageAceptar={"GENERAR"}
      messageCancelar={"CANCELAR"}
      handleSubmit={validateCAE}
      openBackdrop={disabled}
    />
  );
}
