import { Grid, TextField } from "@material-ui/core";
import React from "react";

export default function DetalleCrearPedido({
  cantidad,
  aliasValue,
  setCantidad,
  setAliasValue,
}) {
  return (
    <Grid
      container
      spacing={2}
      style={{ marginBottom: "1em", marginTop: "1em" }}
    >
      <Grid item xs={12} className="pt-2">
        <TextField
          value={aliasValue}
          onChange={(e) => setAliasValue(e.target.value)}
          fullWidth
          autoFocus
          variant="outlined"
          size="small"
          label="Alias para el nuevo pedido"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          type="number"
          label="Cantidad"
          variant="outlined"
          value={cantidad}
          onChange={(e) => setCantidad(e.target.value)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
