import { pdf } from "@react-pdf/renderer";
import React from "react";
import EtiquetasA4Bulk from "../../../../components/ait-reusable/Etiquetas/EtiquetasA4/EtiquetasA4Bulk";
import EtiquetasCartaBulk from "../../../../components/ait-reusable/Etiquetas/EtiquetasCarta/EtiquetasCartaBulk";
import EtiquetasTicketBulk from "../../../../components/ait-reusable/Etiquetas/EtiquetasTicket/EtiquetasTiquetBulk";
import { getSizeEtiqueta } from "../../../../components/ait-reusable/Etiquetas/ModalTamaños/utils";
import request from "../../../../requests/request";

export const updateRubroService = async (articulos, rubro, esSubRubro) => {
  try {
    const response = await request({
      url: "/api/articulos/update-rubro/",
      method: "PUT",
      data: {
        rubro,
        codigos: articulos,
        es_sub_rubro: esSubRubro,
      },
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPDF = (
  tableData,
  formato_etiqueta,
  formatoHoja,
  configGeneral,
  configuracionEtiqueta,
  sucursal,
  setOpenBackdrop,
) => {
  let data = getSizeEtiqueta(
    formato_etiqueta.alto,
    formato_etiqueta.ancho,
    formatoHoja,
  );

  const filasArray = {
    cant: data.cantAlto,
    array: Array.from({ length: data.cantAlto }, (_, i) => i),
  };
  const columnasArray = {
    cant: data.cantAncho,
    array: Array.from({ length: data.cantAncho }, (_, i) => i),
  };

  if (formatoHoja === "Carta") {
    setOpenBackdrop(true);
    const blob = pdf(
      <EtiquetasCartaBulk
        articulos={tableData}
        sucursal={sucursal}
        alto={formato_etiqueta.alto}
        ancho={formato_etiqueta.ancho}
        filasArray={filasArray}
        columnasArray={columnasArray}
        configGeneral={configGeneral}
        configuracionEtiqueta={configuracionEtiqueta}
      />,
    ).toBlob();
    blob.then((res) => {
      const bloblURL = window.URL.createObjectURL(res);
      window.open(bloblURL);
      setOpenBackdrop(false);
    });
  }
  if (formatoHoja === "A4") {
    setOpenBackdrop(true);
    const blob = pdf(
      <EtiquetasA4Bulk
        articulos={tableData}
        sucursal={sucursal}
        alto={formato_etiqueta.alto}
        ancho={formato_etiqueta.ancho}
        filasArray={filasArray}
        columnasArray={columnasArray}
        configGeneral={configGeneral}
        configuracionEtiqueta={configuracionEtiqueta}
      />,
    ).toBlob();
    blob.then((res) => {
      const bloblURL = window.URL.createObjectURL(res);
      window.open(bloblURL);
      setOpenBackdrop(false);
    });
  }
  if (formatoHoja === "Ticket") {
    setOpenBackdrop(true);
    const blob = pdf(
      <EtiquetasTicketBulk
        alto={formato_etiqueta.alto}
        ancho={formato_etiqueta.ancho}
        articulos={tableData}
        sucursal={sucursal}
        configGeneral={configGeneral}
        configuracionEtiqueta={configuracionEtiqueta}
      />,
    ).toBlob();
    blob.then((res) => {
      const bloblURL = window.URL.createObjectURL(res);
      window.open(bloblURL);
      setOpenBackdrop(false);
    });
  }
};
