import { Button, Grid, MenuItem, TextField } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import React, { useEffect } from "react";
import ButtonFormato from "../../../../../components/ait-reusable/Button/ButtonFormato";
import { textSizes } from "../../../../Configuration/ConfiguracionEtiquetas/Componentes/Utils";

export default function Options({
  etiquetaSize,
  setEtiquetaSize,
  formatoHoja,
  setFormatoHoja,
  formato_etiqueta,
  handleGetPDF,
  disabled,
}) {
  useEffect(() => {
    if (!formatoHoja) {
      setFormatoHoja("Ticket");
    }
  }, [formatoHoja, setFormatoHoja]);

  return (
    <Grid container spacing={2} className="d-flex my-3" alignItems="center">
      <Grid item xs={12} sm={3}>
        <TextField
          padding={0}
          select
          fullWidth
          size="small"
          value={etiquetaSize}
          variant="outlined"
          label="Tamaño de etiquetas"
          onChange={(e) => {
            const selectedId = e.target.value;
            const selectedEtiqueta = formato_etiqueta.find(
              (etiqueta) => etiqueta.id === selectedId,
            );
            if (selectedEtiqueta) {
              setEtiquetaSize(selectedEtiqueta);
            }
          }}
          style={{ maxWidth: "350px" }}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            },
            renderValue: (selected) => {
              const selectedEtiqueta = formato_etiqueta.find(
                (etiqueta) => etiqueta.id === selected.id,
              );
              return selectedEtiqueta
                ? `${selectedEtiqueta.ancho}mm x ${selectedEtiqueta.alto}mm`
                : "";
            },
          }}
        >
          {formato_etiqueta &&
            formato_etiqueta
              .filter((etiqueta) => etiqueta.es_visible)
              .map((etiqueta) => (
                <MenuItem
                  key={etiqueta.id}
                  value={etiqueta.id}
                  disabled={!etiqueta.es_visible}
                  style={{
                    whiteSpace: "nowrap",
                    maxWidth: "350px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>
                      {etiqueta.ancho}mm x {etiqueta.alto}mm{" "}
                      {etiqueta.ancho === 100 && etiqueta.alto === 50
                        ? '"Vertical"'
                        : ""}
                    </span>
                    <span
                      style={{
                        fontSize: "0.8rem",
                        color: "gray",
                        whiteSpace: "normal",
                      }}
                    >
                      {textSizes[`${etiqueta.ancho}x${etiqueta.alto}`] ||
                        "Todos los datos seleccionados"}
                    </span>
                  </div>
                </MenuItem>
              ))}
        </TextField>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={8}
        spacing={2}
        style={{ justifyContent: "flex-start", alignItems: "center" }}
      >
        <Grid item>
          <ButtonFormato
            tipo="Ticket"
            message="Ticket"
            icon="receipt"
            click={() => setFormatoHoja("Ticket")}
            format={formatoHoja}
          />
        </Grid>
        {/* <Grid item>
          <ButtonFormato
            tipo="A4"
            message="A4"
            icon="print"
            click={() => setFormatoHoja("A4")}
            format={formatoHoja}
          />
        </Grid>
        <Grid item>
          <ButtonFormato
            tipo="Carta"
            message="Carta"
            icon="print"
            click={() => setFormatoHoja("Carta")}
            format={formatoHoja}
          />
        </Grid> */}
        <Grid item>
          <Button
            onClick={handleGetPDF}
            style={{ fontWeight: "bold" }}
            variant="contained"
            size="small"
            color="primary"
            startIcon={<PrintIcon />}
            fullWidth
            disabled={disabled}
          >
            IMPRIMIR
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
