import React from "react";
import {
  Checkbox,
  AccordionSummary,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStore from "./store";

const Header = ({ name, module_key }) => {
  const modules_selected = useStore((state) => state.modules_selected);

  const selectModule = useStore((state) => state.selectModule);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleClickModule = (event) => {
    stopPropagation(event);

    selectModule(module_key);
  };

  return (
    <AccordionSummary style={{ fontSize: 20 }} expandIcon={<ExpandMoreIcon />}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={modules_selected.includes(module_key)}
          />
        }
        label={name}
        onFocus={stopPropagation}
        onClick={(e) => handleClickModule(e)}
      />
    </AccordionSummary>
  );
};

export default Header;
