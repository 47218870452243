import { useRef } from "react";
import { errorNotification } from "../components/Notifications";

const useNotifyErrorStock = () => {
  const notifyStockRef = useRef(false);

  return () => {
    const state = notifyStockRef.current;

    if (state) {
      /** evita render del mensaje */
      return;
    }

    notifyStockRef.current = true;

    const time_ms = 1000 * 20;

    const error_message_sin_stock =
      "No tenes permiso para vender articulos sin stock. Debe autorizarlo un usuario con perfil administrador.";

    errorNotification(error_message_sin_stock, time_ms);

    setTimeout(() => {
      notifyStockRef.current = false;
    }, time_ms);
  };
};

export default useNotifyErrorStock;
