import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";
import { parseCurrency } from "../../../../utils/parsers";

export default function RegimenTransparenciaFiscalA4({ ivaAmount }) {
  return (
    <View
      style={
        (styles.containerColumn,
        {
          width: "95%%",
          marginTop: 15,
          color: "#1B71BA",
          // marginLeft: 20,
          paddingLeft: 5,
          borderTop: 1,
          borderTopColor: "#1B71BA",
          paddingTop: 5,
        })
      }
    >
      <Text
        style={(styles.textTotalesFactura, { extAlign: "left", fontSize: 8 })}
      >
        Régimen de Transparencia Fiscal al Consumidor (Ley 27.743)
      </Text>

      <Text
        style={(styles.textTotalesFactura, { extAlign: "left", fontSize: 8 })}
      >
        IVA Contenido {parseCurrency(Number(ivaAmount))}
      </Text>
      <Text
        style={(styles.textTotalesFactura, { extAlign: "left", fontSize: 8 })}
      >
        Otros impuestos Nacionales Indirectos $ 0,00
      </Text>
    </View>
  );
}
