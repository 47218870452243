import { Card, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { updateConfigGeneral } from "../../../Redux/Actions/actionConfigGeneral";
import {
  getConfiguracionEtiquetas,
  updateConfiguracionEtiquetas,
} from "../../../Redux/Actions/configEtiquetasActions";
import ConfiguracionDeDetalles from "./Componentes/ConfiguracionDeDetalles";
import DetallesAMostrar from "./Componentes/DetallesAMostrar";
import EtiquetaDefault from "./Componentes/EtiquetaDefault";
import PrevisualizacionPdf from "./Componentes/PrevisualizacionPdf";

export default function ConfiguracionEtiquetas() {
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { configuracionEtiquetas } = useSelector(
    (state) => state.configEtiquetas,
  );

  const formik = useFormik({
    initialValues: {
      ...configuracionEtiquetas,
      etiquetaDefault:
        (configGeneral &&
          configGeneral.formato_etiqueta &&
          configGeneral.formato_etiqueta.find(
            (etiqueta) => etiqueta.es_default,
          ) &&
          configGeneral.formato_etiqueta.find((etiqueta) => etiqueta.es_default)
            .id) ||
        null,
    },
    onSubmit: async (values) => {
      try {
        await handlePutConfiguracionEtiquetas(values);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const fetchConfiguracionEtiquetas = async () => {
    dispatch(getConfiguracionEtiquetas());
  };

  useEffect(() => {
    if (Object.keys(configuracionEtiquetas).length === 0) {
      fetchConfiguracionEtiquetas();
    } else {
      formik.setValues({ ...formik.values, ...configuracionEtiquetas });
    }
  }, [dispatch, configuracionEtiquetas]);

  const handlePutConfiguracionEtiquetas = async (values) => {
    setIsSubmitting(true);
    const { etiquetaDefault, ...valuesWithoutEtiquetaDefault } = values;

    const etiquetaEncontrada = configGeneral.formato_etiqueta.find(
      (etiqueta) => etiqueta.id === etiquetaDefault,
    );

    try {
      if (etiquetaEncontrada) {
        const updatedConfig = {
          ...configGeneral,
          formato_etiqueta: configGeneral.formato_etiqueta.map((etiqueta) =>
            etiqueta.id === etiquetaDefault
              ? { ...etiqueta, es_default: true }
              : { ...etiqueta, es_default: false },
          ),
        };

        dispatch(updateConfigGeneral(updatedConfig));
      }
      dispatch(updateConfiguracionEtiquetas(valuesWithoutEtiquetaDefault));

      successNotification("Configuración de etiquetas guardada correctamente");
    } catch (error) {
      console.log(error);
      errorNotification("Error al guardar la configuración de etiquetas");
    }
    setIsSubmitting(false);
  };

  return (
    <Card className="p-3">
      <Grid container>
        <Grid item xs={12}>
          <Alert severity="info">
            Acá podrás configurar las etiquetas de todos tus artículos. Podrás
            elegir qué información visualizar y de qué manera. Se recomienda no
            seleccionar todos los detalles para que puedan visualizarse
            correctamente y no se superpongan ya que el tamaño de la etiqueta es
            limitado.
          </Alert>
        </Grid>
        <Grid item xs={3}>
          <DetallesAMostrar formik={formik} />
        </Grid>
        <Grid item container xs={9}>
          <Grid item xs={9}>
            <ConfiguracionDeDetalles formik={formik} />
          </Grid>
          <Grid item xs={12}>
            <EtiquetaDefault formik={formik} />
          </Grid>
          <Grid item xs={12}>
            <PrevisualizacionPdf formik={formik} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <ButtonAceptar
          message="GUARDAR CAMBIOS"
          click={formik.handleSubmit}
          disabled={isSubmitting}
        />
      </Grid>
      <CircularBackdrop openBackdrop={isSubmitting} />
    </Card>
  );
}
