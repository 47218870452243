import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleTicket";
import { parseCurrency } from "../../../../utils/parsers";

export default function RegimenTransparenciaFiscalTicket({ montoIva }) {
  return (
    <View style={{ paddingHorizontal: 10 }}>
      <Text>--------------------------------</Text>
      <Text style={styles.textTotalesFactura}>
        Régimen de Transparencia Fiscal al Consumidor (Ley 27.743)
      </Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={[styles.containerColumn, { width: "82%" }]}>
          <View>
            <Text style={styles.textTotalesFactura}>IVA Contenido</Text>
            <Text style={styles.textTotalesFactura}>
              Otros impuestos Nacionales Indirectos
            </Text>
          </View>
        </View>
        <View style={styles.containerColumn2}>
          <View>
            <Text style={styles.textTotalesFactura}>
              {parseCurrency(Number(montoIva))}
            </Text>
            <Text style={styles.textTotalesFactura}>{parseCurrency(0)}</Text>
          </View>
        </View>
      </View>
      <Text>--------------------------------</Text>
    </View>
  );
}
