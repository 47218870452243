import React, { useState, useEffect } from "react";
import MaskedInput from "react-text-mask";
import { FormHelperText, TableCell } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { cambiarCantidad } from "../../../Redux/Actions/presupuestoActions";

const styleError = { color: "#c4183c", fontSize: 11.2, textAlign: "center" };

const InputUnit = ({
  id_index,
  idOrden,
  articulo,
  isVendedor,
  isVenta,
  PERMISOS,
  detalles,
  empleado,
  descuento,
  medioPago,
  tienePermisos,
  onNotifyErrorStock,
  setDesabilitaRegistrarVenta,
}) => {
  const [value, setValue] = useState(articulo.cantidad);
  const [errorUnit, setErrorUnit] = useState({
    isError: false,
    message: "",
  });

  useEffect(() => {
    setValue(articulo.cantidad);
  }, [articulo.cantidad]);

  const dispatch = useDispatch();

  const handleError = () =>
    setErrorUnit({
      isError: true,
      message: "No hay suficiente stock",
    });

  const handlerProcess = (event) => {
    setErrorUnit({
      isError: false,
      message: "",
    });

    dispatch(cambiarCantidad(detalles, id_index, event, descuento, medioPago));
  };

  const handleChange = (event) => {
    setValue(event.target.value);

    if (isVendedor && isVenta) {
      /***
       * Venta por descripcion
       */
      if (articulo.idRepuestoProveedor === null) {
        return handlerProcess(event);
      }

      const permisoParaVenderSinStock = tienePermisos(
        empleado,
        PERMISOS.VENTAS_ARTICULO_SIN_STOCK,
      );

      if (permisoParaVenderSinStock) {
        return handlerProcess(event);
      }

      if (!articulo.repuesto_sucursal) {
        onNotifyErrorStock();

        return;
      }

      if (articulo.repuesto_sucursal.stockDisponible === null) {
        onNotifyErrorStock();

        return;
      }

      if (event.target.value > articulo.repuesto_sucursal.stockDisponible) {
        onNotifyErrorStock();

        handleError();

        return;
      }

      return handlerProcess(event);
    } else {
      handlerProcess(event);
    }
  };

  const handler = (articulo) => {
    if (!isVendedor && !isVenta) {
      return;
    }

    /***
     * Venta por descripcion
     */
    if (articulo.idRepuestoProveedor === null) {
      return;
    }

    const permisoParaVenderSinStock = tienePermisos(
      empleado,
      PERMISOS.VENTAS_ARTICULO_SIN_STOCK,
    );

    if (permisoParaVenderSinStock) {
      return;
    }

    if (!articulo.repuesto_sucursal) {
      onNotifyErrorStock();

      return;
    }

    if (articulo.repuesto_sucursal.stockDisponible === null) {
      onNotifyErrorStock();

      return;
    }

    if (articulo.cantidad > articulo.repuesto_sucursal.stockDisponible) {
      onNotifyErrorStock();

      handleError();

      return;
    }
  };

  const handleBlur = (e) => {
    if (e.target.value.trim() === "") {
      setValue(1);
    }
  };

  useEffect(() => {
    handler(articulo);
  }, [articulo]);

  useEffect(() => {
    if (errorUnit.isError) {
      setDesabilitaRegistrarVenta(true);
    } else {
      setDesabilitaRegistrarVenta(false);
    }
  }, [errorUnit.isError]);

  return (
    <TableCell align="center" style={{ minWidth: 110, maxWidth: 110 }}>
      <MaskedInput
        style={
          errorUnit.isError
            ? { ...styleError, border: "1px solid #c4183c" }
            : {}
        }
        className="text-center form-control"
        guide={false}
        mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        onFocus={(event) => event.target.select()}
        disabled={idOrden ? true : false}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        invalid={!articulo.cantidadValida}
        name="cantidad"
      />

      {/* Mensaje de error si la cantidad no es válida */}
      {!articulo.cantidadValida && (
        <FormHelperText style={styleError}>Cantidad inválida</FormHelperText>
      )}

      {/* Mostrar error según `errorUnit` */}
      {errorUnit.isError && (
        <FormHelperText style={styleError}>{errorUnit.message}</FormHelperText>
      )}
    </TableCell>
  );
};

export default InputUnit;
