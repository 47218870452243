import React, { useState, useEffect } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import request from "../../../../requests/request";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import DetallesPedidoModal from "./DetallesPedidoModal";
import DetalleAgregarPedido from "./DetalleAgregarPedido";
import DetalleCrearPedido from "./DetalleCrearPedido";
import {
  getPedidos,
  postPutPedidoDesdeRepuesto,
} from "../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import DetalleArticulo from "./DetalleArticulo";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: 500,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ModalPedido({ open, close, articuloSeleccionado }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [pedido, setPedido] = useState(null);
  const [descripcion, setDescripcion] = useState("");
  const [agregarPedido, setAgregarPedido] = useState(false);
  const [errorPedido, setErrorPedido] = useState(false);
  const [aliasValue, setAliasValue] = useState("");
  const [cantidad, setCantidad] = useState(1);
  const [pedidosAbiertos, setPedidosAbiertos] = useState([]);

  const postAndPutPedido = async (e) => {
    setLoadingSubmit(true);
    const dataPut = {
      repuesto_proveedor_id:
        articuloSeleccionado.idRepuestoProveedor ||
        articuloSeleccionado.articuloClicked.idRepuestoProveedor,
      pedido_id: pedido && pedido.id,
      cantidad: cantidad,
    };

    const dataPost = {
      repuesto_proveedor_id:
        articuloSeleccionado.idRepuestoProveedor ||
        articuloSeleccionado.articuloClicked.idRepuestoProveedor,
      alias: aliasValue ? aliasValue : null,
      cantidad: cantidad,
    };

    try {
      const response = await request({
        method: !agregarPedido ? "PUT" : "POST",
        url: postPutPedidoDesdeRepuesto,
        data: !agregarPedido ? dataPut : dataPost,
      });
      setLoadingSubmit(false);
      successNotification(
        !agregarPedido
          ? "El artículo se ha agregado al pedido exitosamente."
          : "El pedido se ha creado exitosamente.",
      );
      close();
    } catch (error) {
      console.error(error);
      errorNotification("Ha ocurrido un error.");

      setLoadingSubmit(false);
    }
  };

  const onSubmitValidation = (e) => {
    e.preventDefault();

    if (!agregarPedido && !pedido) {
      setErrorPedido(true);
    } else {
      postAndPutPedido(e);
    }
  };

  const validatePedido = (e) => {
    const existPedido = e.target.value;

    if (existPedido) {
      setErrorPedido(false);
    } else {
      setErrorPedido(true);
    }
  };

  const handlePostPedido = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getPedidos,
        params: {
          proveedor:
            articuloSeleccionado.idProveedor ||
            articuloSeleccionado.articuloClicked.idProveedor,
          estado: 1,
        },
      });
      let pedidosResponse = response.data.data.items;
      setPedidosAbiertos(pedidosResponse);
      if (pedidosResponse.length == 0) {
        setAgregarPedido(true);
      }
    } catch (error) {
      console.error(error);
      errorNotification(
        "Ocurrió un error al obtener los pedidos, por favor vuelva a intentarlo o agregue uno nuevo.",
      );
      setAgregarPedido(true);
    }
  };

  useEffect(() => {
    if (pedidosAbiertos.length == 1) {
      setPedido(pedidosAbiertos[0]);
    }
  }, [pedidosAbiertos]);

  useEffect(() => {
    if (open) {
      handlePostPedido();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={close} classes={{ paper: classes.root }}>
      <form onSubmit={(e) => onSubmitValidation(e)}>
        <DialogTitle>Agregar a pedido</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid
            container
            justifyContent="flex-start"
            style={{ marginBottom: "1em" }}
          >
            <DetalleArticulo articulo={articuloSeleccionado} />
          </Grid>
          <Divider />
          {pedidosAbiertos.length > 0 ? (
            <>
              <Grid
                container
                justifyContent="flex-start"
                style={{ marginBottom: "1em", marginTop: "1em" }}
              >
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <span>Agregar a un pedido</span>
                    </Grid>
                    <Grid item>
                      <Switch
                        color="primary"
                        checked={agregarPedido}
                        onChange={() => setAgregarPedido(!agregarPedido)}
                      />
                    </Grid>
                    <Grid item>
                      <span>Nuevo pedido</span>
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider />
            </>
          ) : null}
          {!agregarPedido && (
            <>
              <Grid
                container
                spacing={2}
                style={{ marginBottom: "1em", marginTop: "1em" }}
              >
                <Grid item xs={12}>
                  <Typography color="textSecondary">
                    ¡Selecciona el pedido al cual querés agregarle el artículo!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    loading={loading}
                    value={pedido}
                    autoHighlight
                    options={pedidosAbiertos}
                    filterOptions={(options, state) => options}
                    getOptionLabel={(pedido) =>
                      `ALIAS: ${pedido.alias ? pedido.alias : "- - -"}`
                    }
                    inputValue={descripcion || ""}
                    onInputChange={(event, value) => {
                      setDescripcion(value);
                    }}
                    onChange={(event, value) => setPedido(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errorPedido)}
                        onBlur={(e) => validatePedido(e)}
                        helperText={
                          Boolean(errorPedido) &&
                          "Es necesario elegir un pedido"
                        }
                        label="Pedido"
                        variant="outlined"
                      />
                    )}
                    name="buscador_pedidos"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    type="number"
                    label="Cantidad"
                    variant="outlined"
                    value={cantidad}
                    onChange={(e) => setCantidad(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Divider />
            </>
          )}

          <DetallesPedidoModal pedido={pedido}>
            {!agregarPedido && pedido && (
              <DetalleAgregarPedido pedido={pedido} />
            )}
            {agregarPedido && (
              <DetalleCrearPedido
                cantidad={cantidad}
                aliasValue={aliasValue}
                setCantidad={setCantidad}
                setAliasValue={setAliasValue}
              />
            )}
          </DetallesPedidoModal>
        </DialogContent>
        <Box pt={1}>
          <Divider />
        </Box>
        <DialogActions>
          <Box mr={1}>
            <ButtonCancelar message={"Cancelar"} click={close} />
          </Box>
          <ButtonAceptar
            message={!agregarPedido ? "Agregar al pedido" : "Nuevo pedido"}
            disabled={errorPedido && !agregarPedido}
          />
        </DialogActions>
        {loadingSubmit && (
          <Backdrop className={classes.backdrop} open={loadingSubmit}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </form>
    </Dialog>
  );
}
