import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";

export default function ObservacionesTicket({ data }) {
  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "space-between",
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <Text
        style={styles.text}
      >{`Observaciones: ${data.observacion || "---"}`}</Text>
      <Text style={styles.text}>
        {`Observaciones de pago: ${
          (data.orden_carga_saldo && data.orden_carga_saldo.concepto) || "---"
        }`}
      </Text>
      <Text style={styles.text}>
        {`Descuento: ${Number(data.descuento_porcentaje).toLocaleString("es-AR")}%`}
      </Text>
    </View>
  );
}
