import request from "../../../../requests/request";
import { observacionArticulo } from "../../../../requests/urls";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";

export const handleSubmitObservaciones = async (
  obs,
  articulo,
  setModalObservacion,
  setArticuloModal,
  detalles,
  actualizarObservaciones,
  dispatch,
) => {
  try {
    const response = await request({
      method: "PUT",
      url: observacionArticulo,
      data: {
        idRepuestoProveedor: articulo.idRepuestoProveedor,
        observaciones: obs,
      },
    });
    setModalObservacion(false);
    setArticuloModal(null);
    successNotification("Observaciones actualizadas con éxito");
    dispatch(actualizarObservaciones(detalles, articulo, obs));
  } catch (err) {
    console.log(err);
    errorNotification("Error, intente nuevamente");
  }
};
