import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import FiltroPublicaciones from "./Componentes/FiltroPublicaciones";
import ListPublicaciones from "./Componentes/ListPublicaciones";
import { useDebounce } from "../../../../customHooks/useDebounce";
import { useGetPublicaciones } from "../services/publicacionesServices";
import Pagination from "react-js-pagination";
import Spinner from "../../../../components/Spinner";
import NoResults from "../../../../components/ait-reusable/NoResults";
import { getHostClient } from "../utils";
import { useSelector } from "react-redux";
import UpdateInformation from "./Componentes/UpdateInformation";
import { ButtonCache } from "./Componentes/ButtonCache";
import { useMeliContext } from "../../../../context/MeliContext";

export default function PublicacionesMercadoLibre({ props }) {
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    page: 0,
    offset: 0,
    query: "",
    publicacion: 0,
    tipo: "todas",
    estado: "todas",
    cliente_url: getHostClient(),
  });
  const itemsPorPagina = 10;
  const debouncedSearchPubl = useDebounce(search, 500);
  const { articuloVinculacion } = useSelector(
    (state) => state.vinculacionesMELIReducer,
  );
  const { dataLink, dataDesvinculacionVariante } = useMeliContext();

  const getPublicaciones = useGetPublicaciones({
    queryParams: {
      render: {
        ...filters,
      },
      noRender: {
        buscarPor: search,
      },
    },
    queryProps: {
      retry: false,
    },
  });

  useEffect(() => {
    let newPage = 0;
    let newOffset = newPage * itemsPorPagina;

    setFilters((prev) => ({
      ...prev,
      page: newPage,
      offset: newOffset,
      query: debouncedSearchPubl,
    }));
  }, [debouncedSearchPubl]);

  const handleChangePagination = (newPage) => {
    let newOffset = newPage * itemsPorPagina;

    setFilters((prev) => ({
      ...prev,
      page: newPage,
      offset: newOffset,
    }));
  };
  useEffect(() => {
    let value = localStorage.getItem("filtroEstadoPubl");

    if (value !== null) {
      setFilters((prev) => ({
        ...prev,
        estado: value,
      }));
    } else {
      //El estado siempre empieza en "todas" por eso no se hace el seteo aca
      localStorage.setItem("filtroEstadoPubl", "todas"); //todas = activas y pausadas
    }
  }, []);

  useEffect(() => {
    if (dataLink.linkEstablished) {
      getPublicaciones.refetch();
      dataLink.setLinkEstablished(false);
      dataDesvinculacionVariante.setDesvinculacionErrorVariante(false);
    }
  }, [dataLink.linkEstablished]);

  return (
    <>
      <Grid container spacing={2} style={{ paddingTop: 8, paddingBottom: 15 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FiltroPublicaciones
            search={search}
            setSearch={setSearch}
            filters={filters}
            setFilters={setFilters}
            articuloVinculacion={articuloVinculacion}
          />
        </Grid>
      </Grid>
      {/* <UpdateInformation /> */}
      {!getPublicaciones.isFetching && (
        <ButtonCache getPublicaciones={getPublicaciones} />
      )}

      {getPublicaciones.isFetching ? (
        <div className={"mt-5 mb-2"}>
          <Spinner color="#66b3ff" type="spin" />
        </div>
      ) : getPublicaciones.data && getPublicaciones.data.results.length > 0 ? (
        <>
          <Grid
            container
            spacing={2}
            style={{
              paddingTop: 8,
              overflow: "auto",
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ListPublicaciones
                publicaciones={getPublicaciones.data.results}
                history={props.history}
                articuloVinculacion={articuloVinculacion}
                getPublicaciones={getPublicaciones}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className="d-flex mt-4 mb-0 justify-content-center"
          >
            <Pagination
              activePage={filters.page + 1}
              itemsCountPerPage={itemsPorPagina}
              totalItemsCount={getPublicaciones.data.count}
              pageRangeDisplayed={5}
              onChange={(newPage) => handleChangePagination(newPage - 1)}
              itemClass="page-item"
              linkClass="page-link"
            />
          </Grid>
        </>
      ) : (
        <NoResults noResult={"No se encontraron publicaciones."} />
      )}
    </>
  );
}
