import { View } from "@react-pdf/renderer";
import React from "react";
import {
  mostrarCodigosArticulo,
  mostrarDescripcionArticuloExtendida,
  mostrarFechaImpresion,
  mostrarLogoSucursal,
  mostrarNombreProveedor,
  mostrarNombreSucursal,
  mostrarPrecioArticulo,
  mostrarUbicacionArticulo,
  styles,
  viewCodBarra,
} from "./utils";

export default function Label55x44({
  sucursal,
  articulo,
  configGeneral,
  configuracionEtiqueta,
}) {
  const ETIQUETA_ESTILO = {
    baseFontSize: 8,
    midFontSize: 6,
    minFontSize: 4,
    maxCharBaseFontSize: 30,
    baseMarginBottom: 4,
  };
  //1px = 0.36 mm
  return (
    <View
      style={{
        ...styles.contenedorTicket,
        width: 152.7,
        minWidth: 152.7,
        maxWidth: 152.7,
        height: 122.2,
        minHeight: 122.2,
        maxHeight: 122.2,
        padding: 7,
      }}
    >
      {/* NOMBRE SUCURSAL */}
      {configuracionEtiqueta.nombreNegocio && sucursal
        ? mostrarNombreSucursal(
            sucursal.razonSocial,
            ETIQUETA_ESTILO.baseFontSize,
            ETIQUETA_ESTILO.maxCharBaseFontSize,
            ETIQUETA_ESTILO.baseMarginBottom,
          )
        : null}

      {/* CODIGOS ARTICULO */}
      {configuracionEtiqueta &&
        articulo &&
        mostrarCodigosArticulo(
          configuracionEtiqueta,
          articulo,
          ETIQUETA_ESTILO.baseFontSize,
          ETIQUETA_ESTILO.baseMarginBottom,
        )}

      {/* DESCRIPCION */}
      {configuracionEtiqueta.descripcion && articulo
        ? mostrarDescripcionArticuloExtendida(
            articulo.descripcionProveedor,
            ETIQUETA_ESTILO.baseFontSize,
          )
        : null}

      {/* PROVEEDOR Y PRECIO */}
      <View style={styles.viewProveedorPrecio}>
        {configuracionEtiqueta.nombreProveedor ||
        configuracionEtiqueta.numeroProveedor
          ? mostrarNombreProveedor(
              articulo.proveedor,
              configuracionEtiqueta.nombreProveedor,
              articulo.numero_proveedor,
              configuracionEtiqueta.numeroProveedor,
              ETIQUETA_ESTILO.minFontSize,
            )
          : null}
        {configuracionEtiqueta.precio
          ? mostrarPrecioArticulo(
              configuracionEtiqueta,
              articulo,
              ETIQUETA_ESTILO.baseMarginBottom,
              ETIQUETA_ESTILO.minFontSize,
            )
          : null}
      </View>

      {/* CODIGO BARRA + LOGO */}
      <View style={styles.viewCodeBar}>
        {configuracionEtiqueta.logotipo
          ? mostrarLogoSucursal(configGeneral.url_imagen)
          : null}

        {configuracionEtiqueta
          ? viewCodBarra(
              configuracionEtiqueta,
              articulo,
              Boolean(configuracionEtiqueta.logotipo),
            )
          : null}
      </View>

      {/* UBICACION + FECHA DE IMPRESION */}
      <View style={styles.viewUbicacionFecha}>
        {configuracionEtiqueta.ubicacion
          ? mostrarUbicacionArticulo(
              articulo.repuesto_sucursal,
              ETIQUETA_ESTILO.minFontSize,
              55,
            )
          : null}
        {configuracionEtiqueta.fechaImpresion
          ? mostrarFechaImpresion(ETIQUETA_ESTILO.minFontSize)
          : null}
      </View>
    </View>
  );
}
