import React from "react";
import {
  Grid,
  Card,
  CardActions,
  Button,
  SvgIcon,
  Box,
  Typography,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import ListTwoCol from "../List/ListTwoCol";
import Spinner from "../../Spinner";
import ErrorIcon from "@material-ui/icons/Error";
import useDisclosure from "../../../customHooks/useDisclosure";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import { useActualizarCampoConfiguracion } from "../../../views/Configuration/services/configuracion";
import { config_general } from "../../../Redux/Actions/actionConfigGeneral";
import { useDispatch, useSelector } from "react-redux";

const StatsVentas = ({
  totales = [],
  isLoading,
  incluirTodasLasDevolucionesDePeriodo,
  setIncluirTodasLasDevolucionesDePeriodo,
  customFormatSecondColumn,
}) => {
  const [
    openedModalTipoCalculo,
    { open: openModalTipoCalculo, close: closeModalTipoCalculo },
  ] = useDisclosure();
  const [initalStateTipoCalculo, setInitalStateTipoCalculo] = React.useState(
    incluirTodasLasDevolucionesDePeriodo,
  );
  const dispatch = useDispatch();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const actualizarCampo = useActualizarCampoConfiguracion({
    queryProps: {
      onSuccess: () => {
        dispatch(
          config_general({
            ...configGeneral,
            incluir_todas_devoluciones_de_periodo: initalStateTipoCalculo,
          }),
        );
      },
      onError: () => {
        // Muestra de error no necesario de momento
        // errorNotification("Error al recordar el campo");
      },
    },
  });

  const handleSubmit = () => {
    actualizarCampo.mutate({
      campo: "incluir_todas_devoluciones_de_periodo",
      value: JSON.stringify(initalStateTipoCalculo),
    });
    setIncluirTodasLasDevolucionesDePeriodo(initalStateTipoCalculo);
    closeModalTipoCalculo();
  };

  const formatSecondColumn = (value) => {
    if (!value) {
      return "$0,00";
    }

    const number = Number(value);
    if (isNaN(number)) {
      return "Invalid number";
    }

    const numberFormatted = number.toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return numberFormatted;
  };

  let total = Array.isArray(totales)
    ? totales.reduce((acc, t) => t.total + acc, 0)
    : 0;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Card>
            <ListTwoCol
              isLoading={isLoading}
              firstHead={"Conceptos"}
              lastHead={"Totales"}
              info={totales}
              formatSecondColumn={
                customFormatSecondColumn
                  ? customFormatSecondColumn
                  : formatSecondColumn
              }
            />
            <CardActions>
              <Grid
                container
                spacing={2}
                className="justify-content-center pr-0 m-0"
                style={{ width: "100%", alignItems: "center" }}
              >
                {isLoading ? (
                  <div className={"mt-2 mb-2"}>
                    <Spinner color="#66b3ff" type="spin" />
                  </div>
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h5">
                      Total ${" "}
                      {total ? Number(total).toLocaleString("es-AR") : 0}
                    </Typography>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{ color: "white" }}
                      onClick={openModalTipoCalculo}
                    >
                      <SvgIcon style={{ color: "white" }}>
                        <ErrorIcon />
                      </SvgIcon>
                    </Button>
                  </Box>
                )}
                {openedModalTipoCalculo && (
                  <ModalConfirm
                    open={openedModalTipoCalculo}
                    handleClose={closeModalTipoCalculo}
                    title="Calculo de totales"
                    showButtonAceptar={
                      initalStateTipoCalculo !==
                      incluirTodasLasDevolucionesDePeriodo
                    }
                    buttons={{
                      cancel: "Cerrar",
                      submit: "Aplicar cambios",
                    }}
                    handleSubmit={handleSubmit}
                    body={
                      <Box>
                        <Typography align="left">
                          Utilizando esta función podrás elegir como se deben
                          realizar los cálculos de los totales por medio de
                          pago.
                          <br />
                          <br />
                          <span style={{ fontWeight: "bold" }}>
                            Restar todas las NC y sumar todas las ND:
                          </span>{" "}
                          Al total de ventas del periodo se le resta el total de
                          notas de crédito y devoluciones realizadas y se le
                          suma el total de notas de débito realizadas,
                          independientemente de si las ventas asociadas a estas
                          últimas no corresponden al periodo seleccionado.
                          <br />
                          <br />
                          <span style={{ fontWeight: "bold" }}>
                            Restar las NC y sumar las ND de las ventas del
                            periodo:
                          </span>{" "}
                          Al total de ventas del periodo se le resta únicamente
                          las Notas de Crédito y devoluciones asociadas a esas
                          ventas y se le suman únicamente las Notas de Débito
                          asociadas a esas ventas.
                        </Typography>
                        <br />
                        <Box mt={2}>
                          <Typography variant="body1" align="left">
                            Cálculo de totales
                          </Typography>
                          <br />
                          <Box>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={initalStateTipoCalculo}
                                  onChange={() =>
                                    setInitalStateTipoCalculo(true)
                                  }
                                  color="primary"
                                />
                              }
                              label="Restar todas las NC y sumar todas las ND"
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={!initalStateTipoCalculo}
                                  onChange={() =>
                                    setInitalStateTipoCalculo(false)
                                  }
                                  color="primary"
                                />
                              }
                              label="Restar las NC y sumar las ND de las ventas del periodo"
                            />
                          </Box>
                        </Box>
                      </Box>
                    }
                  />
                )}
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default StatsVentas;
