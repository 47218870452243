import { Box, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React from "react";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { CONFIGURACION_ETIQUETAS } from "../../../../constantes/configuracionEtiquetas";

export default function ConfiguracionDeDetalles({ formik }) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Typography
          className="mb-2"
          style={{ fontSize: "1.5rem", color: "gray" }}
        >
          Configuración de detalles
        </Typography>
        <Typography
          className="mb-2"
          style={{ fontSize: "0.85rem", color: "gray" }}
        >
          Definí qué código se usará para generar el código de barras, qué
          precio se mostrará en la etiqueta y si el precio debe cifrarse o no.
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>Código barras desde</Typography>
        <TextField
          select
          xs={12}
          fullWidth
          size="small"
          variant="outlined"
          name="codigoBarrasDesde"
          style={{ marginTop: 5, maxWidth: "215px" }}
          onChange={formik.handleChange}
          value={formik.values.codigoBarrasDesde}
        >
          {CONFIGURACION_ETIQUETAS.CODIGO_DE_BARRAS_DESDE.map((option) => (
            <MenuItem key={option} value={option}>
              Codigo {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <Typography>Precio a mostrar</Typography>
        <TextField
          select
          xs={12}
          fullWidth
          size="small"
          variant="outlined"
          name="precioAMostrar"
          style={{ marginTop: 5, maxWidth: "215px" }}
          onChange={formik.handleChange}
          value={formik.values.precioAMostrar}
        >
          {CONFIGURACION_ETIQUETAS.PRECIO_A_MOSTRAR.map((option) => (
            <MenuItem key={option} value={option}>
              Precio {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography style={{ marginRight: 4 }}>Cifrar precio</Typography>
          <TooltipMoreInfoMaterial
            color="#3f51b5"
            titleTooltip="Al estar activo el precio será crifrado, reemplazando los numeros por letras de la siguiente manera: E:1, C:2, L:3, U:4, Y:5, I:6, N:7, T:8, A:9, R:0. El punto que separa los decimales será reemplazado por el simbolo /"
          />
        </Box>
        <TextField
          select
          xs={12}
          fullWidth
          size="small"
          variant="outlined"
          name="cifrarPrecio"
          style={{ marginTop: 5, maxWidth: "215px" }}
          onChange={formik.handleChange}
          disabled={!formik.values.precio}
          value={formik.values.cifrarPrecio}
        >
          <MenuItem value={true}>Cifrar</MenuItem>
          <MenuItem value={false}>No cifrar</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
}
