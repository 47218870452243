import { Text, View } from "@react-pdf/renderer";
import React from "react";

export const ETIQUETA_ESTILO = {
  baseFontSize: 6,
  midFontSize: 4,
  maxCharBaseFontSize: 55,
  baseMarginBottom: 2,
};

export const mostrarCodigosArticulo = (configuracionEtiqueta, articulo) => {
  const codigo =
    configuracionEtiqueta.codigoAuxiliar && articulo.codAuxiliar
      ? articulo.codAuxiliar
      : configuracionEtiqueta.codigoProveedor && articulo.codProveedor
        ? articulo.codProveedor
        : "";

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 4,
      }}
    >
      <Text
        style={{
          fontSize: ETIQUETA_ESTILO.baseFontSize,
          fontWeight: "bold",
        }}
      >
        {codigo}
      </Text>
    </View>
  );
};
