import { Button, Grid, MenuItem, TextField } from "@material-ui/core";
import React from "react";
import { PLANES } from "../../../../../constantes/configuracion/configuracionBoxer";
import putConfiguracionGeneral from "../../utils";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";

export default function ConfiguracionBoxer({ config, getConfig }) {
  const [form, setForm] = React.useState({
    values: {
      plan: config.plan,
      legajo: config.legajo,
      nombre_negocio: config.nombre_negocio,
    },
    errors: {
      plan: "",
      legajo: "",
      nombre_negocio: "",
    },
  });

  function handleFormChange(e) {
    let value = e.target.value;
    setForm({
      ...form,
      values: {
        ...form.values,
        [e.target.name]: value,
      },
      errors: {
        ...form.errors,
        [e.target.name]: "",
      },
    });
  }

  async function handleSubmit() {
    try {
      await putConfiguracionGeneral({
        ...config,
        plan: form.values.plan,
        legajo: form.values.legajo,
        nombre_negocio: form.values.nombre_negocio,
      })
        .then((res) => {
          getConfig(res);
          // successNotification("Cambios guardados correctamente.");
        })
        .catch(() => {
          errorNotification(
            "Error al guardar los cambios de la configuración.",
          );
        });
    } catch (error) {
      console.error(error);
      errorNotification("Error al guardar los cambios de la configuración.");
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          label="Plan"
          size="small"
          name="plan"
          select
          fullWidth
          value={form.values.plan}
          onChange={handleFormChange}
          inputProps={{ maxLength: 20 }}
          variant="outlined"
          helperText={"Seleccionar el plan"}
        >
          {PLANES.map((plan) => (
            <MenuItem
              key={plan.toLocaleLowerCase()}
              value={plan.toLocaleLowerCase()}
            >
              {plan}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Legajo"
          name="legajo"
          fullWidth
          size="small"
          value={form.values.legajo}
          onChange={handleFormChange}
          variant="outlined"
          helperText={
            "Escribir el legajo de la siguiente manera: bXXXX Ejemplo: b0001, b0002, b0003"
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Nombre del negocio"
          name="nombre_negocio"
          fullWidth
          size="small"
          value={form.values.nombre_negocio}
          onChange={handleFormChange}
          variant="outlined"
          helperText={"Escribir el nombre del negocio"}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Guardar cambios
        </Button>
      </Grid>
    </Grid>
  );
}
