import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { parseCurrency } from "../../../../../utils/parsers";
import RegimenTransparenciaFiscalA4 from "../../Components/RegimenTransparenciaFiscalA4";

export default function TotalesFA4({ notaDebito }) {
  const tipoFactura = notaDebito.receipt.receipt_type.at(-1);

  return (
    <View style={styles.footerTotal}>
      <View
        style={{
          marginRight: 20,
          marginLeft: 20,
          color: "#1B71BA",
          fontWeight: "bolder",
        }}
      >
        <View style={styles.containerColumn} />
        <View style={styles.containerColumn}>
          <View style={styles.containerRowTableEnd}>
            <View style={(styles.containerColumn, { width: 200 })}>
              <Text style={styles.textTotalesFactura}>
                {["A", "M"].includes(tipoFactura) ? "SUBTOTAL" : "TOTAL"}
              </Text>

              <Text style={styles.textTotalesFactura}>
                {["A", "M"].includes(tipoFactura) && `IVA ${"21%"}`}
              </Text>

              {["A", "M"].includes(tipoFactura) &&
                notaDebito.receipt.taxes.map((tax, index) => (
                  <Text key={index} style={styles.textTotalesFactura}>
                    {`${tax.description.toUpperCase()} - ${Number(
                      tax.aliquot,
                    )}%`}
                  </Text>
                ))}

              {["A", "M"].includes(tipoFactura) && (
                <Text style={styles.textTotalesFactura}>TOTAL</Text>
              )}
            </View>
            <View style={(styles.containerColumn, { width: 70 })}>
              <Text style={styles.textTotalesFactura}>
                {["A", "M"].includes(tipoFactura)
                  ? `${parseCurrency(Number(notaDebito.receipt.net_taxed))}`
                  : `${parseCurrency(Number(notaDebito.receipt.total_amount))}`}
              </Text>

              <Text style={styles.textTotalesFactura}>
                {["A", "M"].includes(tipoFactura) &&
                  `${parseCurrency(Number(notaDebito.receipt.total_vat))}`}
              </Text>

              {["A", "M"].includes(tipoFactura) &&
                notaDebito.receipt.taxes.map((tax, index) => (
                  <Text key={index} style={styles.textTotalesFactura}>
                    {parseCurrency(tax.amount)}
                  </Text>
                ))}

              {["A", "M"].includes(tipoFactura) && (
                <Text style={styles.textTotalesFactura}>
                  {`${parseCurrency(Number(notaDebito.receipt.total_amount))}`}
                </Text>
              )}
            </View>
          </View>
        </View>
      </View>
      {["B"].includes(tipoFactura) && (
        <View style={{ marginLeft: 20 }}>
          <RegimenTransparenciaFiscalA4
            ivaAmount={notaDebito.receipt.total_vat}
          />
        </View>
      )}
    </View>
  );
}
