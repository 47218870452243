import React from "react";
import { Grid, Card, CardActions, Box, Typography } from "@material-ui/core";
import Spinner from "../../../../components/Spinner";
import ListTwoCol from "../../../../components/ait-reusable/List/ListTwoCol";

const Stats = ({ totales = [], isLoading, customFormatSecondColumn }) => {
  let formatSecondColumn = (value) => {
    if (!value) {
      return "$0,00";
    }

    const number = Number(value);
    if (isNaN(number)) {
      return "Invalid number";
    }

    const numberFormatted = number.toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return numberFormatted;
  };

  let total = Array.isArray(totales)
    ? totales.reduce((acc, t) => t.total + acc, 0)
    : 0;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Card>
            <ListTwoCol
              isLoading={isLoading}
              firstHead={"Conceptos"}
              lastHead={"Totales"}
              info={totales}
              formatSecondColumn={
                customFormatSecondColumn
                  ? customFormatSecondColumn
                  : formatSecondColumn
              }
            />
            <CardActions>
              <Grid
                container
                spacing={2}
                className="justify-content-center pr-0 m-0"
                style={{ width: "100%", alignItems: "center" }}
              >
                {isLoading ? (
                  <div className={"mt-2 mb-2"}>
                    <Spinner color="#66b3ff" type="spin" />
                  </div>
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h5">
                      Total ${" "}
                      {total ? Number(total).toLocaleString("es-AR") : 0}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Stats;
