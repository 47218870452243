import { Document, Page, View } from "@react-pdf/renderer";
import React from "react";
import { LabelComponent } from "../SingleEtiqueta/SizeLabel";
import { styles } from "../SingleEtiqueta/utils";

export default function EtiquetasCarta({
  articulo,
  sucursal,
  ancho,
  alto,
  filasArray,
  columnasArray,
  configGeneral,
  configuracionEtiqueta,
}) {
  const Component = LabelComponent(ancho + "x" + alto);

  return (
    //LETTER = CARTA => 612px/220mm(ancho) x 792px/280mm(alto)
    <Document title={`Etiqueta-${ancho}x${alto}-CARTA`}>
      <Page size="LETTER" style={styles.pageLetter}>
        <View style={styles.contenedor}>
          {filasArray.array.map((_x, iF) => (
            <View style={styles.fila} key={iF}>
              {columnasArray.array.map((_x, iC) => (
                <View style={{ padding: 5 }} key={iC}>
                  <Component
                    sucursal={sucursal}
                    articulo={articulo}
                    configGeneral={configGeneral}
                    configuracionEtiqueta={configuracionEtiqueta}
                  />
                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}
