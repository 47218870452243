import React, { useState } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import ModalReutilizable from "../../../components/ait-reusable/Modal/index";
import request from "../../../requests/request";
import { generateNotaCredito } from "../../../requests/urls";
import {
  successNotification,
  errorNotification,
} from "../../../components/Notifications";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { AlertReusable } from "../../../components/ait-reusable/Alerts/AlertReusable";
import useValidacionMensajeErrorAFIP from "../../../customHooks/useValidacionMensajeErrorAFIP";

const MensajeModalFCEM = () => {
  return (
    <>
      <span>
        <span style={{ fontWeight: "bold" }}>S:</span> El cliente{" "}
        <span style={{ fontWeight: "bold" }}>RECHAZÓ</span> el comprobante
        facturado.
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>N:</span> El cliente{" "}
        <span style={{ fontWeight: "bold" }}>ACEPTO</span> el comprobante
        facturado.
      </span>
    </>
  );
};

export default function ModalGenerarNC({
  open,
  handleClose,
  nota,
  setDataErrorCAE,
}) {
  const [disabled, setDisabled] = useState(false);
  const [anulacionFCEM, setAnulacionFCEM] = useState("N");
  const { getDataInfoErrorAnulacion } = useValidacionMensajeErrorAFIP();

  const valoresFCEMA = [
    {
      id: 0,
      value: "S",
    },
    {
      id: 1,
      value: "N",
    },
  ];

  const validateCAE = async () => {
    setDisabled(true);
    try {
      const response = await request({
        method: "PUT",
        url: generateNotaCredito,
        data: {
          id_nota_credito: nota.idNotaCredito,
          anulacion_fcem:
            nota.venta && nota.venta.tipo_comprobante.nombre === "FCEM A"
              ? anulacionFCEM
              : "",
        },
      });
      handleResponseGenerate(response);
    } catch (error) {
      let msgError = JSON.stringify(error.response.data);
      if (
        msgError.includes("Actualización de Nota de crédito de Boxer fallida")
      ) {
        errorNotification(
          "Nota de crédito generada con éxito, pero no se pudo actualizar la misma en Boxer. Comuníquese con soporte.",
          6000,
        );
        handleClose();
      } else if (msgError.includes("Error al validar la nota de crédito")) {
        errorNotification(
          "Nota de crédito generada con éxito, pero ocurrió un error al validar la misma con ARCA y esta pendiente de CAE.",
          6000,
        );
        let data = getDataInfoErrorAnulacion(msgError);

        setDataErrorCAE({
          open: true,
          idComprobante: data.idNC,
          isNotaCredito: true,
          msgErrorValidacionAFIP: data.mensajeError,
        });

        handleClose();
      } else {
        errorNotification(
          "Ocurrió un error al generar la nota de crédito, intente más tarde.",
        );
        handleClose();
      }
      setDisabled(false);
    }
  };

  const handleResponseGenerate = (res) => {
    if (res.data.status === 201) {
      successNotification(
        "Nota de crédito generada y validada en AFIP con éxito.",
      );
      handleClose();
    } else {
      errorNotification(
        "Ocurrió un error al generar la nota de crédito, intente más tarde.",
      );
      setDisabled(false);
    }
  };

  return (
    <ModalReutilizable
      open={open}
      handleClose={handleClose}
      content={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h5
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  textAlign: "center",
                }}
              >
                {`¿Está seguro que de generar una nota de crédito para esta devolución?`}
              </h5>
            </Grid>
          </Grid>
          {nota.venta && nota.venta.tipo_comprobante.nombre === "FCEM A" && (
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <AlertReusable
                  severity={"info"}
                  text={
                    "Esta nota de crédito está asociada a una Factura FCEM A por lo que debe seleccionar un valor de anulación."
                  }
                />
              </Grid>
              <Grid item xs={10} md={5} style={{ paddingTop: "1em" }}>
                <TextField
                  select
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={"Valor de anulación"}
                  value={anulacionFCEM || ""}
                  onChange={(e) => {
                    setAnulacionFCEM(e.target.value);
                  }}
                >
                  {valoresFCEMA.map((x, i) => (
                    <MenuItem value={x.value} key={x.id}>
                      {x.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={1} style={{ paddingTop: "1em" }}>
                <TooltipMoreInfoMaterial
                  position={"right"}
                  titleTooltip={<MensajeModalFCEM />}
                  color="rgb(8, 150, 255)"
                />
              </Grid>
            </Grid>
          )}
        </>
      }
      title={"Generar Nota de Crédito"}
      messageAceptar={"GENERAR"}
      messageCancelar={"CANCELAR"}
      handleSubmit={validateCAE}
      openBackdrop={disabled}
    />
  );
}
