import { Collapse, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { firtDayCurrenMonth, lastDayCurrenMonth } from "../../../utils/dates";
import { columnsTotalesTipoMov, formatStats, itemsMockTipoMov } from "./utils";
import TablaPrincipal from "./components/TablaPrincipal";
import Stats from "./Stats";
import ButtonFilter from "../../../components/ait-reusable/Button/ButtonFilter";
import Filtros from "./components/Filtros";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import request from "../../../requests/request";
import { useModulosContext } from "../../../context/ModulosContext";
import { MODULOS } from "../../../constantes/modulos";

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: "#f0f0f0",
  },
  paddingBetweenCards: {
    paddingTop: 10,
  },
}));

export const MovimientosMedios = () => {
  const { activo: moduloVentasActivo } = useModulosContext().tieneModuloActivo(
    MODULOS.MOVIMIENTOS_BILLETERA,
  );

  const classes = useStyles();
  const [period, setPeriod] = useState("thisMonth");
  const [collapseOpen, setCollapseOpen] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [tipoMovSelected, setTipoMovSelected] = useState("Todos");
  const [dates, setDates] = useState({
    fromDate: firtDayCurrenMonth(),
    toDate: lastDayCurrenMonth(),
  });
  const [totalesTipoMovimiento, setTotalesTipoMovimiento] = useState([]);
  const [totalesMedioPago, setTotalesMedioPago] = useState([]);
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const history = useHistory();

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: "/api/venta/informe/movimientos/",
        params: {
          desde: dates.fromDate,
          hasta: dates.toDate,
          empleado: useEmpleado.empleado ? useEmpleado.empleado.idEmpleado : "",
          tipo_movimiento: tipoMovSelected === "Todos" ? "" : tipoMovSelected,
        },
      });

      setTotalesTipoMovimiento(response.data[0].tabla_movimientos);
      setTotalesMedioPago(response.data[1].tabla_medios_pagos);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleSearch();
  }, []);

  return (
    <div style={{ paddingRight: 10, paddingLeft: 10 }}>
      <Grid container>
        <Grid container alignContent="center" alignItems="center">
          <Grid item xs={12} style={{ paddingBottom: 10 }}>
            <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} />
          </Grid>
          <Grid item xs={12} pb={1}>
            <Typography variant="h6">{`Resumen desde ${dates.fromDate} al ${dates.toDate}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={collapseOpen}>
              <Filtros
                period={period}
                setPeriod={setPeriod}
                dates={dates}
                setDates={setDates}
                handleSearch={handleSearch}
                dataEmpleado={{ useEmpleado, useQueryEmpleado }}
                tipoMovSelected={tipoMovSelected}
                setTipoMovSelected={setTipoMovSelected}
              />
            </Collapse>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.paddingBetweenCards}>
          <Grid item xs={12} md={8}>
            <TablaPrincipal
              columns={columnsTotalesTipoMov}
              items={itemsMockTipoMov(
                totalesTipoMovimiento,
                history,
                moduloVentasActivo,
              )}
              title={"Totales por tipo de movimiento"}
              loading={isLoading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Stats
              totales={formatStats(totalesMedioPago)}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
