import moment from "moment";
import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

export const formatDataDuplicados = (filters, data, useStock) => {
  let newData = [];

  data.forEach((d) => {
    newData.push({
      correcto: checkArtCorrecto(d, useStock),
      cod: filters.codigo === 0 ? d.codOriginal : d.codProveedor,
      auxiliar: d.codAuxiliar ? d.codAuxiliar : "---",
      fecha: moment(d.fechaUltimaModificacion).format("DD-MM-YYYY HH:mm"),
      descripcion: d.descripcionProveedor,
      precio: "$" + d.precioCosto,
      stock: getStock(d),
      eliminar: checkArtsEliminados(d, useStock),
    });
  });

  return newData;
};

const getStock = (d) => {
  return (
    <span
      style={{
        color: d.repuesto_sucursal
          ? d.repuesto_sucursal.stockDisponible > 0
            ? "green"
            : "red"
          : "black",
        fontWeight: "bold",
      }}
    >
      {d.repuesto_sucursal ? d.repuesto_sucursal.stockDisponible : "---"}
    </span>
  );
};

const checkArtCorrecto = (d, useStock) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={useStock.isArtCorrecto(d)}
          onChange={() => useStock.handleChangeCorrecto(d)}
          color="primary"
        />
      }
    />
  );
};

const checkArtsEliminados = (d, useStock) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={useStock.isArtEliminado(d)}
          disabled={useStock.isArtCorrecto(d)}
          onChange={() => useStock.handleChangeEliminados(d)}
        />
      }
    />
  );
};
