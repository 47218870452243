import React from "react";
import { Grid, TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

export default function SearchCompra({
  inputValueCompra,
  setInputValueCompra,
}) {
  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Buscar por número de factura, número de nota de crédito o punto de venta"
          placeholder="Ejemplo: 3431-84271634"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={inputValueCompra}
          onChange={(e) => setInputValueCompra(e.target.value)}
        ></TextField>
      </Grid>
    </>
  );
}
