import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleTicket";
import HeaderTicket from "./Components/HeaderTicket";
import DataNCTicket from "./Components/DataNCTicket";
import SubHeaderNCTicket from "./Components/SubHeaderNCTicket";
import ArticulosTicketF from "./Components/ArticulosTicketF";
import TotalesTFactura from "./Components/TotalesTFactura";
import ValidationAfip from "../DeVentas/Components/ValidationAfip";
import { parseCurrency } from "../../../../utils/parsers";
import RegimenTransparenciaFiscalTicket from "../Components/RegimenTransparenciaFiscalTicket";

export default function TicketFactura({ data, remito, config }) {
  return (
    <Document title={remito ? "Remito" : "Factura Venta"}>
      <Page style={styles.page} size={{ width: 226.77, minHeight: 400 }}>
        <View>
          <HeaderTicket
            logoEmpresa={data.logo}
            data={data}
            config={{ ocultar_datos_ci: false }}
            remito={remito}
          />
          <DataNCTicket
            data={data.nota_credito}
            remito={remito}
            allData={data}
          />
          <SubHeaderNCTicket
            data={data}
            observacion={data.nota_credito.observacion}
          />
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.textbold}>{`Descripción(%IVA)`}</Text>
              <Text style={styles.textbold}>{`${
                !data.nota_credito.por_montos ? "Cant/" : ""
              }Precio Unit.`}</Text>
            </View>
            <View style={styles.containerColumn2}>
              <Text style={styles.textbold}>IMPORTE</Text>
            </View>
          </View>

          <Text
            style={{
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            --------------------------------
          </Text>

          {!data.nota_credito.por_montos ? (
            <ArticulosTicketF
              data={data.nota_credito.detalle}
              factura={data.factura}
              remito={remito}
              fullData={data}
              config={config}
            />
          ) : (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 10,
              }}
            >
              <View style={{ flexDirection: "column" }}>
                <Text style={styles.textDescriptionItem}>
                  {"Nota de crédito por montos"}
                </Text>
                {!remito ? (
                  <Text style={styles.text}>
                    {data.factura.idTipoFactura.nombre === "A" ||
                    data.factura.idTipoFactura.nombre === "M"
                      ? `${parseCurrency(
                          Number(data.nota_credito.monto * (1 / 1.21)),
                        )}`
                      : `${parseCurrency(Number(data.nota_credito.monto))}`}
                  </Text>
                ) : (
                  <Text style={styles.text}>---</Text>
                )}
                {!remito ? (
                  (data.factura.idTipoFactura.nombre === "A" ||
                    data.factura.idTipoFactura.nombre === "M") && (
                    <Text style={styles.textDescription}>
                      {`IVA:${"21%"} - ${parseCurrency(
                        Number(data.nota_credito.monto) -
                          Number(data.nota_credito.monto * (1 / 1.21)),
                      )}`}
                    </Text>
                  )
                ) : (
                  <Text style={styles.textDescription}>---</Text>
                )}
              </View>
              <View style={styles.containerColumn}>
                {!remito ? (
                  <Text style={styles.text}>
                    {data.factura.idTipoFactura.nombre === "A" ||
                    data.factura.idTipoFactura.nombre === "M"
                      ? `${parseCurrency(
                          Number(data.nota_credito.monto * (1 / 1.21)),
                        )}`
                      : `${parseCurrency(Number(data.nota_credito.monto))}`}
                  </Text>
                ) : (
                  <Text style={styles.text}>---</Text>
                )}
              </View>
            </View>
          )}

          <Text style={{ marginLeft: 10, marginRight: 10 }}>
            --------------------------------
          </Text>
          {!remito && (
            <TotalesTFactura
              vat={data.nota_credito.datos_afip.iva_resumen}
              nota={data.nota_credito}
              factura={data.factura}
            />
          )}
          {!remito && data.factura.idTipoFactura.nombre === "B" && (
            <RegimenTransparenciaFiscalTicket
              montoIva={data.nota_credito.datos_afip.iva_resumen.amount}
            />
          )}

          {data.codigo_QR && (
            <ValidationAfip
              validation={data.nota_credito.datos_afip.validation}
              qr={data.codigo_QR}
            />
          )}
        </View>
      </Page>
    </Document>
  );
}
