import React, { useState, useEffect } from "react";
import { Grid, FormControl, TextField, Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TablePedidos from "./TablePedidos";
import request from "../../../requests/request";
import { errorNotification } from "../../../components/Notifications";
import useSearchProveedores from "../../../customHooks/useSearchProveedores";
import axios from "axios";
import Filtros from "./Filtros";
import {
  getEstados,
  getPedidos,
  getExcelPedidos,
  readTotalesPedidos,
} from "../../../requests/urls";
import { getFormatedNow } from "../../../utils/dates";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import ModalDetallesPedido from "./Modales/ModalDetallePedido";
import { useDispatch } from "react-redux";
import { setearDatosDePedido } from "../../../Redux/Actions/pedidoActions";
import OverviewTotales from "./Componentes/OverviewTotales";
import { cargarDatosDesdeInformePedidos } from "../../../Redux/Actions/compraActions";
import { cargarCompraRapidaDesdeInformePedidos } from "../../../Redux/Actions/compraRapidaActions";
import { CambiarEstadoPedido } from "./Modales/CambiarEstadoPedido";
import ModalHistorialPedido from "./Modales/ModalHistorialPedido";

const PedidosInforme = () => {
  const [dates, setDates] = useState({
    fromDate: getFormatedNow(),
    toDate: getFormatedNow(),
  });
  const [modalCambiarEstado, setModalCambioEstado] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const [period, setPeriod] = useState("today");
  const [estados, setEstados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    estado: null,
    proveedorSelected: null,
    alias: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [totalesPedidos, setTotalesPedidos] = useState(null);
  const [openModalDetalles, setOpenModalDetalles] = useState(false);
  const [pedidoSelected, setPedidoSelected] = useState(null);
  const [loadingOverview, setLoadingOverview] = useState(false);
  const [modalHistorial, setModalHistorial] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { useProveedor, useQuery } = useSearchProveedores();

  const handleGetPedidos = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getPedidos,
        params: {
          desde: dates.fromDate,
          hasta: dates.toDate,
          estado: filters.estado,
          proveedor: useProveedor.proveedor
            ? useProveedor.proveedor.idProveedor
            : "",
          alias: filters.alias,
          page: pagination.page,
        },
      });
      response.status === 200
        ? setPedidos(response.data.data.items)
        : setPedidos([]);
      setPagination({
        ...pagination,
        num_items: response.data.data.num_items,
        next_page: response.data.data.next_page,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      clearState();
    }
  };

  const handleGetTotales = async () => {
    setLoadingOverview(true);
    try {
      const response = await request({
        method: "GET",
        url: readTotalesPedidos,
        params: {
          desde: dates.fromDate,
          hasta: dates.toDate,
          proveedor: useProveedor.proveedor
            ? useProveedor.proveedor.idProveedor
            : "",
          alias: filters.alias,
        },
      });
      setTotalesPedidos(response.data);
      setLoadingOverview(false);
    } catch (error) {
      console.error(error);
      setTotalesPedidos(null);
      setLoadingOverview(false);
    }
  };

  const handleGetEstados = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getEstados,
      });
      response.status === 200 ? setEstados(response.data.data) : setEstados([]);
    } catch (error) {
      console.error(error);
    }
  };

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setPedidos([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  const handleGetExcelPedido = (pedido) => {
    let url = getExcelPedidos(pedido.id);
    let params = { idPedido: pedido.id };
    let fileName = `Excel_pedido_${pedido.id}.xlsx`;
    axios({
      url: url,
      params: params,
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Disposition": `attachment; filename=${fileName}`,
        "Content-Type": "text/plain; charset=UTF-8",
      },
      responseType: "arraybuffer", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        errorNotification("Excel no disponible");
      });
  };

  const handleOpenModalDetalle = (pedido) => {
    setOpenModalDetalles(true);
    setPedidoSelected(pedido);
  };

  const handleCloseModalDetalle = () => {
    setOpenModalDetalles(false);
    setPedidoSelected(null);
  };

  const handleModalCambiarEstado = (pedido) => {
    setPedidoSelected(pedido);
    setModalCambioEstado(true);
  };

  const handleEditarPedido = (pedido) => {
    dispatch(setearDatosDePedido(pedido, true));
    history.push(`/pedidos/editar/${pedido.id}/`);
  };

  const handleRecrearPedido = (pedido) => {
    dispatch(setearDatosDePedido(pedido, true));
    history.push("/proveedores/pedidos/");
  };

  const handleCargarCompraNormal = (pedido) => {
    dispatch(cargarDatosDesdeInformePedidos(pedido));
    history.push("/compras/normal/");
  };

  const handleCargarCompraRapida = (pedido) => {
    dispatch(cargarCompraRapidaDesdeInformePedidos(pedido));
    history.push("/compras/rapida/");
  };

  const handleCrearAuditoria = (pedido) => {
    history.push(`/auditoria/crear/${pedido.id}`);
  };

  const handleModalHistorial = (pedido) => {
    setPedidoSelected(pedido);
    setModalHistorial(true);
  };

  const handleGet = () => {
    handleGetPedidos();
    handleGetTotales();
  };

  const handleSubmit = () => {
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    } else {
      handleGet();
    }
  };

  useEffect(() => {
    handleGetEstados();
    handleGetTotales();
  }, []);

  useEffect(() => {
    handleGetPedidos();
  }, [pagination.page]);

  return (
    <>
      <Container fluid className="main-content-container px-4">
        <PageHeader title="MIS PEDIDOS" history={history} />
        <Grid container spacing={2} className="mb-2">
          <Grid item sm={12} xs={12}>
            <Filtros
              dates={dates}
              period={period}
              setDates={setDates}
              setPeriod={setPeriod}
              clear={clearState}
              handleSearch={handleSubmit}
              filters={filters}
              estados={estados}
              setFilters={setFilters}
              handleGetPedidos={handleGet}
            >
              <Grid item xs={12} sm={12} md={2} style={{ paddingBottom: 15 }}>
                <FormControl fullWidth>
                  <Autocomplete
                    loading={loading}
                    autoHighlight
                    value={useProveedor.proveedor}
                    options={useProveedor.proveedores}
                    filterOptions={(options, state) => options}
                    getOptionLabel={(proveedor) => proveedor.razonSocial}
                    inputValue={useQuery.query || ""}
                    onInputChange={(event, value) => {
                      useQuery.setQuery(value);
                    }}
                    onChange={(event, value) =>
                      useProveedor.setProveedor(value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Proveedor"
                        variant="outlined"
                      />
                    )}
                    name="buscador_proveedores"
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  value={filters.alias}
                  variant="outlined"
                  label="Alias"
                  fullWidth
                  size="small"
                  name="alias"
                  onChange={(e) =>
                    setFilters({ ...filters, alias: e.target.value })
                  }
                />
              </Grid>
            </Filtros>
          </Grid>
        </Grid>
        <OverviewTotales
          totalesPedidos={totalesPedidos}
          loadingOverview={loadingOverview}
        />
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <TablePedidos
              pedidos={pedidos}
              pagination={pagination}
              setPagination={setPagination}
              handleGetExcelPedido={handleGetExcelPedido}
              handleOpenModalDetalle={handleOpenModalDetalle}
              handleEditarPedido={handleEditarPedido}
              handleRecrearPedido={handleRecrearPedido}
              handleCargarCompraNormal={handleCargarCompraNormal}
              handleCargarCompraRapida={handleCargarCompraRapida}
              loading={loading}
              handleModalCambiarEstado={handleModalCambiarEstado}
              handleCrearAuditoria={handleCrearAuditoria}
              handleModalHistorial={handleModalHistorial}
            />
          </Grid>
        </Grid>
      </Container>
      {openModalDetalles && (
        <ModalDetallesPedido
          open={openModalDetalles}
          onClose={handleCloseModalDetalle}
          pedido={pedidoSelected}
        />
      )}
      {modalCambiarEstado && (
        <CambiarEstadoPedido
          open={modalCambiarEstado}
          close={() => setModalCambioEstado(false)}
          pedido={pedidoSelected}
          handleGetPedidos={handleGetPedidos}
        />
      )}
      {modalHistorial && (
        <ModalHistorialPedido
          idPedido={pedidoSelected.id}
          pedido={pedidoSelected}
          open={modalHistorial}
          onClose={() => setModalHistorial(false)}
        />
      )}
    </>
  );
};
export default PedidosInforme;
