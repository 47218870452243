import { Text, View } from "@react-pdf/renderer";
import React from "react";

export const ETIQUETA_ESTILO = {
  baseFontSize: 8,
  maxCharBaseFontSize: 85,
  baseMarginBottom: 2,
};

export const mostrarCodigosArticulo = (configuracionEtiqueta, articulo) => {
  const codigo =
    configuracionEtiqueta.codigoProveedor && articulo.codProveedor
      ? articulo.codProveedor
      : "";

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: ETIQUETA_ESTILO.baseMarginBottom,
      }}
    >
      <Text
        style={{
          fontSize: ETIQUETA_ESTILO.baseFontSize,
          fontWeight: "bold",
        }}
      >
        {codigo}
      </Text>
    </View>
  );
};
