import { Document, Page } from "@react-pdf/renderer";
import React from "react";
import { LabelComponent } from "../SingleEtiqueta/SizeLabel";
import { getPageSize, styles } from "../SingleEtiqueta/utils";

export default function EtiquetasTiquetBulk({
  alto,
  ancho,
  sucursal,
  articulos,
  configGeneral,
  configuracionEtiqueta,
}) {
  const Component = LabelComponent(ancho + "x" + alto);

  const generarEtiquetas = () =>
    articulos.flatMap((articulo, index) => {
      const cantidadEtiquetas = articulo.cantidadEtiquetas || 1;
      return Array.from({ length: cantidadEtiquetas }, (_, i) => (
        <Page
          key={`page-${index}-${i}`}
          size={getPageSize(ancho, alto)}
          style={styles.page}
        >
          <Component
            alto={alto}
            ancho={ancho}
            sucursal={sucursal}
            articulo={articulo}
            configGeneral={configGeneral}
            configuracionEtiqueta={configuracionEtiqueta}
          />
        </Page>
      ));
    });

  return (
    <Document title={`EtiquetaTicket ${ancho}x${alto}`}>
      {generarEtiquetas()}
    </Document>
  );
}
