import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { parseCurrency } from "../../../../../utils/parsers";

export default function TotalesTicket({ data }) {
  const totalSaldado = data.ventas_saldadas.reduce(
    (acc, venta) => acc + Number(venta.monto_saldado),
    0,
  );
  const totalSobrante = Number((data.importe_total - totalSaldado).toFixed(2));

  return (
    <View style={styles.containerRow}>
      <View style={styles.containerColumn}>
        <Text style={styles.textTotalesFactura}>{"IMPORTE SALDADO"}</Text>
        {totalSobrante > 0 && (
          <Text style={styles.textTotalesFactura}>
            {"SOBRANTE A BILLETERA"}
          </Text>
        )}
        <Text style={styles.textTotalesFactura}>{`IMPORTE TOTAL`}</Text>
      </View>
      <View style={styles.containerColumn2}>
        <Text style={styles.textTotalesFactura}>
          {parseCurrency(Number(totalSaldado))}
        </Text>
        {totalSobrante > 0 && (
          <Text style={styles.textTotalesFactura}>
            {parseCurrency(Number(totalSobrante))}
          </Text>
        )}
        <Text style={styles.textTotalesFactura}>
          {parseCurrency(Number(data.importe_total))}
        </Text>
      </View>
    </View>
  );
}
