import React, { useState } from "react";
import ModalReutilizable from "../Modal";
import { Grid, Typography } from "@material-ui/core";
import request from "../../../requests/request";
import { validateNotasCredito } from "../../../requests/urls";
import { errorNotification, successNotification } from "../../Notifications";
import TitleModal from "./TitleModal";
import ReusableTable from "../Report/ReusableTable";
import { AlertReusable } from "../Alerts/AlertReusable";

export default function ModalValidacionNotasCredito({
  open,
  handleClose,
  notasCredito,
  idFactura,
}) {
  const [disabled, setDisabled] = useState(false);

  const formatDevoluciones = (devoluciones) => {
    const data = [];
    devoluciones.forEach((devolucion) => {
      data.push({
        idDevolucion: devolucion.idDevolucion,
        mensajeError: devolucion.mensajeError,
      });
    });
    return data;
  };

  const getContentNC = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AlertReusable
              severity="error"
              text={`Se generó la factura Nº ${idFactura} correctamente pero ocurrió un error al validar las siguientes notas de crédito en ARCA`}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: 8 }}>
          <Grid item xs={12}>
            <ReusableTable
              columns={["ID devolución", "Error"]}
              items={formatDevoluciones(notasCredito)}
              loading={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: 8 }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ textAlign: "center", fontSize: 17 }}
            >
              {`¿Desea reintentar la validación de las notas de crédito ahora? Si no lo desea en este momento, puede hacerlo luego desde el informe de notas de crédito.`}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const getDataNotasCredito = (notas) => {
    const data = [];
    notas.forEach((nota) => {
      data.push({ nota: nota.idDevolucion, anulacion_fcem: "" });
    });
    return data;
  };

  const validateCAE = async () => {
    setDisabled(true);
    try {
      const response = await request({
        method: "PUT",
        url: validateNotasCredito,
        data: {
          notas_credito: getDataNotasCredito(notasCredito),
        },
        params: {
          paginated: 0,
        },
      });
      handleResponseValidate(response);
    } catch (error) {
      errorNotification(
        "El número de CAE no se ha generado, intente más tarde.",
      );
      setDisabled(false);
    }
  };

  const handleResponseValidate = (res) => {
    if (res.data.status === 201) {
      successNotification("El número de CAE se ha generado con éxito.");
      handleClose();
    } else {
      errorNotification(
        "El número de CAE no se ha generado, intente más tarde.",
      );
      setDisabled(false);
    }
  };
  return (
    <ModalReutilizable
      open={open}
      handleClose={handleClose}
      content={getContentNC()}
      title={<TitleModal title="Validación de CAE de notas de crédito" />}
      handleSubmit={validateCAE}
      openBackdrop={disabled}
      messageAceptar={"Generar CAE"}
      messageCancelar={"Cancelar"}
    />
  );
}
