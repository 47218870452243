const size = {
  A4: {
    "44x55": { cantAncho: 4, cantAlto: 4 },
    "50x25": { cantAncho: 3, cantAlto: 9 },
    "55x44": { cantAncho: 3, cantAlto: 5 },
    "80x25": { cantAncho: 3, cantAlto: 9 },
    "80x50": { cantAncho: 3, cantAlto: 3 },
    "100x50": { cantAncho: 1, cantAlto: 3 },
    "100x75": { cantAncho: 1, cantAlto: 3 },
  },
  Carta: {
    "44x55": { cantAncho: 4, cantAlto: 4 },
    "50x25": { cantAncho: 3, cantAlto: 9 },
    "55x44": { cantAncho: 3, cantAlto: 5 },
    "80x25": { cantAncho: 3, cantAlto: 9 },
    "80x50": { cantAncho: 3, cantAlto: 3 },
    "100x50": { cantAncho: 1, cantAlto: 3 },
    "100x75": { cantAncho: 1, cantAlto: 3 },
  },
};

export const getSizeEtiqueta = (alto, ancho, formatoHoja) => {
  if (formatoHoja === "Ticket") {
    return { cantAncho: 1, cantAlto: 1 };
  }
  const set = size[formatoHoja][`${ancho}x${alto}`];

  return { cantAncho: set.cantAncho, cantAlto: set.cantAlto };
};
