import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { parseCurrency } from "../../../../../utils/parsers";
import TotalesCtaCte from "./TotalesCtaCte";
import TablaPlazosCtaCte from "./TablaPlazosCtaCte";
import { getPriceNetoGravado, textAbbreviation } from "./utils";
import RegimenTransparenciaFiscalA4 from "../../Components/RegimenTransparenciaFiscalA4";

function getSubtotal(vat) {
  return vat.reduce((total, iva) => Number(iva.base_amount) + total, 0);
}
export default function TotalesFacturaA4({
  tipo,
  factura,
  vat,
  receipt,
  dcto,
  recargo,
  plazos,
  montoTotal,
  data,
  config,
}) {
  const { nombre } = factura.idTipoFactura;

  const amountSubtotal =
    nombre === "A" || nombre === "M"
      ? Number(getSubtotal(vat) + Number(dcto) - Number(recargo))
      : Number(receipt.total_amount) + Number(dcto) - Number(recargo);

  const porcDescuento = factura.is_agrupada
    ? ""
    : data.porcentaje_descuento
      ? "(" + Number(data.porcentaje_descuento) * 100 + "%)"
      : "";

  return (
    <View style={tipo === "ventaA4" ? styles.footerTotal : { fontSize: 10 }}>
      <View
        style={{
          marginRight: 20,
          marginLeft: tipo === "ventaA4" ? 10 : 0,
          marginBottom: 20,
          marginTop: 10,
          color: "#1B71BA",
          fontWeight: "bolder",
        }}
      >
        {/* <View style={styles.containerColumn} /> */}
        <View style={styles.containerColumn}>
          <View style={styles.containerRowTable}>
            <View style={styles.containerRowTableEnd}>
              <View style={(styles.containerColumn, { width: 275 })}>
                {plazos.length > 0 &&
                  (tipo === "ventaA4" ? (
                    <TotalesCtaCte
                      plazos={plazos}
                      montoTotal={montoTotal}
                      fechaVenta={factura.fechaHoraFactura}
                    />
                  ) : (
                    <TablaPlazosCtaCte
                      plazos={plazos}
                      montoTotal={montoTotal}
                      fechaVenta={factura.fechaHoraFactura}
                    />
                  ))}
                {config.activar_leyenda && data.venta.leyenda.length > 0 && (
                  <View
                    style={{
                      color: "black",
                      marginTop: 5,
                      paddingHorizontal: 10,
                    }}
                  >
                    <Text style={styles.textCenterLeyenda}>
                      {data.venta.leyenda[0].descripcion}
                    </Text>
                  </View>
                )}
              </View>
            </View>
            <View style={styles.containerRowTableEnd}>
              <View style={(styles.containerColumn, { width: 200 })}>
                <Text style={styles.textTotalesFactura}>SUBTOTAL</Text>
                <View>
                  {tipo === "ventaA4" ? (
                    <Text style={styles.textTotalesFactura}>
                      {"DESCUENTO" + porcDescuento}
                    </Text>
                  ) : tipo !== "ventaA4" && dcto ? (
                    <Text style={styles.textTotalesFactura}>
                      {"DESCUENTO" + porcDescuento}
                    </Text>
                  ) : null}
                  {tipo === "ventaA4" ? (
                    <Text style={styles.textTotalesFactura}>{`RECARGO`}</Text>
                  ) : tipo !== "ventaA4" && dcto ? (
                    <Text style={styles.textTotalesFactura}>{`RECARGO`}</Text>
                  ) : null}
                  {(nombre === "A" || nombre === "M") && (
                    <Text
                      style={styles.textTotalesFactura}
                    >{`NETO GRAVADO`}</Text>
                  )}
                  {(nombre === "A" || nombre === "M") && (
                    <Text style={styles.textTotalesFactura}>
                      {`IVA ${vat[0].vat_type.description || ""}`}
                    </Text>
                  )}
                  {(nombre === "A" || nombre === "M") &&
                    receipt.taxes.map((tax, index) => (
                      <Text key={index} style={styles.textTotalesFactura}>
                        {`${textAbbreviation(
                          tax.description,
                        ).toUpperCase()} - ${Number(tax.aliquot)}%`}
                      </Text>
                    ))}
                </View>

                <Text style={styles.textTotalesFactura}>TOTAL</Text>
              </View>
              <View style={(styles.containerColumn, { width: 70 })}>
                <View>
                  <Text style={styles.textTotalesFactura}>
                    {factura.is_agrupada
                      ? parseCurrency(
                          nombre === "A" || nombre === "M"
                            ? getPriceNetoGravado(amountSubtotal, dcto, recargo)
                            : Number(receipt.total_amount),
                        )
                      : parseCurrency(amountSubtotal)}
                  </Text>
                  {tipo === "ventaA4" ? (
                    <Text style={styles.textTotalesFactura}>
                      {parseCurrency(Number(factura.is_agrupada ? 0 : dcto))}
                    </Text>
                  ) : tipo !== "ventaA4" && dcto ? (
                    <Text style={styles.textTotalesFactura}>
                      {parseCurrency(Number(factura.is_agrupada ? 0 : dcto))}
                    </Text>
                  ) : null}
                  {tipo === "ventaA4" ? (
                    <Text style={styles.textTotalesFactura}>
                      {parseCurrency(Number(factura.is_agrupada ? 0 : recargo))}
                    </Text>
                  ) : tipo !== "ventaA4" && dcto ? (
                    <Text style={styles.textTotalesFactura}>
                      {parseCurrency(Number(factura.is_agrupada ? 0 : recargo))}
                    </Text>
                  ) : null}
                  {(nombre === "A" || nombre === "M") && (
                    <Text style={styles.textTotalesFactura}>
                      {parseCurrency(
                        getPriceNetoGravado(amountSubtotal, dcto, recargo),
                      )}
                    </Text>
                  )}

                  {(nombre === "A" || nombre === "M") && (
                    <Text style={styles.textTotalesFactura}>
                      {parseCurrency(Number(vat[0].amount))}
                    </Text>
                  )}
                  {(nombre === "A" || nombre === "M") &&
                    receipt.taxes.map((tax, index) => (
                      <Text key={index} style={styles.textTotalesFactura}>
                        {parseCurrency(Number(tax.amount))}
                      </Text>
                    ))}
                </View>

                <Text style={styles.textTotalesFactura}>
                  {parseCurrency(Number(receipt.total_amount))}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      {nombre === "B" && (
        <View style={tipo === "ventaA4" ? { marginLeft: 20 } : {}}>
          <RegimenTransparenciaFiscalA4 ivaAmount={vat[0].amount} />
        </View>
      )}
    </View>
  );
}
