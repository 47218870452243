import React from "react";
import {
  useAnularConciliacionVentas,
  useObtenerConciliacionVentas,
  useObtenerVentasAdeudadasDeConciliacionVentas,
} from "../../../services/conciliacionVentas";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import ButtonCancelar from "../Button/ButtonCancelar";
import ReportTable from "../Report/ReportTable";
// import formatVentasConciliacionVenta from "./utils";
import ButtonAceptar from "../Button/ButtonAceptar";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { errorNotification } from "../../Notifications";
import formatVentasConciliacionVenta from "./utils";

export default function ModalRehacerConciliacion({
  open,
  toggle,
  conciliacion_ventas_id,
}) {
  const history = useHistory();
  const { idCliente } = useParams();
  const conciliacionVentas = useObtenerConciliacionVentas({
    queryParams: { conciliacionVentasId: conciliacion_ventas_id },
  });
  const obtenerVentasAdeudadasDeConciliacionVentas =
    useObtenerVentasAdeudadasDeConciliacionVentas({
      queryParams: { id: conciliacion_ventas_id },
    });
  let columns = ["Comprobante", "Deuda"];

  const handleRecrearConciliacion = () => {
    history.push(
      `/facturas-adeudadas/${idCliente}?recrear_conciliacion_id=${conciliacion_ventas_id}`,
    );
  };

  return (
    <Dialog open={open} onClose={toggle}>
      {(conciliacionVentas.isLoading ||
        conciliacionVentas.isFetching ||
        obtenerVentasAdeudadasDeConciliacionVentas.isLoading ||
        obtenerVentasAdeudadasDeConciliacionVentas.isFetching) && (
        <DialogContent>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
            }}
          >
            <CircularProgress />
            <Typography>Cargando conciliación...</Typography>
          </Box>
        </DialogContent>
      )}

      {!conciliacionVentas.isLoading &&
        !conciliacionVentas.isFetching &&
        conciliacionVentas.data &&
        !conciliacionVentas.error &&
        !obtenerVentasAdeudadasDeConciliacionVentas.isLoading &&
        !obtenerVentasAdeudadasDeConciliacionVentas.isFetching &&
        obtenerVentasAdeudadasDeConciliacionVentas.data &&
        !obtenerVentasAdeudadasDeConciliacionVentas.error && (
          <>
            <DialogTitle>{`Rehacer conciliación n° ${conciliacionVentas.data.id}`}</DialogTitle>
            <DialogContent dividers>
              <Typography
                component={DialogContentText}
                color="inherit"
                align="center"
              >
                ¿Está seguro que desea rehacer la conciliación{" "}
                {conciliacionVentas.data.id}?
              </Typography>
              <Typography
                component={DialogContentText}
                color="inherit"
                align="center"
              >
                Solo se cargarán las siguientes ventas adeudadas que siguen con
                deuda con deudas.
              </Typography>
              <ReportTable
                heightStyle={"calc(100vh - 350px)"}
                billetera
                loading={false}
                items={formatVentasConciliacionVenta({
                  ventas: obtenerVentasAdeudadasDeConciliacionVentas.data,
                })}
                columns={columns}
              />
            </DialogContent>
            <DialogActions>
              <ButtonAceptar
                click={handleRecrearConciliacion}
                color="primary"
                message={"Aceptar"}
                loading={false}
              />
              <ButtonCancelar
                click={toggle}
                color="primary"
                message={"Cerrar"}
                disabled={false}
              />
            </DialogActions>
          </>
        )}
    </Dialog>
  );
}
