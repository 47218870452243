import moment from "moment";
export const cardsInforme = () => {
  return [
    {
      id: 1,
      title: "Ventas por mes",
      description:
        "Informe mensual del total vendido por empleado y por medio de pago.",
    },
    // {
    //   id: 2,
    //   title: "Ventas por medio de pago",
    //   description: "Informe de los totales vendidos por medio de pago.",
    // },
    // {
    //   id: 3,
    //   title: "Auditoria",
    //   description:
    //     "Informe de auditoria de movimientos de caja, para un mes y año en específico.",
    // },
    {
      id: 4,
      title: "Ventas por artículo",
      description: "Informe del total de artículos vendidos por proveedor.",
    },
    {
      id: 5,
      title: "Compras pagadas",
      description: "Informe de compras pagadas por proveedor y/o por período.",
    },
    {
      id: 6,
      title: "Informe de deudores",
      description:
        "Informe de los clientes que más importes nos adeudan, ordenados de mayor a menor.",
    },
    {
      id: 7,
      title: "Frecuencia de actualización de proveedores",
      description: "Informe de proveedores con sus últimas actualizaciones.",
    },
    {
      id: 8,
      title: "Clientes con comisión",
      description:
        "Informe de los clientes que mas han aprovechado la comisión Cliente Mecánico.",
    },
    {
      id: 9,
      title: "Top de artículos",
      description:
        "Informe de los articulos mas vendidos por un proveedor en un periodo seleccionado.",
    },
    {
      id: 10,
      title: "Deudas pendientes con mis proveedores",
      description:
        "Este informe te permite consultar el monto total adeudado a tus proveedores en una fecha específica.",
    },
  ];
};

export const initialFilter = {
  month: moment().month(),
  year: moment().year(),
  type: 1,
  provider: null,
};

export const cantidadesList = [10, 20, 50, 100];

export const isDisabled = (id, filter) => {
  let date = new Date();
  let invalid = false;
  switch (id) {
    case 1:
    case 2:
      if (filter.year === "" || parseInt(filter.year) > moment(date).year())
        invalid = true;
      break;
    case 3:
      if (filter.year === "" || parseInt(filter.year) > moment(date).year())
        invalid = true;
      break;
    case 4:
      if (filter.provider === null) invalid = true;
      break;
    case 5:
      if (filter.year === "" || parseInt(filter.year) > moment(date).year())
        invalid = true;
      break;
    default:
      break;
  }
  return invalid;
};

export const restarMeses = (cantidadMesesSelect) => {
  let date = new Date();
  date.setDate(1);
  date.setMonth(date.getMonth() - cantidadMesesSelect);
  return moment(date).format("YYYY-MM-DD");
};
