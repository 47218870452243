import { Checkbox, Grid, InputLabel, Typography } from "@material-ui/core";
import React from "react";

export default function DetallesAMostrar({ formik }) {
  const fields = [
    { name: "logotipo", label: "Logotipo del negocio" },
    { name: "codigoProveedor", label: "Código proveedor" },
    { name: "codigoOriginal", label: "Código original" },
    { name: "codigoAuxiliar", label: "Código auxiliar" },
    { name: "nombreProveedor", label: "Nombre del proveedor" },
    { name: "numeroProveedor", label: "Número del proveedor" },
    { name: "nombreNegocio", label: "Nombre del negocio" },
    { name: "ubicacion", label: "Ubicación" },
    { name: "precio", label: "Precio" },
    { name: "descripcion", label: "Descripción" },
    { name: "fechaImpresion", label: "Fecha de impresión" },
  ];

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Typography
          className="mb-2"
          style={{ fontSize: "1.5rem", color: "gray" }}
        >
          Detalles a mostrar
        </Typography>
        <Typography
          className="mb-2"
          style={{ fontSize: "0.85rem", color: "gray" }}
        >
          Seleccioná qué datos del artículo incluir en las etiquetas.
        </Typography>
        <Typography
          className="mb-2"
          style={{ fontSize: "0.85rem", color: "gray" }}
        >
          <span role="img" aria-label="Advertencia">
            ⚠️
          </span>{" "}
          Algunas medidas muestran sólo ciertos datos específicos por
          restricciones de espacio.
        </Typography>
      </Grid>
      {fields.map((field) => (
        <Grid
          key={field.name}
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 10,
          }}
        >
          <Checkbox
            id={`checkbox-${field.name}`}
            name={field.name}
            color="primary"
            onChange={formik.handleChange}
            checked={formik.values[field.name]}
          />

          <InputLabel
            htmlFor={`checkbox-${field.name}`}
            style={{ marginBottom: 0, flexGrow: 1, cursor: "pointer" }}
          >
            <Typography>{field.label}</Typography>
          </InputLabel>
        </Grid>
      ))}
    </Grid>
  );
}
