import { parseCurrency } from "../../../utils/parsers";

export default function formatVentasConciliacionVenta({ ventas }) {
  const tipoComprobante = (venta) => {
    if (venta.tipo_comprobante === "Factura") {
      return "FA";
    } else if (venta.tipo_comprobante === "Comprobante interno") {
      return "CI";
    } else {
      return venta.tipo_comprobante;
    }
  };
  return ventas.map((venta) => ({
    comprobante: `${tipoComprobante(venta)} N°${venta.nro_factura ? venta.nro_factura : venta.idVenta}`,
    monto: parseCurrency(venta.deuda),
  }));
}
