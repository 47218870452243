import {
  GET_CONFIGURACION_ETIQUETAS,
  UPDATE_CONFIGURACION_ETIQUETAS,
} from "./types";
import {
  getConfiguracionEtiquetasService,
  putConfiguracionEtiquetasService,
} from "../../views/Configuration/ConfiguracionEtiquetas/Services";

export const getConfiguracionEtiquetas = () => async (dispatch) => {
  try {
    const data = await getConfiguracionEtiquetasService();
    dispatch({ type: GET_CONFIGURACION_ETIQUETAS, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const updateConfiguracionEtiquetas = (data) => async (dispatch) => {
  try {
    await putConfiguracionEtiquetasService(data);
    dispatch({ type: UPDATE_CONFIGURACION_ETIQUETAS, payload: data });
  } catch (error) {
    console.error(error);
  }
};
