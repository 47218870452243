import Label100x50 from "./Label100x50";
import Label100x75 from "./Label100x75";
import Label30x15 from "./Label30x15";
import Label42x29 from "./Label42x29";
import Label44x55 from "./Label44x55";
import Label50x25 from "./Label50x25";
import Label55x44 from "./Label55x44";
import Label80x25 from "./Label80x25";
import Label80x50 from "./Label80x50";

export const LabelComponent = (size) => {
  const componentes = {
    "30x15": Label30x15,
    "42x29": Label42x29,
    "44x55": Label44x55,
    "50x25": Label50x25,
    "55x44": Label55x44,
    "80x25": Label80x25,
    "80x50": Label80x50,
    "100x50": Label100x50,
    "100x75": Label100x75,
  };

  return componentes[size] || Label55x44;
};
