import React, { Fragment } from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { formatCurrency } from "../../../../../utils/parsers";

export default function ListadoVentasTicket({ data }) {
  return (
    <View style={styles.containerRow}>
      <View style={styles.containerColumn}>
        <Text style={{ ...styles.textbold, paddingBottom: 5 }}>
          COMPROBANTES:
        </Text>
        {data.ventas_saldadas.map((venta, index) => (
          <Fragment key={index}>
            <View
              style={{
                ...styles.containerRow,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={styles.textbold}
                >{`Comprobante N° ${venta.id_venta}`}</Text>
                <Text style={styles.textbold}>Importe total</Text>
                <Text style={styles.textbold}>Importe saldado</Text>
              </View>
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={styles.textbold}
                >{`Pago N° ${venta.pago_cliente_id}`}</Text>
                <Text style={styles.textbold}>
                  {formatCurrency(venta.venta_monto_total)}
                </Text>
                <Text style={styles.textbold}>
                  {formatCurrency(venta.monto_saldado)}
                </Text>
              </View>
            </View>
            <View
              style={{
                ...styles.containerRow,
                paddingBottom: 10,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={styles.textbold}
                >{`Observaciones de venta: ${venta.venta_observaciones || "---"}`}</Text>
              </View>
            </View>
          </Fragment>
        ))}
      </View>
    </View>
  );
}
