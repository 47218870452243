import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EtiquetasTicket from "../../../../components/ait-reusable/Etiquetas/EtiquetasTicket";
import TextFieldAutocompleteArticulo from "../../../../components/ait-reusable/TextFieldAutocompleteArticulo";
import useArticulosSucursal from "../../../../customHooks/useGetArticulosSucursal";
import { useGetSucursal } from "../../../../customHooks/useGetSucursal";
import { getFilters } from "../../../Proveedores/Catalogo/utils";
import { hanldeBlobPDFTicket, textSizes } from "./Utils";

export default function PrevisualizacionPdf({ formik }) {
  const [medidas, setMedidas] = useState(null);
  const [articulo, setArticulo] = useState(null);
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  const { sucursal } = useGetSucursal();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { dataArticulos } = useArticulosSucursal({
    listfilters: [],
    useMeli: false,
    proveedorSelect: "0",
  });
  const formato_etiqueta = configGeneral.formato_etiqueta;

  const isButtonDisabled = !medidas || !articulo;

  useEffect(() => {
    dataArticulos.getArticulosSucursal();
  }, [dataArticulos.filters.debouncedSearchTerm]);

  useEffect(() => {
    handleGetFitlers();
  }, []);

  useEffect(() => {
    setMedidas(null);
    setArticulo(null);
  }, [formik.values]);

  const handleGetFitlers = async () => {
    try {
      const response = await getFilters();

      dataArticulos.filters.setFilter(
        localStorage.getItem("filtroArticulos") || response[0].id,
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDescargarPrevisualizacion = async () => {
    await formik.handleSubmit();
    await hanldeBlobPDFTicket({
      medidas,
      articulo,
      sucursal,
      configGeneral,
      configuracion: formik.values,
    });
  };

  return (
    <Grid container>
      <Grid container item xs={6} style={{ marginTop: 20 }}>
        <Grid item xs={12}>
          <Typography
            className="mb-2"
            style={{ fontSize: "1.5rem", color: "gray" }}
          >
            Previsualización de Etiqueta
          </Typography>
          <Typography
            className="mb-2"
            style={{ fontSize: "0.85rem", color: "gray" }}
          >
            Visualizá en tiempo real cómo quedará la etiqueta con la
            configuración y medida seleccionadas antes de guardar los cambios.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              value={articulo}
              inputValue={autocompleteValue}
              options={dataArticulos.articulos}
              filterOptions={(options, _state) => options}
              onChange={(_event, value) => setArticulo(value)}
              onInputChange={(_event, value) => {
                setAutocompleteValue(value);
                dataArticulos.filters.setBusqueda(value);
              }}
              getOptionLabel={(option) =>
                `${option.codProveedor} - ${option.descripcionProveedor}`
              }
              renderInput={(params) => (
                <TextFieldAutocompleteArticulo
                  {...params}
                  size="small"
                  variant="outlined"
                  placeholder="Artículo"
                />
              )}
              name="buscador_articulos"
              style={{ maxWidth: "400px" }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            fullWidth
            size="small"
            name="medidas"
            label="Medidas"
            variant="outlined"
            style={{ marginTop: 5, maxWidth: "215px" }}
            onChange={(e) => {
              const selectedMedida = formato_etiqueta.find(
                (etiqueta) => etiqueta.id === e.target.value,
              );
              setMedidas(selectedMedida);
            }}
            value={medidas ? medidas.id : ""}
            SelectProps={{
              renderValue: (selected) => {
                if (!formato_etiqueta) return "";
                const etiquetaSeleccionada = formato_etiqueta.find(
                  (etiqueta) => etiqueta.id === selected,
                );
                return etiquetaSeleccionada
                  ? `${etiquetaSeleccionada.ancho}mm x ${etiquetaSeleccionada.alto}mm`
                  : "";
              },
            }}
          >
            {configGeneral &&
              formato_etiqueta &&
              formato_etiqueta.map((medida) => {
                if (medida.es_visible) {
                  return (
                    <MenuItem
                      key={medida.id}
                      value={medida.id}
                      disabled={!medida.es_visible}
                      style={{
                        whiteSpace: "nowrap",
                        maxWidth: "350px",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>
                          {medida.ancho}mm x {medida.alto}mm{" "}
                          {medida.ancho === 100 && medida.alto === 50
                            ? '"Vertical"'
                            : ""}
                        </span>
                        <span
                          style={{
                            fontSize: "0.8rem",
                            color: "gray",
                            whiteSpace: "normal",
                          }}
                        >
                          {textSizes[`${medida.ancho}x${medida.alto}`] ||
                            "Todos los datos seleccionados"}
                        </span>
                      </div>
                    </MenuItem>
                  );
                }
                return null;
              })}
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={6} style={{ paddingLeft: 10 }}>
        {medidas && articulo && (
          <PDFViewer width="300px" height="200px">
            <EtiquetasTicket
              alto={medidas.alto}
              ancho={medidas.ancho}
              sucursal={sucursal}
              articulo={articulo}
              configGeneral={configGeneral}
              configuracionEtiqueta={formik.values}
            />
          </PDFViewer>
        )}
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          disabled={isButtonDisabled}
          children="GUARDAR Y PREVISUALIZAR"
          onClick={handleDescargarPrevisualizacion}
          style={{ marginTop: 10, paddingLeft: 20, maxWidth: "150px" }}
        >
          GUARDAR Y PREVISUALIZAR
        </Button>
      </Grid>
      <canvas id="barcode" style={{ display: "none" }} />
    </Grid>
  );
}
