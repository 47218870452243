import { Box, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { textSizes } from "./Utils";

export default function EtiquetaDefault({ formik }) {
  const { formato_etiqueta } = useSelector(
    (state) => state.configGeneral.configGeneral,
  );

  const etiquetaDefault = (formato_etiqueta || []).find(
    (etiqueta) => !!etiqueta.es_default,
  );

  useEffect(() => {
    if (
      !formik.values.etiquetaDefault &&
      formato_etiqueta &&
      formato_etiqueta.length > 0
    ) {
      const etiquetaDefault = formato_etiqueta.find(
        (etiqueta) => etiqueta.es_default,
      );

      if (etiquetaDefault) {
        formik.setFieldValue("etiquetaDefault", etiquetaDefault.id);
      }
    }
  }, [etiquetaDefault]);

  const newBlankWp = () => {
    window.open("https://api.whatsapp.com/send/?phone=543518085094");
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Typography
          className="mb-2"
          style={{ fontSize: "1.5rem", color: "gray" }}
        >
          Elegir medida de Etiqueta por defecto
        </Typography>
        <Typography
          className="mb-2"
          style={{ fontSize: "0.85rem", color: "gray" }}
        >
          Elegí la medida que más se ajusta tus necesidades para usarla como
          predeterminada. Igual vas a poder cambiarla al momento de imprimir.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 5 }}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography style={{ marginRight: 4 }}>
            Medidas de Etiquetas
          </Typography>
          <Alert severity="info" style={{ marginLeft: 10 }}>
            <Typography style={{ fontSize: 12, margin: 0, padding: 0 }}>
              ¿No encontrás la medida que necesitás? Solicitala a{" "}
              <span
                onClick={newBlankWp}
                style={{ cursor: "pointer", color: "blue" }}
              >
                Soporte
              </span>{" "}
              para que la agreguemos.
            </Typography>
          </Alert>
        </Box>
      </Grid>
      <Grid item xs={6} md={4} style={{ marginTop: 5 }}>
        <TextField
          select
          fullWidth
          size="small"
          variant="outlined"
          name="etiquetaDefault"
          label="Medidas"
          onChange={formik.handleChange}
          value={formik.values.etiquetaDefault}
          SelectProps={{
            renderValue: (selected) => {
              if (!formato_etiqueta) return "";
              const etiquetaSeleccionada = formato_etiqueta.find(
                (etiqueta) => etiqueta.id === selected,
              );
              return etiquetaSeleccionada
                ? `${etiquetaSeleccionada.ancho}mm x ${etiquetaSeleccionada.alto}mm`
                : "";
            },
          }}
          style={{ maxWidth: "215px" }}
        >
          {formato_etiqueta &&
            formato_etiqueta.map((etiqueta) => (
              <MenuItem
                key={etiqueta.id}
                value={etiqueta.id}
                disabled={!etiqueta.es_visible}
                style={{
                  whiteSpace: "nowrap",
                  maxWidth: "350px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>
                    {etiqueta.ancho}mm x {etiqueta.alto}mm{" "}
                    {etiqueta.ancho === 100 && etiqueta.alto === 50
                      ? '"Vertical"'
                      : ""}
                  </span>
                  <span
                    style={{
                      fontSize: "0.8rem",
                      color: "gray",
                      whiteSpace: "normal",
                    }}
                  >
                    {textSizes[`${etiqueta.ancho}x${etiqueta.alto}`] ||
                      "Todos los datos seleccionados"}
                  </span>
                </div>
              </MenuItem>
            ))}
        </TextField>
      </Grid>
    </Grid>
  );
}
