import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../assets/css/styleTicket";
import moment from "moment";
import { parseDate } from "../../../../utils/parsers";
import ClienteTicket from "./componentes/ClienteTicket";
import FormaPagoTicket from "./componentes/FormaPagoTicket";
import ObservacionesTicket from "./componentes/ObservacionesTicket";
import LineaSeparacionTicket from "./componentes/LineaSeparacionTicket";
import TotalesTicket from "./componentes/TotalesTicket";
import ListadoVentasTicket from "./componentes/ListadoVentasTicket";

export default function ConciliacionVentasTicket({ data, config }) {
  return (
    <Document
      title={`Conciliación N° ${data.id} - ${moment(data.fecha_hora).format("DD/MM/YYYY")} - ${data.cliente_sucursal.razon_social}`}
    >
      <Page style={styles.page} size={{ width: 226.77, minHeight: 400 }}>
        {data.sucursal.logo_base64 && !config.ocultar_datos_ci && (
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <Image
              cache={false}
              style={{ width: 120, height: 80 }}
              allowDangerousPaths={true}
              src={
                data.sucursal.logo_base64 &&
                `data:image/png/jpg;base64,${data.sucursal.logo_base64}`
              }
            />
          </View>
        )}

        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              fontSize: 8,
              textAlign: "center",
              paddingBottom: 10,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            {data.sucursal.razonSocial ? data.sucursal.razonSocial : null}
          </Text>
        </View>

        <View
          style={{
            flexDirection: "column",
            justifyContent: "space-between",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Text style={styles.text}>CONCILIACION N° {data.id}</Text>
          <Text style={styles.text}>
            FECHA DE EMISIÓN: {parseDate(data.fecha_hora, "DD/MM/YYYY HH:mm")}
          </Text>
          <Text style={styles.text}>
            {`CUIT: ${data.sucursal.CUIT ? data.sucursal.CUIT : "---"}`}
          </Text>
          <Text style={styles.text}>
            {`INGRESOS BRUTOS: ${
              data.sucursal.ingresosBrutos
                ? data.sucursal.ingresosBrutos
                : "---"
            }`}
          </Text>
          <Text style={styles.text}>
            {`DOMICILIO: ${data.sucursal.domicilio ? data.sucursal.domicilio : "---"}`}
          </Text>
          <Text style={styles.text}>
            {`TE: ${
              data.sucursal.telefono_contacto
                ? data.sucursal.telefono_contacto
                : "---"
            }`}
          </Text>
        </View>

        <LineaSeparacionTicket />

        <ClienteTicket data={data} />

        <LineaSeparacionTicket />

        <ObservacionesTicket data={data} />

        <LineaSeparacionTicket />

        <FormaPagoTicket data={data} />

        <LineaSeparacionTicket />

        <ListadoVentasTicket data={data} />

        <LineaSeparacionTicket />

        <TotalesTicket data={data} />

        <LineaSeparacionTicket />
      </Page>
    </Document>
  );
}
