import { View } from "@react-pdf/renderer";
import React from "react";
import { ETIQUETA_ESTILO, mostrarCodigosArticulo } from "./Design80x25";
import {
  mostrarDescripcionArticulo,
  mostrarLogoSucursal,
  mostrarNombreSucursal,
  styles,
  viewCodBarra,
} from "./utils";

export default function Label80x25({
  sucursal,
  articulo,
  configGeneral,
  configuracionEtiqueta,
}) {
  //1px = 0.36 mm
  return (
    <View
      style={{
        ...styles.contenedorTicket,
        width: 222.2,
        minWidth: 222.2,
        maxWidth: 222.2,
        height: 69.4,
        minHeight: 69.4,
        maxHeight: 69.4,
        padding: 7,
      }}
    >
      {/* NOMBRE SUCURSAL */}
      {configuracionEtiqueta.nombreNegocio && sucursal
        ? mostrarNombreSucursal(
            sucursal.razonSocial,
            ETIQUETA_ESTILO.baseFontSize,
            ETIQUETA_ESTILO.maxCharBaseFontSize,
            ETIQUETA_ESTILO.baseMarginBottom,
          )
        : null}

      {/* CODIGOS ARTICULO */}
      {configuracionEtiqueta &&
        articulo &&
        mostrarCodigosArticulo(configuracionEtiqueta, articulo)}

      {/* DESCRIPCION */}
      {configuracionEtiqueta.descripcion && articulo
        ? mostrarDescripcionArticulo(
            articulo.descripcionProveedor,
            ETIQUETA_ESTILO.baseFontSize,
            ETIQUETA_ESTILO.maxCharDescription,
            ETIQUETA_ESTILO.baseMarginBottom,
          )
        : null}

      {/* CODIGO BARRA + LOGO */}
      <View style={styles.viewCodeBar}>
        {configuracionEtiqueta.logotipo
          ? mostrarLogoSucursal(configGeneral.url_imagen)
          : null}

        {configuracionEtiqueta
          ? viewCodBarra(
              configuracionEtiqueta,
              articulo,
              Boolean(configuracionEtiqueta.logotipo),
            )
          : null}
      </View>
    </View>
  );
}
