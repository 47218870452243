import React from "react";
import {
  useAnularConciliacionVentas,
  useObtenerConciliacionVentas,
} from "../../../services/conciliacionVentas";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import ButtonCancelar from "../Button/ButtonCancelar";
import ReportTable from "../Report/ReportTable";
import formatVentasConciliacionVenta from "./utils";
import ButtonAceptar from "../Button/ButtonAceptar";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

export default function ModalAnularConciliacionVentas({
  open,
  toggle,
  conciliacion_ventas_id,
  onAnularConciliacionVentas = async () => {},
}) {
  const history = useHistory();
  const { idCliente } = useParams();
  const [recrearConciliacion, setRecrearConciliacion] = React.useState(false);
  const conciliacionVentas = useObtenerConciliacionVentas({
    queryParams: { conciliacionVentasId: conciliacion_ventas_id },
  });
  let anularConciliacionVentas = useAnularConciliacionVentas();
  let columns = ["Comprobante", "Monto", "Estado"];

  const handleAnularConciliacion = async () => {
    const data = await anularConciliacionVentas.mutateAsync({
      id: conciliacion_ventas_id,
    });

    if (recrearConciliacion) {
      history.push(
        `/facturas-adeudadas/${idCliente}?recrear_conciliacion_id=${conciliacion_ventas_id}`,
      );
    } else {
      await onAnularConciliacionVentas();
      toggle();
    }
  };
  return (
    <Dialog open={open} onClose={toggle}>
      {(conciliacionVentas.isLoading || conciliacionVentas.isFetching) && (
        <DialogContent>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
            }}
          >
            <CircularProgress />
            <Typography>Cargando conciliación...</Typography>
          </Box>
        </DialogContent>
      )}

      {!conciliacionVentas.isLoading &&
        !conciliacionVentas.isFetching &&
        conciliacionVentas.data &&
        !conciliacionVentas.error && (
          <>
            <DialogTitle>{`Anular conciliación n° ${conciliacionVentas.data.id}`}</DialogTitle>
            <DialogContent dividers>
              <Typography
                component={DialogContentText}
                color="inherit"
                align="center"
              >
                ¿Está seguro que desea anular la conciliación{" "}
                {conciliacionVentas.data.id} de $
                {parseFloat(
                  conciliacionVentas.data.importe_total,
                ).toLocaleString("ES-ar")}
                ?
              </Typography>
              <Typography
                component={DialogContentText}
                color="inherit"
                align="center"
              >
                Si confirmás, se anularán todos los pagos vigentes y los
                siguientes comprobantes volverán a estar pendientes de pago:
              </Typography>

              <ReportTable
                noResults={"No se encontraron cobros registrados."}
                heightStyle={"calc(100vh - 350px)"}
                billetera
                loading={false}
                items={formatVentasConciliacionVenta({
                  ventas: conciliacionVentas.data.ventas_saldadas,
                })}
                columns={columns}
              />

              <Box mt={2} />

              {conciliacionVentas.data.orden_carga_saldo && (
                <Typography
                  component={DialogContentText}
                  color="inherit"
                  align="center"
                >
                  También se anulará la orden de carga de saldo n°{" "}
                  {conciliacionVentas.data.orden_carga_saldo.id} de $
                  {parseFloat(
                    conciliacionVentas.data.orden_carga_saldo.monto,
                  ).toLocaleString("ES-ar")}{" "}
                  que se hizo para conciliar las ventas{" "}
                </Typography>
              )}

              {Number(conciliacionVentas.data.importe_cuenta_corriente) > 0 && (
                <Typography
                  component={DialogContentText}
                  color="inherit"
                  align="center"
                >
                  Además, el saldo en billetera que se usó como parte de pago de
                  la conciliación ($
                  {parseFloat(
                    conciliacionVentas.data.importe_cuenta_corriente,
                  ).toLocaleString("ES-ar")}{" "}
                  ) volverá a estar disponible como saldo a favor en la
                  billetera del cliente.
                </Typography>
              )}
              <Typography
                component={DialogContentText}
                color="inherit"
                align="center"
              >
                Si te equivocaste al hacer al conciliación y querés anularla
                para hacerla de nuevo, podés usar la opcion "Recrear
                conciliación" para pagar los mismos comprobantes que la
                conciliacion original:
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={recrearConciliacion}
                    onChange={() =>
                      setRecrearConciliacion(!recrearConciliacion)
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Anular y rehacer conciliación"
              />
            </DialogContent>
            <DialogActions>
              <ButtonAceptar
                click={handleAnularConciliacion}
                color="primary"
                message={"Aceptar"}
                loading={anularConciliacionVentas.isLoading}
              />
              <ButtonCancelar
                click={toggle}
                color="primary"
                message={"Cerrar"}
                disabled={anularConciliacionVentas.isLoading}
              />
            </DialogActions>
          </>
        )}
    </Dialog>
  );
}
