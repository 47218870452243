import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import ButtonAceptar from "../../../../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonFormato from "../../../../../../components/ait-reusable/Button/ButtonFormato";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "500px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ModalEnvioComprobante({
  open,
  handleClose,
  handleSubmit,
}) {
  const [action, setAction] = useState("");

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.root }}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Enviar comprobante</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Elige en que formato quieres imprimir el comprobante o si desea
          enviarlo por whatsapp
        </DialogContentText>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <ButtonFormato
                tipo="Ticket"
                message="Ticket"
                icon="receipt"
                click={() => setAction("Ticket")}
                format={action}
              />
            </Grid>
            <Grid item>
              <ButtonFormato
                tipo="A4"
                message="A4"
                icon="print"
                click={() => setAction("A4")}
                format={action}
              />
            </Grid>
            <Grid item>
              <ButtonFormato
                tipo="Whatsapp"
                message="Enviar por Whatsapp"
                icon="whatsapp"
                click={() => setAction("Whatsapp")}
                format={action}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ justifyContent: "center" }}>
        <ButtonCancelar click={handleClose} message="Cancelar" />
        <ButtonAceptar
          disabled={action === ""}
          click={() => handleSubmit("EnvioComprobante", action)}
          message="Aceptar"
        />
      </DialogActions>
    </Dialog>
  );
}
