import request from "../requests/request";
import { useMutation, useQuery } from "react-query";

export const conciliacionVentasServices = {
  obtenerConciliacionVentas: async ({ conciliacionVentasId }) => {
    const response = await request({
      method: "GET",
      url: `/api/pagos/obtener-conciliacion-ventas/${conciliacionVentasId}/`,
    });
    return response.data;
  },
  anularConciliacionVenas: async ({ id }) => {
    const response = await request({
      method: "PUT",
      url: `/api/pagos/anular-conciliacion-ventas/${id}/`,
    });
    return response.data;
  },
  obtenerVentasAdeudadasDeConciliacionVentas: async ({ id }) => {
    const response = await request({
      method: "GET",
      url: `/api/pagos/conciliacion-ventas/ventas-adeudadas/${id}/`,
    });
    return response.data;
  },
};

export const useObtenerConciliacionVentas = ({
  queryParams,
  queryProps,
} = {}) => {
  return useQuery({
    queryKey: ["listaMedioPago", queryParams],
    queryFn: () =>
      conciliacionVentasServices.obtenerConciliacionVentas({ ...queryParams }),
    ...queryProps,
  });
};

export const useAnularConciliacionVentas = ({
  queryParams,
  queryProps,
} = {}) => {
  return useMutation({
    mutationFn: (data) =>
      conciliacionVentasServices.anularConciliacionVenas(data),
    ...queryProps,
  });
};

export const useObtenerVentasAdeudadasDeConciliacionVentas = ({
  queryParams,
  queryProps,
} = {}) => {
  return useQuery({
    queryKey: ["obtenerVentasAdeudadasDeConciliacionVentas", queryParams],
    queryFn: () =>
      conciliacionVentasServices.obtenerVentasAdeudadasDeConciliacionVentas({
        ...queryParams,
      }),
    ...queryProps,
  });
};
