import moment from "moment";
import { MenuItem, Paper, Box } from "@material-ui/core";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import React from "react";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export function formatPagos(
  pagos,
  setComprobanteDetalle,
  anularPago,
  getReciboComprobante,
  config,
  getReciboComprobanteWapp,
) {
  return pagos.map((pago) => ({
    fecha: moment(pago.idPago.fechaHora).format("DD-MM HH:mm"),
    monto: `$${Number(Math.abs(pago.idPago.monto)).toLocaleString("es-AR")}`,
    responsable: pago.idPago.responsable_pago_cliente
      ? `${pago.idPago.responsable_pago_cliente.nombre} ${pago.idPago.responsable_pago_cliente.apellido}`
      : "---",
    venta: pago.idVenta,
    comprobante: BadgeComprobante(pago),
    opciones: ActionTable(
      pago,
      setComprobanteDetalle,
      anularPago,
      getReciboComprobante,
      config,
      getReciboComprobanteWapp,
    ),
  }));
}

function BadgeComprobante(venta) {
  let message = venta.fechaHoraAnulacion
    ? `Anulada el ${moment(venta.fechaHoraAnulacion).format("DD-MM")}`
    : "Efectiva";
  let color = venta.fechaHoraAnulacion ? "red" : "green";
  return (
    <Paper
      className="font-weight-bold "
      // theme="info"
      style={{
        // width: "100%",
        fontSize: "13px",
        backgroundColor:
          (venta.tipo_comprobante.nombre === "Factura" && "#86a3c2") ||
          "#b6cec7",
        color: "black",
      }}
    >
      <Box display="flex" flexDirection={"row"} justifyContent="center">
        <Box pr={1}>
          <TooltipMoreInfoMaterial
            color={color}
            position={"left"}
            titleTooltip={message}
          />
        </Box>
        <Box style={{ paddingTop: "0.4em" }}>
          {venta.tipo_comprobante.split(" ")[0]}
        </Box>
      </Box>
    </Paper>
  );
}

function ActionTable(
  pago,
  setComprobanteDetalle,
  anularPago,
  getReciboComprobante,
  config,
  getReciboComprobanteWapp,
) {
  return (
    <MenuB>
      <MenuItem onClick={() => setComprobanteDetalle(pago)}>
        Ver detalle
      </MenuItem>
      <MenuItem onClick={() => anularPago(pago)}>Anular Pago</MenuItem>
      {config && config.en_ticket && (
        <MenuItem onClick={() => getReciboComprobante(pago, "ticket")}>
          Imprimir Ticket
        </MenuItem>
      )}
      {config && config.en_a4 && (
        <MenuItem onClick={() => getReciboComprobante(pago, "A4")}>
          Imprimir A4
        </MenuItem>
      )}
      {config && config.en_a4 && (
        <MenuItem onClick={() => getReciboComprobante(pago, "A4Ahorro")}>
          Imprimir A4 - Ahorro de papel
        </MenuItem>
      )}
      <MenuItem onClick={() => getReciboComprobanteWapp(pago, "A4")}>
        Enviar WhatsApp
      </MenuItem>
    </MenuB>
  );
}
