import React, { useEffect, useState } from "react";
import { Paper, TextField, Box, Grid, Button, Icon } from "@material-ui/core";
import { Container } from "shards-react";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";
import ModalHistorialPedido from "../../../Informe/Pedidos/Modales/ModalHistorialPedido";

export default function ListaArticulos({
  articulos,
  remove,
  handleChangeCantidad,
  handleChangePreciosCosto,
  articulosFiltrados,
  setArticulosFiltrados,
  idPedido,
}) {
  const [modalHistorial, setModalHistorial] = useState(false);
  let columns = [
    "Artículo",
    "Original",
    "Descripción",
    "Cantidad",
    "Precio",
    "Acciones",
  ];

  useEffect(() => {
    if (articulos && articulos.length !== 0) {
      // const a = articulos.filter((a) => a.is_deleted === false);
      setArticulosFiltrados(articulos);
    }
  }, [articulos]);

  useEffect(() => {
    formatArticulos();
  }, [articulos]);

  const formatArticulos = () => {
    return articulosFiltrados
      .map(
        (articulo, index) =>
          articulo.is_deleted === false && {
            articulo: articulo.codProveedor || "---",
            original: articulo.codOriginal || "---",
            descripcion: articulo.descripcionProveedor || "---",
            cantidad: (
              <TextField
                style={{ width: "100px" }}
                value={articulo.cantidad}
                name="cantidad"
                type="number"
                variant="outlined"
                size="small"
                onChange={(e) => handleChangeCantidad(e, index)}
              />
            ),
            precio: (
              <TextField
                style={{ width: "100px" }}
                value={articulo.precios.costo || 0}
                name="precio"
                type="number"
                variant="outlined"
                size="small"
                onChange={(e) => handleChangePreciosCosto(e, index)}
              />
            ),
            opciones: (
              <IconButton
                style={{ color: "red", padding: 0 }}
                aria-label="quitar"
                component="span"
                onClick={() => remove(articulo, index)}
              >
                <ClearIcon />
              </IconButton>
            ),
          },
      )
      .filter((a) => a);
  };

  return (
    <Container fluid className="main-content-container px-3">
      <Paper style={{ paddingBottom: 5 }}>
        <Box minHeight={275} maxHeight={300} overflow="auto">
          <ReusableTable
            columns={columns}
            noResults={"Aún no se han agregado artículos al pedido"}
            items={formatArticulos()}
          />
        </Box>
        {idPedido && (
          <Grid
            item
            lg={12}
            sm={12}
            style={{ textAlign: "end", paddingRight: 5, paddingTop: 5 }}
          >
            <Button
              color="primary"
              variant="outlined"
              style={{ outline: "none", fontWeight: "bold" }}
              onClick={() => setModalHistorial(true)}
              startIcon={<Icon>{"history"}</Icon>}
            >
              Historial
            </Button>
          </Grid>
        )}
      </Paper>
      <ModalHistorialPedido
        idPedido={idPedido}
        open={modalHistorial}
        onClose={() => setModalHistorial(false)}
      />
    </Container>
  );
}
