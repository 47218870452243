import React, { useState } from "react";
import ModalReutilizable from "../Modal";
import { Grid, Typography } from "@material-ui/core";
import request from "../../../requests/request";
import { validateNotasCredito } from "../../../requests/urls";
import { errorNotification, successNotification } from "../../Notifications";
import TitleModal from "./TitleModal";
import ReusableTable from "../Report/ReusableTable";
import { AlertReusable } from "../Alerts/AlertReusable";

export default function ModalValidacionFactNC({ open, handleClose, facturas }) {
  const [disabled, setDisabled] = useState(false);

  const formatErrores = (errores) => {
    return errores.map((error) => {
      return {
        nroFactura: error.dataID.nroFactura,
        idNotaCredito: error.dataID.idNotaCredito,
        error: error.mensajeError,
      };
    });
  };

  const getContentNC = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AlertReusable
              severity="error"
              text={`Se generó el pago de la/s factura/s pero ocurrió un error al validar las siguientes notas de crédito en ARCA`}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: 8 }}>
          <Grid item xs={12}>
            <ReusableTable
              columns={["Nro. Factura", "ID Nota de Crédito", "Error"]}
              items={formatErrores(facturas)}
              loading={false}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ paddingTop: 8 }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ textAlign: "center", fontSize: 17 }}
            >
              {`¿Desea reintentar la validación de las notas de crédito ahora? Si no lo desea en este momento, las notas de crédito quedarán registradas pero pendientes de CAE y se podrá reintentar la generación del CAE desde el informe de notas de crédito.`}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const getIDNotasCredito = () => {
    const ids = [];
    facturas.forEach((fact) => {
      ids.push({ nota: fact.dataID.idNotaCredito, anulacion_fcem: "" });
    });
    return ids;
  };

  const validateCAE = async () => {
    setDisabled(true);
    let ids = getIDNotasCredito();
    try {
      const response = await request({
        method: "PUT",
        url: validateNotasCredito,
        data: {
          notas_credito: ids,
        },
        params: {
          paginated: 0,
        },
      });
      handleResponseValidate(response);
    } catch (error) {
      errorNotification(
        "El número de CAE no se ha generado, intente más tarde.",
      );
      setDisabled(false);
    }
  };

  const handleResponseValidate = (res) => {
    if (res.data.status === 201) {
      successNotification("El número de CAE se ha generado con éxito.");
      handleClose();
    } else {
      errorNotification(
        "El número de CAE no se ha generado, intente más tarde.",
      );
      setDisabled(false);
    }
  };
  return (
    <ModalReutilizable
      open={open}
      handleClose={handleClose}
      content={getContentNC()}
      title={<TitleModal title="Validación de CAE de notas de crédito" />}
      handleSubmit={validateCAE}
      openBackdrop={disabled}
      messageAceptar={"Generar CAE"}
      messageCancelar={"Cancelar"}
    />
  );
}
