import {
  GET_CONFIGURACION_ETIQUETAS,
  UPDATE_CONFIGURACION_ETIQUETAS,
} from "../Actions/types";

const initialState = {
  configuracionEtiquetas: {},
};

const configEtiquetasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIGURACION_ETIQUETAS:
      return { ...state, configuracionEtiquetas: action.payload };
    case UPDATE_CONFIGURACION_ETIQUETAS:
      return { ...state, configuracionEtiquetas: action.payload };
    default:
      return state;
  }
};

export default configEtiquetasReducer;
