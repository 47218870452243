import React from "react";
import { Grid, Typography } from "@material-ui/core";
import logoARCA from "../../../images/logoARCA.png";

export default function TitleModal({ title }) {
  return (
    <Grid container sapcing={2} alignItems="center">
      <Grid item xs={4}>
        <img
          alt="logoARCA"
          style={{
            maxWidth: "100%",
            objectFit: "fill",
          }}
          src={logoARCA}
        />
      </Grid>
      <Grid item xs={7}>
        <Typography
          variant="h6"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}
