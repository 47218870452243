import React, { useEffect } from "react";
import useStore from "./store";

const App = ({ onPermissions, onCuentas }) => {
  const permissions = useStore((state) => state.permissions);
  const cuentas = useStore((state) => state.cuentas);

  useEffect(() => {
    onPermissions(permissions);
  }, [permissions]);

  useEffect(() => {
    onCuentas(cuentas);
  }, [cuentas]);

  return <></>;
};

export default App;
