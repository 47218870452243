import React from "react";
import { Button, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles((theme) => ({
  botonNoSeleccionado: {
    backgroundColor: "white",
    color: "black",
    borderColor: "white",
    "&:hover": {
      backgroundColor: "#5a6169",
      color: "white",
      borderColor: "#5a6169",
    },
  },
  botonSeleccionado: {
    backgroundColor: "#5a6169",
    color: "white",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#5a6169",
    "&:hover": {
      backgroundColor: "#5a6169",
      color: "white",
      borderColor: "#5a6169",
    },
  },
}));

export default function ButtonFormato({
  tipo,
  message,
  icon,
  click,
  format,
  disabled,
}) {
  const classes = useStyles();
  const isSelected = tipo === format ? true : false;

  const iconMap = {
    print: <PrintIcon style={{ fontSize: 20 }} />,
    whatsapp: <WhatsAppIcon style={{ fontSize: 20 }} />,
    email: <EmailIcon style={{ fontSize: 20 }} />,
    receipt: <ReceiptIcon style={{ fontSize: 20 }} />,
  };

  return (
    <Button
      className={
        isSelected ? classes.botonSeleccionado : classes.botonNoSeleccionado
      }
      fullWidth
      onClick={click}
      size="small"
      endIcon={iconMap[icon]}
      style={{
        outline: "none",
      }}
      disabled={disabled}
    >
      {message}
    </Button>
  );
}
