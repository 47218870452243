import request from "../../../../requests/request";
import { comprobanteAFactura } from "../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import moment from "moment";
import useValidacionMensajeErrorAFIP from "../../../../customHooks/useValidacionMensajeErrorAFIP";

const useFacturar = (
  onSuccess,
  onError,
  id,
  disable,
  setOpenBackdrop,
  selectedDate,
  closeModalErrorAFIP,
) => {
  const { getMensajeErrorValidacionFactura, getDataInfoErrorDevolucion } =
    useValidacionMensajeErrorAFIP();
  const handlePostFactura = async (
    tipoFactura,
    comprobante,
    vencimientoDelPago,
    cbu,
    alias,
    opcionTransferencia,
  ) => {
    let data =
      comprobante.nombre === "FCEM A"
        ? {
            tipo_factura: comprobante.idTipoFactura,
            tipo_comprobante: comprobante.idTipoComprobante,
            fecha_vencimiento: moment(vencimientoDelPago).format("YYYY-MM-DD"),
            data_fcem: {
              fecha_vencimiento_pago:
                moment(vencimientoDelPago).format("YYYY-MM-DD"),
              cbu: cbu,
              alias: alias,
              opcion_transferencia: opcionTransferencia,
            },
          }
        : {
            tipo_factura: comprobante.idTipoFactura,
            tipo_comprobante: comprobante.idTipoComprobante,
            fecha_vencimiento: selectedDate,
            data_fcem: null,
          };

    if (!disable.disabled || !disable) {
      disable && disable.setDisabled(true);
      setOpenBackdrop && setOpenBackdrop(true);
      try {
        const response = await request({
          method: "POST",
          url: comprobanteAFactura(id),
          data: data,
        });
        handleResponsePostFactura(response);
      } catch (error) {
        onError(null);
        let msgError = JSON.stringify(error.response.data);
        if (msgError.includes("Actualización de Factura de Boxer fallida")) {
          onSuccess(null);
          successNotification(
            "Factura generada con éxito, pero no se pudo actualizar la misma en Boxer. Comuníquese con soporte.",
            6000,
          );
        } else if (
          msgError.includes(
            "Errores encontrados en las siguientes devoluciones",
          )
        ) {
          let errorDevoluciones = getDataInfoErrorDevolucion(msgError);
          let errorDevolucionesARCA = errorDevoluciones.data.filter((f) =>
            f.mensajeError.includes("Error de ARCA"),
          );

          closeModalErrorAFIP(
            errorDevolucionesARCA,
            errorDevoluciones.idFactura,
            "devoluciones",
            "",
          );
          errorNotification(
            "Factura generada con éxito pero ocurrieron errores en las validaciones de AFIP de notas de crédito.",
            6000,
          );
        } else if (msgError.includes("Error al validar la factura")) {
          let errorValidacionAFIP = getMensajeErrorValidacionFactura(msgError);

          closeModalErrorAFIP([], null, "factura", errorValidacionAFIP);
          errorNotification(
            "Factura generada con éxito pero está pendiente de CAE ya que ocurrió el siguiente error en ARCA: " +
              errorValidacionAFIP,
            6000,
          );
        } else if (msgError.includes("Error afip")) {
          errorNotification(
            `ERROR DE AFIP: ${Object.values(error.response.data)}`,
            6000,
          );
        } else {
          errorNotification("No se puede registrar la factura.");
        }
        console.error(error);
      }
    }
    setOpenBackdrop && setOpenBackdrop(false);
  };

  const handleResponsePostFactura = (response) => {
    if (response.status === 201) {
      onSuccess(response.data.data);
      successNotification("Factura generada con exito");
    } else if (response.status === 500) {
      onError(null);
      errorNotification("No se pudo registar la factura");
    }
  };

  return { handlePostFactura };
};

export default useFacturar;
