import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from "@material-ui/core";
import PageTitle from "../../components/common/PageTitle";
import VentasSemanales from "./VentasSemanales";
import TopDiezProductos from "./TopDiezProductos";
import HorariosPopulares from "./HorariosPopulares";
import TopDiezDeudasProveedores from "./TopDiezDeudasProveedores";
import TopDiezDeudasClientes from "./TopDiezDeudasClientes";
import TopDiezVencimientos from "./TopDiezVencimientos";
import Montos from "./Montos/Montos";
import ReporteEmpleados from "./ReporteEmpleados";
import {
  FECHA_DESDE,
  FECHA_HASTA,
  FiltroFechas,
  textoDePeriodoSeleccionado,
  titlePrincipal,
  TituloCard,
} from "./utils";
import IngresosEgresos from "./IngresosEgresos";
import StatsVentas from "../../components/ait-reusable/StatsVentas";
import { useSelector } from "react-redux";
import { getData } from "../Informe/Ventas/utils";

const useStyles = makeStyles((theme) => ({
  paddingBetweenCards: {
    paddingTop: "1rem",
  },
  margin: {
    margin: "1rem",
  },
}));

export default function Dashboard() {
  const { configGeneral } = useSelector((state) => state.configGeneral);

  const [valueFecha, setValueFecha] = useState("esta_semana");
  const [totales, setTotales] = useState({});
  const [loadingTotales, setLoadingTotales] = useState(false);
  const [
    incluirTodasLasDevolucionesDePeriodo,
    setIncluirTodasLasDevolucionesDePeriodo,
  ] = useState(configGeneral.incluir_todas_devoluciones_de_periodo);

  const classes = useStyles();
  const valuesFechas = [
    {
      value: "esta_semana",
      name: "Esta semana",
    },
    {
      value: "semana_pasada",
      name: "Semana pasada",
    },
    {
      value: "este_mes",
      name: "Este mes",
    },
    {
      value: "mes_pasado",
      name: "Mes pasado",
    },
  ];

  useEffect(() => {
    const isChecked = 0;
    setLoadingTotales(true);
    getData(
      {
        fromDate: FECHA_DESDE(valueFecha),
        toDate: FECHA_HASTA(valueFecha),
      },
      "",
      "",
      "",
      "",
      // Si el usuario no tiene permisos para ver todas las ventas de todos los empleados
      // se setea su id para que solo pueda ver las suyas
      "",
      null,
      "",
      "",
      "",
      null,
      isChecked,
      incluirTodasLasDevolucionesDePeriodo,
      null,
      null,
    ).then((response) => {
      setTotales(response.totalesMedioPago.data.data);
      setLoadingTotales(false);
    });
  }, [valueFecha, incluirTodasLasDevolucionesDePeriodo]);

  return (
    <div className={classes.margin}>
      <Box mt={1}>
        <PageTitle title={titlePrincipal(valueFecha)} />
      </Box>
      <Grid container className={classes.paddingBetweenCards}>
        <FiltroFechas
          valuesFechas={valuesFechas}
          valueFecha={valueFecha}
          setValueFecha={setValueFecha}
        />
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "1em" }}>
        <Montos
          className={classes.paddingBetweenCards}
          valueFecha={valueFecha}
        />
        <Grid item xs={12} sm={12} lg={3} md={3}>
          <div>
            <Card>
              <CardHeader
                title={<TituloCard titulo="Medios de pago de Ventas" />}
                subheader={textoDePeriodoSeleccionado(valueFecha)}
              />
              <Divider />
              <CardContent>
                <StatsVentas
                  totales={totales}
                  isLoading={loadingTotales}
                  incluirTodasLasDevolucionesDePeriodo={
                    incluirTodasLasDevolucionesDePeriodo
                  }
                  setIncluirTodasLasDevolucionesDePeriodo={
                    setIncluirTodasLasDevolucionesDePeriodo
                  }
                />
              </CardContent>
            </Card>
          </div>
          <Grid item className={classes.paddingBetweenCards}>
            <TopDiezDeudasClientes />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={5} md={5}>
          <Grid item>
            <VentasSemanales />
          </Grid>
          <Grid item className={classes.paddingBetweenCards}>
            <HorariosPopulares valueFecha={valueFecha} />
          </Grid>
          <Grid item className={classes.paddingBetweenCards}>
            <TopDiezVencimientos valueFecha={valueFecha} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} md={4}>
          <Grid item>
            <IngresosEgresos
              valueFecha={valueFecha}
              ingresosTotales={totales}
              loadingIngresos={loadingTotales}
            />
          </Grid>
          <Grid item className={classes.paddingBetweenCards}>
            <TopDiezProductos valueFecha={valueFecha} />
          </Grid>
          <Grid item className={classes.paddingBetweenCards}>
            <ReporteEmpleados valueFecha={valueFecha} />
          </Grid>
          <Grid item className={classes.paddingBetweenCards}>
            <TopDiezDeudasProveedores />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
