import { View } from "@react-pdf/renderer";
import React from "react";
import { ETIQUETA_ESTILO, mostrarCodigosArticulo } from "./Design30x15";
import { mostrarDescripcionArticulo, styles } from "./utils";

export default function Label50x25({
  sucursal,
  articulo,
  configGeneral,
  configuracionEtiqueta,
}) {
  //1px = 0.36 mm
  return (
    <View
      style={{
        ...styles.contenedorTicket,
        width: 83.3,
        minWidth: 83.3,
        maxWidth: 83.3,
        height: 41.6,
        minHeight: 41.6,
        maxHeight: 41.6,
        padding: 5,
      }}
    >
      {/* CODIGOS ARTICULO */}
      {configuracionEtiqueta &&
        articulo &&
        mostrarCodigosArticulo(configuracionEtiqueta, articulo)}

      {/* DESCRIPCION */}
      {configuracionEtiqueta.descripcion && articulo
        ? mostrarDescripcionArticulo(
            articulo.descripcionProveedor,
            ETIQUETA_ESTILO.baseFontSize,
            ETIQUETA_ESTILO.maxCharBaseFontSize,
            ETIQUETA_ESTILO.baseMarginBottom,
          )
        : null}
    </View>
  );
}
