import { getHostClient } from "../../views/integraciones/mercadolibre/utils";

/** Types */
export const AGREGAR_DESCRIPCION = "@presupuesto/agregar-descripcion";
export const AGREGAR_ITEM = "@presupuesto/agregar-item";
export const ELIMINAR_ITEM = "@presupuesto/eliminar-item";
export const CAMBIAR_PRECIO = "@presupuesto/cambiar-precio";
export const CAMBIAR_CANTIDAD = "@presupuesto/cambiar-cantidad";
export const CAMBIAR_LISTA_PRECIOS = "@presupuesto/cambiar-lista-precios";
export const ACTUALIZAR_CLIENTE = "@presupuesto/actualizar-cliente";
export const ACTUALIZAR_COMPROBANTE = "@presupuesto/actualizar-comprobante";
export const ACTUALIZAR_PROVINCIA = "@presupuesto/actualizar-provincia";
export const ACTUALIZAR_MEDIO_PAGO = "@presupuesto/actualizar-medio-pago";
export const ACTUALIZAR_TARJETA = "@presupuesto/actualizar-tarjeta";
export const ACTUALIZAR_COEFICIENTE = "@presuspuesto/actualizar-coeficiente";
export const ACTUALIZAR_DESCUENTO = "@presupuesto/actualizar-descuento";
export const LIMPIAR_PRESUPUESTO = "@presupuesto/limpiar-presupuesto";
export const CARGAR_PRESUPUESTO = "@presupuesto/cargar-presupuesto";
export const CAMBIAR_PRECIO_LISTA = "@presupuesto/cambiar-precio-lista";
export const SET_DEFAULTS = "@presupuesto/set-defaults";
export const SET_DEFAULT_REDONDEO = "@presupuesto/set-default-redondeo";
export const ACTUALIZAR_REDONDEO = "@presupuesto/actualizar-redondeo";
export const ELIMINAR_MEDIO_PAGO = "@presupuesto/eliminar-medio-pago";
export const SET_DEFAULT_STOCK_RESERVA =
  "@presupuesto/set-default-stock-reserva";
export const ACTUALIZAR_CONFG_STOCK_RESERVA =
  "@presupuesto/actualizar-confg-stock-reserva";
export const SET_DEFAULT_DIAS_VIGENCIA =
  "@presupuesto/set-default-dias-vigencia";
export const ACTUALIZAR_DIAS_VIGENCIA = "@presupuesto/actualizar-dias-vigencia";
export const ACTUALIZAR_MULTIPLE_MEDIO =
  "@presupuesto/actualizar-multiple-medio";
export const ACTUALIZAR_TRANSFERENCIA = "@presupuesto/actualizar-transferencia";
export const ACTUALIZAR_MONTO_CON_INTERES =
  "@presupuesto/actualizar-monto-con-interes";
export const ACTUALIZAR_CHEQUE = "@presupuesto/actualizar-cheque";
export const ACTUALIZAR_REFERENCIA_TARJETA =
  "@presupuesto/actualizar-referencia-tarjeta";
export const ACTUALIZAR_PRECIOS_CON_RECARGO =
  "@presupuesto/actualizar-precios-con-recargo";
export const ACTUALIZAR_PRECIOS_POR_CAMBIO_MEDIO =
  "@presupuesto/actualizar-precios-por-cambio-medio";
export const ACTUALIZAR_TIPO_TRANSACCION =
  "@presupuesto/actualizar-tipo-transaccion";
export const ACTUALIZAR_CLIENTE_PRESUPUESTO =
  "@presupuesto/actualizar-cliente-presupuesto";
export const UPDATE_LISTA_IMPUESTOS = "@presupuesto/actualizar-lista-impuestos";
export const UPDATE_DATA_CTA_CTE_CLIENTE =
  "@presupuesto/update-data-cta-cte-cliente";
export const UPDATE_VALIDACION_IMPUESTOS_PRESUPUESTO =
  "@presupuesto/update-validacion-impuestos-presupuesto";
export const UPDATE_DESCRIPCION_PERSONALIZADA_DETALLE =
  "@presupuesto/cambiar-descripcion-detalle";
export const ACTUALIZAR_OBSERVACIONES = "@presupuesto/actualizar-observaciones";

/**Funciones */
/**Funcion para modificar el precio de un articulo contado o lista*/
export const cambiarPrecioLista = (
  detalles,
  isPrecioLista,
  roundPrices,
  descuento,
  medioPago,
  montoRedondeo,
  clienteDescuento,
) => {
  let nuevosDetalles = [];

  detalles.forEach((detalle) => {
    if (detalle.precioLista) {
      let precio = isPrecioLista ? detalle.precioLista : detalle.precioVenta;
      let priceFinal = getPrecioFinal(
        precio,
        roundPrices,
        montoRedondeo,
        clienteDescuento,
        medioPago,
      );

      let nuevo = {
        ...detalle,
        precio: priceFinal,
        subtotal: Number((priceFinal * detalle.cantidad).toFixed(2)),
      };
      nuevosDetalles.push(nuevo);
    } else {
      //Son artículos con descripción
      nuevosDetalles.push(detalle);
    }
  });
  localStorage.setItem("isPrecioLista", isPrecioLista);

  let montos = calculoMontos(nuevosDetalles, descuento, medioPago);

  let copiaMedio = addTotalAmount(medioPago, montos);
  return {
    type: CAMBIAR_PRECIO_LISTA,
    payload: { isPrecioLista, nuevosDetalles, montos, copiaMedio },
  };
};

/**Funcion para redondear o no los precios de los articulos cuando se cambia la configuración*/
export const redondearPrecios = (
  detalles,
  roundPrices,
  isPrecioLista,
  descuento,
  medioPago,
  montoRedondeo,
  clienteDescuento,
) => {
  let nuevosDetalles = detalles.slice();
  let isCtaCte =
    medioPago[0] &&
    medioPago[0].nombre &&
    medioPago[0].nombre === "Cuenta Corriente"
      ? true
      : false;

  if (roundPrices) {
    nuevosDetalles.forEach((detalle) => {
      let precioFirst = isPrecioLista
        ? detalle.precioLista
        : detalle.precioVenta;

      let newPrecio = precioFirst
        ? precioFirst
        : clienteDescuento.isClienteDescuento && isCtaCte
          ? (
              Number(Number(detalle.precio).toFixed(2)) /
              (1 + parseFloat(clienteDescuento.recargoLarger.porcentaje))
            ).toFixed(2)
          : detalle.precio;

      let priceFinal = getPrecioFinal(
        newPrecio,
        roundPrices,
        montoRedondeo,
        clienteDescuento,
        medioPago,
      );
      detalle.precio = priceFinal;
      detalle.subtotal = priceFinal * detalle.cantidad;
    });
  } else {
    nuevosDetalles.forEach((detalle) => {
      let newPrecio = isPrecioLista ? detalle.precioLista : detalle.precioVenta;
      if (newPrecio) {
        let priceFinal = getPrecioFinal(
          newPrecio,
          roundPrices,
          montoRedondeo,
          clienteDescuento,
          medioPago,
        );
        detalle.precio = priceFinal;
        detalle.subtotal = Number(
          parseFloat(Number(priceFinal * detalle.cantidad)).toFixed(2),
        );
      }
    });
  }
  // localStorage.setItem("round_prices", roundPrices);
  let newMedio = medioPago.length === 0 ? getMedioDefault() : medioPago;

  let montos = calculoMontos(nuevosDetalles, descuento, newMedio);

  let copiaMedio = addTotalAmount(medioPago, montos);
  return {
    type: ACTUALIZAR_REDONDEO,
    payload: { roundPrices, nuevosDetalles, montos, copiaMedio },
  };
};

// export const reservarStockPresupuesto = stockReserva => {
//   localStorage.setItem("stock_reserva", stockReserva);
//   return {
//     type: ACTUALIZAR_CONFG_STOCK_RESERVA,
//     payload: stockReserva
//   };
// };

export const setDefaultRedondeo = (roundPrices) => {
  return {
    type: SET_DEFAULT_REDONDEO,
    payload: roundPrices,
  };
};

// export const setDefaultStockReserva = () => {
//   const stockReserva = localStorage.getItem("stock_reserva") === "true";
//   return {
//     type: SET_DEFAULT_STOCK_RESERVA,
//     payload: stockReserva
//   };
// };

export const setDefaultDiasVigencia = (fechaVigenciasDias) => {
  return {
    type: SET_DEFAULT_DIAS_VIGENCIA,
    payload: fechaVigenciasDias,
  };
};

// export const cambiarCantDiasVigencia = fechaVigenciasDias => {
//   localStorage.setItem("fechaVigenciasDias", fechaVigenciasDias);
//   return {
//     type: ACTUALIZAR_DIAS_VIGENCIA,
//     payload: fechaVigenciasDias
//   };
// };

export const setDefaults = () => {
  return {
    type: SET_DEFAULTS,
    payload: false,
  };
};

/**Funcion que agregar una descripcion al carrito del la venta */
export const agregarDescripcion = (
  detalles,
  nuevaDescripcion,
  roundPrices,
  descuento,
  medioPago,
  montoRedondeo,
  clienteDescuento,
) => {
  let priceFinal = getPrecioFinal(
    nuevaDescripcion.precio,
    roundPrices,
    montoRedondeo,
    clienteDescuento,
    medioPago,
  );
  nuevaDescripcion.precio = priceFinal;
  let copiaDetalle = [...detalles];
  let atributosFijos = {
    IVA: 0.21,
    codProveedor: null,
    idRepuestoSucursal: null,
    idRepuestoProveedor: null,
    idIva: 1,
    precioCosto: "",
    precioValido: true,
    precioValidoVenta: true,
    cantidadValida: true,
  };
  let descripcion = {
    ...nuevaDescripcion,
    ...atributosFijos,
    subtotal: parseFloat(
      Number(priceFinal) * Number(nuevaDescripcion.cantidad),
    ),
  };
  copiaDetalle.push(descripcion);

  let montos = calculoMontos(copiaDetalle, descuento, medioPago);

  let copiaMedio = addTotalAmount(medioPago, montos);

  return {
    type: AGREGAR_DESCRIPCION,
    payload: { copiaDetalle, montos, copiaMedio },
  };
};

/** Funcion que agreagar un nuevo item o articulo al carrito de la venta,
 * busca el item en el array, si lo encuentra actualiza la cantidad y el subtotal
 * del item.
 * Si el item no existe, lo agrega al array
 */
export const agregarItem = (
  detalles,
  item,
  isPrecioLista = false,
  addFromCatalogo,
  roundPrices,
  descuento,
  medioPago,
  montoRedondeo,
  clienteDescuento,
) => {
  let copiaDetalle = [...detalles];
  // Buscar el index del objecto, segun su idRepuestoProveedor, retorna -1 si no existe
  let index = copiaDetalle.findIndex(
    (element) => element.idRepuestoProveedor === item.idRepuestoProveedor,
  );
  if (index >= 0) {
    let newCant = addFromCatalogo
      ? parseInt(item.cantidad)
      : copiaDetalle[index].cantidad + 1;
    let newPrecio = addFromCatalogo
      ? Number(parseFloat(Number(item.precio)).toFixed(2))
      : copiaDetalle[index].precio;

    let priceFinal = getPrecioFinal(
      newPrecio,
      roundPrices,
      montoRedondeo,
      clienteDescuento,
      medioPago,
    );

    copiaDetalle[index].precio = priceFinal;
    copiaDetalle[index].cantidad = newCant;
    copiaDetalle[index].subtotal = priceFinal * newCant;
    copiaDetalle[index].montoIva = Number(
      parseFloat(Number(copiaDetalle[index].subtotal) * 0.21).toFixed(2),
    );
  }

  if (index === -1) {
    let newCant = addFromCatalogo ? item.cantidad : 1;
    let newPrecio = addFromCatalogo
      ? item.precio
      : isPrecioLista
        ? item.precios.lista
        : item.precios.venta;

    let priceFinal = getPrecioFinal(
      newPrecio,
      roundPrices,
      montoRedondeo,
      clienteDescuento,
      medioPago,
    );
    let selectedListaPrecio;
    if (item.idListaPrecio) {
      selectedListaPrecio = item.idListaPrecio;
    } else if (item.lista_precios) {
      let selectedLista = item.lista_precios.find(
        (i) => i.es_default && i.is_active,
      );
      if (selectedLista) {
        selectedListaPrecio = selectedLista.id;
      }
    }

    copiaDetalle.push({
      idRepuestoProveedor: item.idRepuestoProveedor,
      precioLista: item.precios.lista,
      precioVenta: item.precios.venta,
      precio: priceFinal,
      descripcion: item.descripcionProveedor,
      codProveedor: item.codProveedor,
      codAuxiliar: item.codAuxiliar,
      codOriginal: item.codOriginal,
      cantidad: newCant,
      iva: 0.21,
      idIva: 1,
      codigo_afip: 3,
      precioCosto: item.precios.costo,
      subtotal: priceFinal * newCant,
      precioValido: true,
      precioValidoVenta: true,
      cantidadValida: true,
      repuesto_sucursal: item.repuesto_sucursal || null,
      observaciones: item.observaciones || [],
      proveedor: item.proveedor || null,
      fechaUltimaActualizacion: item.fecha_ultima_actualizacion,
      tipo_descuento: item.tipo_descuento,
      identificador_proveedor: item.identificador_proveedor
        ? item.identificador_proveedor
        : null,
      rubro: item.rubro || null,
      sub_rubro: item.sub_rubro || null,
      lista_precios: item.lista_precios,
      idListaPrecio: parseInt(selectedListaPrecio),
      idProveedor: item.idProveedor || null,
      precios: item.precios || {},
    });
  }
  let newMedio = medioPago.length === 0 ? getMedioDefault() : medioPago;

  let montos = calculoMontos(copiaDetalle, descuento, newMedio);

  let copiaMedio = addTotalAmount(medioPago, montos);

  return {
    type: AGREGAR_ITEM,
    payload: { copiaDetalle, montos, copiaMedio },
  };
};

/**Funcion encargada de eliminar un objeto del array de detalles de la venta,
 * realiza un filtro de todos aquellos objetos que no tengan el index pasado por parámetro
 */
export const eliminarItem = (detalles, index, descuento, medioPago) => {
  let copiaDetalle = [...detalles].filter((value, i, array) => index !== i);

  let montos = calculoMontos(copiaDetalle, descuento, medioPago);

  let copiaMedio = addTotalAmount(medioPago, montos);
  return {
    type: ELIMINAR_ITEM,
    payload: { copiaDetalle, montos, copiaMedio },
  };
};

/**Funcion encargada de reliza el cambio de precio en el item,
 * si el precio es menos al precio de costo o a 0, se actualizan el campo
 * si el precio es menos al precio de venta o a 0, se actualizan el campo
 * precioValido para notifica el error
 * precioValidoVenta para notificar el error
 */
export const cambiarPrecio = (detalles, index, event, descuento, medioPago) => {
  const copiaDetalle = [...detalles];
  let repuesto = copiaDetalle[index];
  repuesto.precio = event.target.value !== "" ? Number(event.target.value) : 0;
  repuesto.subtotal = Number(
    parseFloat(repuesto.precio * repuesto.cantidad).toFixed(2),
  );
  repuesto.montoIva = Number(
    (parseFloat(repuesto.subtotal) * repuesto.iva).toFixed(2),
  );

  repuesto.precios || repuesto.precioCosto
    ? (repuesto.precioValido = repuesto.precioCosto
        ? parseFloat(repuesto.precio) >= parseFloat(repuesto.precioCosto)
        : parseFloat(repuesto.precio) >= parseFloat(repuesto.precios.costo))
    : (repuesto.precioValido = parseFloat(repuesto.precio) >= 0);

  repuesto.precios || repuesto.precioVenta
    ? (repuesto.precioValidoVenta = repuesto.precioVenta
        ? parseFloat(repuesto.precio) >= parseFloat(repuesto.precioVenta)
        : parseFloat(repuesto.precio) >= parseFloat(repuesto.precios.venta))
    : (repuesto.precioValidoVenta = parseFloat(repuesto.precio) >= 0);

  copiaDetalle[index] = repuesto;
  let montos = calculoMontos(copiaDetalle, descuento, medioPago);
  let copiaMedio = addTotalAmount(medioPago, montos);

  return {
    type: CAMBIAR_PRECIO,
    payload: { copiaDetalle, montos, copiaMedio },
  };
};

/**Funcion encargada de cambiar los precios de un item del catalogo
 * cuando se cambia la Lista de Precios aplicada
 */
export const actualizarPrecio = (
  detalles,
  index,
  price,
  redondeo_precio,
  cliente,
  descuento,
  medioPago,
  listaPrecio,
) => {
  const copiaDetalle = [...detalles];
  let repuesto = copiaDetalle[index];

  repuesto.precio =
    price.venta !== ""
      ? Number(
          getPrecioFinal(
            price.venta,
            redondeo_precio ? true : false,
            redondeo_precio,
            cliente ? cliente.clienteDescuento : { isClienteDescuento: false },
            medioPago,
          ),
        )
      : 0;
  repuesto.subtotal = Number(
    parseFloat(repuesto.precio * repuesto.cantidad).toFixed(2),
  );
  repuesto.montoIva = Number(
    (parseFloat(repuesto.subtotal) * repuesto.iva).toFixed(2),
  );
  repuesto.precioCosto = price.costo;
  repuesto.precioVenta = price.venta;
  repuesto.precioLista = price.lista;
  repuesto.idListaPrecio = parseInt(listaPrecio);

  copiaDetalle[index] = repuesto;
  let montos = calculoMontos(copiaDetalle, descuento, medioPago);
  let copiaMedio = addTotalAmount(medioPago, montos);
  let idListaPrecio = repuesto.idListaPrecio;

  return {
    type: CAMBIAR_LISTA_PRECIOS,
    payload: { copiaDetalle, montos, copiaMedio, idListaPrecio },
  };
};

/**Funcion encargada de reliza el cambio de cantidad en el item,*
 */
export const cambiarCantidad = (
  detalles,
  index,
  event,
  descuento,
  medioPago,
) => {
  const copiaDetalle = [...detalles];
  let repuesto = copiaDetalle[index];
  repuesto.cantidad =
    event.target.value !== "" ? Number(event.target.value) : 1;
  repuesto.subtotal = Number(
    parseFloat(repuesto.precio * repuesto.cantidad).toFixed(2),
  );
  repuesto.montoIva = Number(
    (parseFloat(repuesto.subtotal) * repuesto.iva).toFixed(2),
  );
  repuesto.cantidadValida = repuesto.cantidad > 0 ? true : false;
  copiaDetalle[index] = repuesto;

  let montos = calculoMontos(copiaDetalle, descuento, medioPago);

  let copiaMedio = addTotalAmount(medioPago, montos);
  return {
    type: CAMBIAR_CANTIDAD,
    payload: { copiaDetalle, montos, copiaMedio },
  };
};

/**Funcion encargada de cambiar la descripción de un item del carrito de la venta */
export const updateDescripcionPersonalizadaDetalle = (
  detalles,
  index,
  descripcion,
) => {
  let repuesto = detalles[index];

  repuesto.descripcionPersonalizada = descripcion;
  detalles[index] = repuesto;

  return {
    type: UPDATE_DESCRIPCION_PERSONALIZADA_DETALLE,
    payload: detalles,
  };
};

/** Funcion encargada de actualizar el tipo de transacción */
export const updateTypeTransaction = (type) => {
  return {
    type: ACTUALIZAR_TIPO_TRANSACCION,
    payload: type,
  };
};

/** Funcion encargada de actualizar el cliente de el presupuesto */
export const updateCliente = (cliente) => {
  return {
    type: ACTUALIZAR_CLIENTE,
    payload: cliente,
  };
};

/**Funcion encargada de actualizar el comprobrante seleciconado */
export const updateComprobante = (comprobante) => {
  return {
    type: ACTUALIZAR_COMPROBANTE,
    payload: comprobante,
  };
};

/** Funcion encargada de actualizar la variable isMultipleMedioPago del presupuesto */
export const updateMultipleMedioPago = (valor) => {
  return {
    type: ACTUALIZAR_MULTIPLE_MEDIO,
    payload: valor,
  };
};

/**Funcion encargada de actualizar la provincia de la venta */
export const updateProvincia = (provincia) => {
  return {
    type: ACTUALIZAR_PROVINCIA,
    payload: provincia,
  };
};

/**Función encargada de agregar un nuevo medio de pago */
export const addNewMedio = (
  medioPagoSelect,
  montos,
  medioPago,
  newMonto,
  isMultipleMedio,
  reset,
) => {
  let copiaMedios = reset === undefined ? [...medioPago] : [];
  let lengthArray = copiaMedios.length;

  copiaMedios.push({
    ...medioPagoSelect,
    idMedio: copiaMedios.length + 1,
    coeficiente: {
      nroLote: "",
      nroAut: "",
      id_coeficiente_interes: null,
      porcentaje: 0,
      cantidad_cuotas: "",
      fecha_actualizacion: "",
      referencia: null,
    },
    cheque: {
      numero_cheque: null,
      tipo: null,
      emision: null,
      vencimiento: null,
      cobro: null,
      monto: null,
      propio: null,
      referencia: null,
    },
    tarjeta: null,
    transferencia: {
      banco: null,
      referencia: null,
      fecha_transferencia: null,
    },
    monto: newMonto !== null ? newMonto : montos.subtotal,
  });

  let newMontos = newCalculoInteresTotal(copiaMedios, montos, isMultipleMedio);

  copiaMedios.map((medio) => {
    if (medio.idMedio === lengthArray + 1) {
      medio.monto = newMonto !== null ? newMonto : newMontos.montoTotal;
    }
  });
  return {
    type: ACTUALIZAR_MEDIO_PAGO,
    payload: { copiaMedios, newMontos },
  };
};

/**Funcion encargada de actualizar el medio de pago seleciconado - ( el reducer actualiza a null el campo tarjeta y el campo coeficiente) */
export const updateMedioPago = (
  medioPagoSelect,
  montos,
  medioPago,
  newMonto,
  idSelected,
  isMultipleMedio,
) => {
  let copiaMedios = [...medioPago];
  copiaMedios.forEach((medio) => {
    if (medio.idMedio === idSelected) {
      medio.tipo = medioPagoSelect && medioPagoSelect.tipo;
      medio.idMedioPago = medioPagoSelect && medioPagoSelect.idMedioPago;
      medio.nombre = medioPagoSelect && medioPagoSelect.nombre;
      medio.tarjeta = null;
      medio.coeficiente = {
        nroLote: "",
        nroAut: "",
        id_coeficiente_interes: null,
        porcentaje: 0,
        cantidad_cuotas: "",
        fecha_actualizacion: "",
        referencia: null,
      };
      medio.cheque = {
        numero_cheque: null,
        tipo: null,
        emision: null,
        vencimiento: null,
        cobro: null,
        monto: null,
        propio: null,
        referencia: null,
        modalidad: null,
      };
      medio.transferencia = {
        banco: null,
        referencia: null,
        fecha_transferencia: null,
      };
      medio.monto = newMonto !== null ? newMonto : montos.subtotal;
    }
  });

  let newMontos = newCalculoInteresTotal(copiaMedios, montos, isMultipleMedio);

  copiaMedios.map((medio) => {
    if (medio.idMedio === idSelected) {
      //Esta validación de si es null es porque esta función se utiliza en multiples medios y en un medio de pago.
      //Cuando es un medio de pago el monto del medio tiene que ser el monto total
      medio.monto = newMonto !== null ? newMonto : newMontos.montoTotal;
    }
  });

  return {
    type: ACTUALIZAR_MEDIO_PAGO,
    payload: { copiaMedios, newMontos },
  };
};

/**Función que elimina uno o todos los medios de pago */
export const deleteMedioPago = (medioPago, montos, index, isMultipleMedio) => {
  let copiaMedios = [];

  if (index !== null) {
    copiaMedios = [...medioPago].filter((value, i, array) => index !== i);
  } else {
    copiaMedios = [
      {
        idMedioPago: 2,
        nombre: "Efectivo",
        tipo: "Simple",
        idMedio: 1,
        coeficiente: {
          nroLote: "",
          nroAut: "",
          id_coeficiente_interes: null,
          porcentaje: 0,
          cantidad_cuotas: "",
          fecha_actualizacion: "",
          referencia: null,
        },
        cheque: {
          numero_cheque: null,
          tipo: null,
          emision: null,
          vencimiento: null,
          cobro: null,
          monto: null,
          propio: null,
          referencia: null,
          modalidad: null,
        },
        tarjeta: null,
        transferencia: {
          banco: null,
          referencia: null,
          fecha_transferencia: null,
        },
        monto: montos.subtotal,
      },
    ];
  }

  let newMontos = newCalculoInteresTotal(copiaMedios, montos, isMultipleMedio);

  if (index === null) {
    copiaMedios[0].monto = newMontos.montoTotal;
  }

  return {
    type: ELIMINAR_MEDIO_PAGO,
    payload: { copiaMedios, newMontos },
  };
};

/**Función que modifica el monto para el medio de pago seleccionado */
export const updateMontoMedio = (
  newMonto,
  idSelected,
  medioPago,
  montos,
  isMultipleMedio,
) => {
  let copiaMedios = [...medioPago];

  copiaMedios.forEach((medio) => {
    if (medio.idMedio === idSelected) {
      medio.monto = newMonto;
    }
  });

  let newMontos = newCalculoInteresTotal(copiaMedios, montos, isMultipleMedio);

  return {
    type: ACTUALIZAR_MEDIO_PAGO,
    payload: { copiaMedios, newMontos },
  };
};

/**Función que setea el monto de los medios de pago con tarjeta con el monto con interes*/
export const updateMontoConInteres = (medioPago) => {
  let copiaMedios = [...medioPago];

  copiaMedios.forEach((medio) => {
    if (medio.coeficiente.id_coeficiente_interes) {
      let newMonto = Number(
        medio.monto * (1 + Number(medio.coeficiente.porcentaje)),
      ).toFixed(2);

      medio.monto = Number(newMonto);
    }
  });

  return {
    type: ACTUALIZAR_MONTO_CON_INTERES,
    payload: copiaMedios,
  };
};

/**Función que setea la fecha del día actual cuando se selecciona el medio transferencia */
export const updateFechaTransferencia = (medioPago, idSelected, dateToday) => {
  let copiaMedios = [...medioPago];

  copiaMedios.forEach((medio) => {
    if (medio.idMedio === idSelected) {
      medio.transferencia.fecha_transferencia = dateToday;
    }
  });

  return {
    type: ACTUALIZAR_TRANSFERENCIA,
    payload: copiaMedios,
  };
};

/**Función que setea los datos de la transferencia */
export const updateDataTransferencia = (medioPago, idSelected, name, value) => {
  let copiaMedios = [...medioPago];

  copiaMedios.forEach((medio) => {
    if (medio.idMedio === idSelected) {
      medio.transferencia[name] = value;
    }
  });

  return {
    type: ACTUALIZAR_TRANSFERENCIA,
    payload: copiaMedios,
  };
};

/**Función que setea la fecha del día actual (emision y vencimiento) cuando se selecciona el medio cheque */
export const updateFechasCheque = (medioPago, idSelected, dateToday) => {
  let copiaMedios = [...medioPago];

  copiaMedios.forEach((medio) => {
    if (medio.idMedio === idSelected) {
      medio.cheque.emision =
        medio.cheque.emision === null ? dateToday : medio.cheque.emision;
      medio.cheque.vencimiento =
        medio.cheque.vencimiento === null
          ? dateToday
          : medio.cheque.vencimiento;
    }
  });

  return {
    type: ACTUALIZAR_CHEQUE,
    payload: copiaMedios,
  };
};

/**Función que setea los datos del cheque */
export const updateDataCheque = (medioPago, idSelected, name, value) => {
  let copiaMedios = [...medioPago];

  copiaMedios.forEach((medio) => {
    if (medio.idMedio === idSelected) {
      medio.cheque[name] = value;
    }
  });

  return {
    type: ACTUALIZAR_CHEQUE,
    payload: copiaMedios,
  };
};

/**Función que modifica el nro de lote y autorización para el medio de pago seleccionado */
export const updateNrosTarjetaMedio = (
  value,
  idSelected,
  medioPago,
  montos,
  name,
) => {
  let copiaMedios = [...medioPago];

  copiaMedios.forEach((medio) => {
    if (medio.idMedio === idSelected) {
      if (name === "nroLote") {
        medio.coeficiente.nroLote = value;
      } else {
        medio.coeficiente.nroAut = value;
      }
    }
  });

  let newMontos = montos;

  return {
    type: ACTUALIZAR_MEDIO_PAGO,
    payload: { copiaMedios, newMontos },
  };
};

/** Funcion encargada de actualizar la tarjeta seleccionada en el medio seleccionado*/
export const updateTarjeta = (
  tarjeta,
  montos,
  medioPago,
  idSelected,
  isMultipleMedio,
) => {
  let copiaMedios = [...medioPago];

  copiaMedios.forEach((medio) => {
    if (medio.idMedio === idSelected) {
      medio.tarjeta = tarjeta;
    }
  });

  let newMontos = newCalculoInteresTotal(copiaMedios, montos, isMultipleMedio);

  return {
    type: ACTUALIZAR_TARJETA,
    payload: { copiaMedios, newMontos },
  };
};

export const updateCoeficiente = (
  coeficiente,
  montos,
  medioPago,
  idSelected,
  isMultipleMedio,
) => {
  let copiaMedios = [...medioPago];

  copiaMedios.forEach((medio) => {
    if (medio.idMedio === idSelected) {
      medio.coeficiente.id_coeficiente_interes = coeficiente
        ? coeficiente.id_coeficiente_interes
        : null;
      medio.coeficiente.porcentaje = coeficiente ? coeficiente.porcentaje : 0;
      medio.coeficiente.cantidad_cuotas = coeficiente
        ? coeficiente.cantidad_cuotas
        : "";
      medio.coeficiente.fecha_actualizacion = coeficiente
        ? coeficiente.fecha_actualizacion
        : "";
    }
  });

  let newMontos = newCalculoInteresTotal(copiaMedios, montos, isMultipleMedio);

  if (!isMultipleMedio) {
    copiaMedios[0].monto = newMontos.montoTotal;
  }

  return {
    type: ACTUALIZAR_COEFICIENTE,
    payload: { copiaMedios, newMontos },
  };
};

export const updateReferenciaTarjeta = (medioPago, idSelected, value) => {
  let copiaMedios = [...medioPago];

  copiaMedios.forEach((medio) => {
    if (medio.idMedio === idSelected) {
      medio.coeficiente.referencia = value;
    }
  });

  return {
    type: ACTUALIZAR_REFERENCIA_TARJETA,
    payload: copiaMedios,
  };
};

export const updateDescuento = (descuento, montos, medioPago) => {
  let copiaMedios = medioPago.length > 0 ? [...medioPago] : getMedioDefault();

  //Suma subtotal e interes y obtiene el monto del descuento
  const newDescuentoMonto = descuento
    ? montos.subtotal * Number(descuento.porcentaje)
    : 0;

  // Obtiene el interes del subtotal anterior
  const newInteres =
    medioPago.length > 0 && medioPago[0].coeficiente.id_coeficiente_interes
      ? Number(
          (montos.subtotal - newDescuentoMonto) *
            Number(medioPago[0].coeficiente.porcentaje),
        )
      : 0;

  const newMontoTotal =
    Number(montos.subtotal.toFixed(2)) +
    Number(newInteres.toFixed(2)) -
    Number(newDescuentoMonto.toFixed(2));

  montos.descuentoMonto = newDescuentoMonto;
  montos.interes = Number(newInteres.toFixed(2));
  montos.montoTotal = Number(newMontoTotal.toFixed(2));

  copiaMedios[0].monto = newMontoTotal;

  return {
    type: ACTUALIZAR_DESCUENTO,
    payload: { descuento, montos, copiaMedios },
  };
};

export const clearPresupuesto = (configGeneral, deleteMedioPago) => {
  const provincia = localStorage.getItem("provinciaDefault");
  const estadoTributario = localStorage.getItem("estadoTributario");
  return {
    type: LIMPIAR_PRESUPUESTO,
    payload: {
      id_presupuesto: null,
      detalles: [],
      montos: {
        subtotal: 0,
        descuentoMonto: 0,
        interes: 0,
        montoTotal: 0,
      },
      cliente: null,
      descripcion: "",
      observacion: "",
      observacion_oculta: "",
      responsable: null,
      provincia: provincia || null,
      tipoComprobante: {
        idTipoFactura:
          configGeneral.type_receipt === 1
            ? estadoTributario !== "EX" && estadoTributario !== "MO"
              ? 2
              : 1
            : null,
        idTipoComprobante: configGeneral.type_receipt === 1 ? 1 : 2,
        nombre:
          configGeneral.type_receipt === 2
            ? "Comprobante interno"
            : estadoTributario !== "EX" && estadoTributario !== "MO"
              ? "B"
              : "C",
      },
      medioPago: [
        {
          idMedioPago: deleteMedioPago ? null : 2,
          nombre: deleteMedioPago ? "" : "Efectivo",
          idMedio: 1,
          coeficiente: {
            nroLote: "",
            nroAut: "",
            id_coeficiente_interes: null,
            porcentaje: 0,
            cantidad_cuotas: "",
            fecha_actualizacion: "",
            referencia: null,
          },
          cheque: {
            numero_cheque: null,
            tipo: null,
            emision: null,
            vencimiento: null,
            cobro: null,
            monto: null,
            propio: null,
            referencia: null,
            modalidad: null,
          },
          tarjeta: null,
          transferencia: {
            banco: null,
            referencia: null,
            fecha_transferencia: null,
          },
          monto: 0,
        },
      ],
      descuento: null,
      dataOrdenMeli: null,
      idOrdenMeli: null,
      isOrdenMeli: false,
      recrear_venta_multiple_medios_pagos: false,
      recrear_venta_medio_pago_tarjeta_sin_coeficiente: false,
      recrear_venta_medio_pago_tarjeta_con_cambios: false,
      tipo_venta: null,
      fecha_vencimiento: null,
      limpiarMedioPago: true,
    },
  };
};

/**
 * La función "cargarPresupuesto" procesa y prepara un presupuesto para acciones posteriores, como
 * redondear precios, validar precios, manejar métodos de pago y calcular totales.
 * :return: La función `cargarPresupuesto` devuelve un objeto con una propiedad `type` establecida en
 * `CARGAR_PRESUPUESTO` y una propiedad `payload` que contiene el objeto presupuesto analizado.
 */
export const cargarPresupuesto = (
  presupuesto,
  roundPrecios,
  montoRedondeo,
  cliente,
) => {
  const { detalles } = presupuesto;
  const detalllesParsed = [];

  detalles.forEach((detalle) => {
    let precioValido;
    if (detalle.precios) {
      precioValido = detalle.precio >= Number(detalle.precios.costo);
    } else {
      precioValido = true;
    }
    let precioValidoVenta;
    if (detalle.precios) {
      precioValidoVenta = detalle.precio >= Number(detalle.precios.venta);
    } else {
      precioValidoVenta = true;
    }

    let selectedListaPrecio;
    if (detalle.idListaPrecio) {
      selectedListaPrecio = detalle.idListaPrecio;
    } else if (detalle.lista_precios) {
      selectedListaPrecio = detalle.lista_precios.find(
        (i) => i.es_default && i.is_active,
      ).id;
    }

    let obj = {
      ...detalle,
      precio: roundPrecios
        ? Math.ceil(detalle.precio / montoRedondeo) * montoRedondeo
        : detalle.precio,
      subtotal: roundPrecios
        ? Math.ceil(detalle.precio / montoRedondeo) *
          montoRedondeo *
          detalle.cantidad
        : Number(parseFloat(detalle.subtotal).toFixed(2)),
      cantidadValida: detalle.cantidad > 0 ? true : false,
      precioValido: precioValido,
      precioValidoVenta: precioValidoVenta,
      observaciones: detalle.observaciones,
      idListaPrecio: parseInt(selectedListaPrecio),
    };

    detalllesParsed.push(obj);
  });
  let newMedio = getMedioDefault();
  presupuesto.recrear_venta_multiple_medios_pagos = false;
  // Si se recrea la venta y la cantidad de los medios de pago es igual a 1
  // se reemplaza por el default
  if (presupuesto.medios_pago && presupuesto.medios_pago.length === 1) {
    // Si el medio de pago es tarjeta de crédito o débito
    if (
      presupuesto.medios_pago[0].tipo === "Tarjeta" &&
      presupuesto.medios_pago[0].coeficiente.id_coeficiente_interes === null
    ) {
      // key para identificar desde la pantalla de ventas si tiene o no coeficiente, (algunos medios de pago de tarjeta no tienen coeficiente relacionado)
      presupuesto.recrear_venta_medio_pago_tarjeta_sin_coeficiente = true;
      // Si el coeficiente de la tarjeta sufrio cambios, tambien se agrega una nueva key para identificar desde la pantalla de ventas
    } else {
      presupuesto.recrear_venta_medio_pago_tarjeta_sin_coeficiente = false;
      newMedio = presupuesto.medios_pago;
    }

    if (presupuesto.medios_pago[0].tiene_cambios) {
      presupuesto.recrear_venta_medio_pago_tarjeta_con_cambios = true;
    } else {
      presupuesto.recrear_venta_medio_pago_tarjeta_con_cambios = false;
    }
  }

  // [23-05-2023] Si al recrear la venta, la venta tiene más de 1 medio de pago
  // se agrega campo recrear_venta_multiple_medios_pagos = True para alerta en pantalla de ventas
  // No se cargan los medios de pago en caso de multiple (manejo de interfaz)
  if (presupuesto.medios_pago && presupuesto.medios_pago.length > 1) {
    presupuesto.recrear_venta_multiple_medios_pagos = true;
    presupuesto.recrear_venta_medio_pago_tarjeta_sin_coeficiente = false;
    presupuesto.recrear_venta_medio_pago_tarjeta_con_cambios = false;
  }

  // Manejo de medios de pago desde un presupuesto a venta
  if (!presupuesto.medios_pago) {
    if (presupuesto.cliente && presupuesto.cliente.id_medio_pago) {
      newMedio[0].idMedioPago = cliente.id_medio_pago.idMedioPago;
      newMedio[0].nombre = cliente.id_medio_pago.nombre;
      newMedio[0].tipo = cliente.id_medio_pago.tipo;
    } else {
      newMedio = getMedioDefault();
    }
  }

  let newMontos = calculoMontos(
    detalllesParsed,
    presupuesto.descuento,
    newMedio,
  );

  let newImpuestosPresupuestoCreado = presupuesto.impuestos_aplicados;

  delete presupuesto.coeficiente;
  delete presupuesto.medios_pago;
  delete presupuesto.tarjeta;
  delete presupuesto.impuestos_aplicados;
  delete presupuesto.montoTotal;
  delete presupuesto.montoImporte;
  delete presupuesto.montoDescuento;
  delete presupuesto.montoInteres;
  delete presupuesto.montoSubtotal;
  delete presupuesto.montoTotalImpuestos;

  newMedio[0].monto = newMontos.montoTotal;
  presupuesto.montos = newMontos;
  presupuesto.medioPago = newMedio;
  presupuesto.cliente = cliente;

  const provinciaDefault = localStorage.getItem("provinciaDefault");
  let parsedPresupuesto = {
    ...presupuesto,
    detalles: detalllesParsed,
    provincia: presupuesto.provincia
      ? presupuesto.provincia
      : presupuesto.cliente
        ? presupuesto.cliente.idCliente.provincia
        : provinciaDefault
          ? provinciaDefault
          : 1,
    impuestosPresupuestoCreado: newImpuestosPresupuestoCreado,
    impuestosValidados: false,
  };

  return {
    type: CARGAR_PRESUPUESTO,
    payload: { ...parsedPresupuesto },
  };
};

export const cargarPresupuestoMeli = (
  orden,
  data,
  roundPrecios,
  montoRedondeo,
  configGeneral,
) => {
  const { order_items } = orden;
  const estadoTributario = localStorage.getItem("estadoTributario");
  const detallesParsed = [];
  const arrayOrdenPaymentsFiltered = orden.payments.filter(
    (p) => p.status !== "cancelled" && p.status !== "rejected",
  );
  const lastObjectArrayOrdenPaymentsFiltered =
    arrayOrdenPaymentsFiltered[arrayOrdenPaymentsFiltered.length - 1];

  const totalPaidAmount = arrayOrdenPaymentsFiltered.reduce((acc, value) => {
    return acc + value.total_paid_amount;
  }, 0);
  order_items.forEach((detalle) => {
    let descripcion = detalle.item.title;
    if (detalle.repuestos && detalle.repuestos.length > 0) {
      descripcion = "Articulos de " + detalle.item.title;
    }
    let obj = {
      ...detalle,
      precio: roundPrecios
        ? Math.ceil(detalle.unit_price / montoRedondeo) * montoRedondeo
        : detalle.unit_price,
      subtotal: roundPrecios
        ? Math.ceil(detalle.unit_price / montoRedondeo) *
          montoRedondeo *
          detalle.quantity
        : Number(parseFloat(detalle.unit_price * detalle.quantity).toFixed(2)),
      cantidadValida: detalle.quantity > 0 ? true : false,
      cantidad: detalle.quantity,
      //descripcion: detalle.item.title,
      descripcion: descripcion,
      descripcionPersonalizada: "",
      precioValido: true,
      precioValidoVenta: true,
      observaciones: "",
      idIva: 1,
      idRepuestoProveedor:
        detalle.repuestos && detalle.repuestos.length > 0
          ? detalle.repuestos[0].id
          : null,
    };
    detallesParsed.push(obj);
  });
  let newMedio = getMedioDefault();

  let newMontos = calculoMontos(detallesParsed, false, newMedio);
  const dataOrdenMeli = [
    {
      client_url: getHostClient(),
      total_paid_amount: totalPaidAmount,
      date_created: lastObjectArrayOrdenPaymentsFiltered.date_created,
      date_approved: lastObjectArrayOrdenPaymentsFiltered.date_approved,
      authorization_code:
        lastObjectArrayOrdenPaymentsFiltered.authorization_code,
      pack_id: orden.pack_id ? orden.pack_id : null,
      buyer_doc_type:
        orden.buyer && orden.buyer.buyer_doc_type
          ? orden.buyer.buyer_doc_type
          : null,
      buyer_doc_number:
        orden.buyer && orden.buyer.buyer_doc_number
          ? orden.buyer.buyer_doc_number
          : null,
      buyer_nickname:
        orden.buyer && orden.buyer.nickname ? orden.buyer.nickname : null,
      total_amount: orden.total_amount ? orden.total_amount : null,
      payments: arrayOrdenPaymentsFiltered,
    },
  ];
  const presupuesto = {
    tipoComprobante: {
      idTipoFactura: configGeneral.type_receipt === 1 ? 2 : null,
      idTipoComprobante: configGeneral.type_receipt === 1 ? 1 : 2,
      nombre:
        configGeneral.type_receipt === 2
          ? "Comprobante interno"
          : estadoTributario === "RI"
            ? "B"
            : "C",
    },
    descuento: null,
    cliente: null,
    sucursal: null,
    fechaHoraVenta: orden.date_created,
    medioPago: newMedio,
    montos: newMontos,
    isOrdenMeli: true,
    dataOrdenMeli: dataOrdenMeli,
    idOrdenMeli: orden.id,
  };
  let parsedPresupuesto = { ...presupuesto, detalles: detallesParsed };

  return {
    type: CARGAR_PRESUPUESTO,
    payload: { ...parsedPresupuesto },
  };
};

export const updatePriceWithRecargo = (
  clienteDescuento,
  clientPrevious,
  detalles,
  descuento,
  medioPago,
) => {
  let copiaDetalle = detalles.slice();
  let copiaMedio = medioPago.slice();

  copiaDetalle.forEach((detalle) => {
    let newPrice = detalle.precio;
    if (clientPrevious.isClienteDescuento) {
      //Saca el recargo del cliente anterior
      newPrice = Number(
        (
          Number(Number(detalle.precio).toFixed(2)) /
          (1 + parseFloat(clientPrevious.recargoLarger.porcentaje))
        ).toFixed(2),
      );

      if (clienteDescuento.isClienteDescuento) {
        //Aplica el recargo del nuevo cliente
        newPrice = Number(
          (
            Number(Number(newPrice).toFixed(2)) *
            (1 + parseFloat(clienteDescuento.recargoLarger.porcentaje))
          ).toFixed(2),
        );
      }
    } else {
      if (clienteDescuento.isClienteDescuento) {
        //Aplica el recargo del nuevo cliente
        newPrice = Number(
          (
            Number(Number(detalle.precio).toFixed(2)) *
            (1 + parseFloat(clienteDescuento.recargoLarger.porcentaje))
          ).toFixed(2),
        );
      }
    }

    detalle.precio = newPrice;
    detalle.subtotal = Number((newPrice * detalle.cantidad).toFixed(2));
  });

  let montos = calculoMontos(copiaDetalle, descuento, medioPago);
  copiaMedio[0].monto = montos.montoTotal;

  return {
    type: ACTUALIZAR_PRECIOS_CON_RECARGO,
    payload: { copiaDetalle, montos, copiaMedio, descuento },
  };
};

export const updatePriceChangeMedio = (
  clienteDescuento,
  detalles,
  medioPago,
  isRecargo,
) => {
  let copiaDetalle = detalles.slice();
  let newDescuento = null;

  copiaDetalle.forEach((detalle) => {
    detalle.precio = isRecargo
      ? Number(
          (
            Number(Number(detalle.precio).toFixed(2)) *
            (1 + parseFloat(clienteDescuento.recargoLarger.porcentaje))
          ).toFixed(2),
        )
      : Number(
          (
            Number(Number(detalle.precio).toFixed(2)) /
            (1 + parseFloat(clienteDescuento.recargoLarger.porcentaje))
          ).toFixed(2),
        );

    detalle.subtotal = Number((detalle.precio * detalle.cantidad).toFixed(2));
  });

  let montos = calculoMontos(copiaDetalle, null, medioPago);

  let copiaMedio = addTotalAmount(medioPago, montos);

  return {
    type: ACTUALIZAR_PRECIOS_POR_CAMBIO_MEDIO,
    payload: { copiaDetalle, montos, copiaMedio, newDescuento },
  };
};

export const updateClientePresupuesto = (
  cliente,
  detalles,
  medioPago,
  montos,
  tipo,
) => {
  let copiaDetalle = detalles.slice();
  let copiaMedio = medioPago.slice();
  let newMontos = montos;

  if (
    cliente.clienteDescuento.isClienteDescuento &&
    medioPago[0].nombre === "Cuenta Corriente"
  ) {
    //Saco recargos
    copiaDetalle.forEach((detalle) => {
      detalle.precio = Number(
        (
          Number(Number(detalle.precio).toFixed(2)) /
          (1 + parseFloat(cliente.clienteDescuento.recargoLarger.porcentaje))
        ).toFixed(2),
      );

      detalle.subtotal = Number((detalle.precio * detalle.cantidad).toFixed(2));
    });

    newMontos = calculoMontos(copiaDetalle, null, medioPago);

    copiaMedio = addTotalAmount(medioPago, newMontos);
  }

  return {
    type: ACTUALIZAR_CLIENTE_PRESUPUESTO,
    payload: { copiaDetalle, newMontos, copiaMedio, tipo },
  };
};

//Función que calcula los montos del carrito
const calculoMontos = (detalles, descuento, medioPago) => {
  //Calcula el subtotal de los detalles
  const subtotal = detalles.reduce((total, item) => item.subtotal + total, 0);

  //Suma subtotal e interes y obtiene el monto del descuento
  const descuentoMonto = descuento
    ? subtotal * Number(descuento.porcentaje)
    : 0;

  // Calcula el interes
  const interes = Number(
    (medioPago[0].coeficiente.id_coeficiente_interes !== null
      ? parseFloat(
          (subtotal - descuentoMonto) * medioPago[0].coeficiente.porcentaje,
        )
      : 0
    ).toFixed(2),
  );

  //Suma y resta para obtener el monto real
  const montoTotal = Number(
    (
      Number(subtotal.toFixed(2)) +
      Number(interes.toFixed(2)) -
      Number(descuentoMonto.toFixed(2))
    ).toFixed(2),
  );

  return { subtotal, descuentoMonto, interes, montoTotal };
};

//Función que agrega monto total al medio de pago cuando se ingresan artículos.
//Retorna la copia del medio de pago con el monto seteado
const addTotalAmount = (medioPago, montos) => {
  let copiaMedio = [...medioPago];

  if (copiaMedio.length === 1) {
    copiaMedio[0].monto = montos.montoTotal;
  }

  return copiaMedio;
};

const newCalculoInteresTotal = (copiaMedios, montos, isMultipleMedio) => {
  let copiaMontos = montos;
  let interes = 0;
  let has_interes = false;

  if (isMultipleMedio) {
    copiaMedios.forEach((medio) => {
      has_interes = medio.coeficiente.id_coeficiente_interes !== null;

      interes += has_interes
        ? Number(Number(medio.coeficiente.porcentaje * medio.monto).toFixed(2))
        : 0;
    });
  } else {
    interes =
      copiaMedios[0].coeficiente.id_coeficiente_interes === null
        ? 0
        : parseFloat(
            copiaMedios[0].coeficiente.porcentaje *
              (montos.subtotal - montos.descuentoMonto),
          );
  }

  copiaMontos.montoTotal = Number(
    (
      Number(montos.subtotal.toFixed(2)) -
      Number(montos.descuentoMonto.toFixed(2)) +
      Number(Number(interes).toFixed(2))
    ).toFixed(2),
  );

  copiaMontos.interes = Number(Number(interes).toFixed(2));
  return copiaMontos;
};

const getMedioDefault = () => {
  return [
    {
      idMedioPago: 2,
      nombre: "Efectivo",
      tipo: "Simple",
      idMedio: 1,
      coeficiente: {
        nroLote: "",
        nroAut: "",
        id_coeficiente_interes: null,
        porcentaje: 0,
        cantidad_cuotas: "",
        fecha_actualizacion: "",
        referencia: null,
      },
      cheque: {
        numero_cheque: null,
        tipo: null,
        emision: null,
        vencimiento: null,
        cobro: null,
        monto: null,
        propio: null,
        referencia: null,
        modalidad: null,
      },
      tarjeta: null,
      transferencia: {
        banco: null,
        referencia: null,
        fecha_transferencia: null,
      },
      monto: 0,
    },
  ];
};

//Función que calcula el precio final de un item
const getPrecioFinal = (
  price,
  roundPrices,
  montoRedondeo,
  clienteDescuento,
  medioPago,
) => {
  let isCtaCte =
    medioPago[0] &&
    medioPago[0].nombre &&
    medioPago[0].nombre === "Cuenta Corriente"
      ? true
      : false;

  let newPrecioRedondeado = roundPrices
    ? Math.ceil(price / montoRedondeo) * montoRedondeo
    : price;

  let newPrecioRecargo =
    clienteDescuento.isClienteDescuento && isCtaCte
      ? Number(
          (
            Number(Number(newPrecioRedondeado).toFixed(2)) *
            (1 + parseFloat(clienteDescuento.recargoLarger.porcentaje))
          ).toFixed(2),
        )
      : newPrecioRedondeado;

  return newPrecioRecargo;
};

export const updateListaImpuestos = (impuestos) => {
  return {
    type: UPDATE_LISTA_IMPUESTOS,
    payload: impuestos,
  };
};

export const updateDataCtaCteCliente = (cliente) => {
  return {
    type: UPDATE_DATA_CTA_CTE_CLIENTE,
    payload: { cliente },
  };
};

export const updateValidacionImpuestosPresupuesto = (value) => {
  return {
    type: UPDATE_VALIDACION_IMPUESTOS_PRESUPUESTO,
    payload: { value },
  };
};

export const actualizarObservaciones = (detalles, articulo, observaciones) => {
  const copiaDetalle = [...detalles];

  let index = copiaDetalle.findIndex(
    (element) => element.idRepuestoProveedor === articulo.idRepuestoProveedor,
  );

  copiaDetalle[index].observaciones = observaciones;

  return {
    type: ACTUALIZAR_OBSERVACIONES,
    payload: copiaDetalle,
  };
};
