import { View } from "@react-pdf/renderer";
import React from "react";
import { ETIQUETA_ESTILO, mostrarCodigosArticulo } from "./Design42x29";
import {
  mostrarDescripcionArticulo,
  mostrarLogoSucursal,
  styles,
  viewCodBarra,
} from "./utils";

export default function Label42x29({
  sucursal,
  articulo,
  configGeneral,
  configuracionEtiqueta,
}) {
  //1px = 0.36 mm
  return (
    <View
      style={{
        ...styles.contenedorTicket,
        width: 116.6,
        minWidth: 116.6,
        maxWidth: 116.6,
        height: 80.5,
        minHeight: 80.5,
        maxHeight: 80.5,
        padding: 7,
      }}
    >
      {/* CODIGOS ARTICULO */}
      {configuracionEtiqueta &&
        articulo &&
        mostrarCodigosArticulo(configuracionEtiqueta, articulo)}

      {/* DESCRIPCION */}
      {configuracionEtiqueta && configuracionEtiqueta.descripcion && articulo
        ? mostrarDescripcionArticulo(
            articulo.descripcionProveedor,
            ETIQUETA_ESTILO.baseFontSize,
            ETIQUETA_ESTILO.maxCharBaseFontSize,
            ETIQUETA_ESTILO.baseMarginBottom,
          )
        : null}

      {/* CODIGO BARRA + LOGO */}
      <View style={styles.viewCodeBar}>
        {configuracionEtiqueta && configuracionEtiqueta.logotipo
          ? mostrarLogoSucursal(configGeneral.url_imagen)
          : null}

        {configuracionEtiqueta
          ? viewCodBarra(
              configuracionEtiqueta,
              articulo,
              Boolean(configuracionEtiqueta.logotipo),
            )
          : null}
      </View>
    </View>
  );
}
