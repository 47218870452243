import React from "react";
import { useSupportData } from "../../../../../cuentas/componentes/FormularioMovimiento/hooks";
import { Box, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useStore from "./store";

function SelectCuentas() {
  const cuentas = useStore((state) => state.cuentas);

  const setCuentas = useStore((state) => state.setCuentas);

  const [__, listadoCuentas] = useSupportData();

  const handleSelectAll = () => {
    setCuentas(listadoCuentas.data);
  };

  const handleSelect = (event, value) => {
    setCuentas(value);
  };

  return (
    <Box className="py-2" width="100%">
      <Autocomplete
        fullWidth
        multiple
        id="tags-outlined"
        options={listadoCuentas.data}
        getOptionLabel={(options) => options.nombre}
        onSelect={() => {}}
        onChange={handleSelect}
        value={cuentas}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            name="cuentas"
            variant="outlined"
            label="Cuentas a ver"
            style={{ backgroundColor: "white" }}
            //error={formik.touched.cuentas && Boolean(formik.errors.cuentas)}
          />
        )}
        size="small"
        name="buscador_cuentas"
      />
      <Box display="flex" justifyContent="flex-start">
        <Typography
          className="pt-2"
          fontSize={6}
          fontWeight={700}
          onClick={handleSelectAll}
          style={{
            color: "#3f51b5",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          Agregar todas
        </Typography>
      </Box>
    </Box>
  );
}

export default SelectCuentas;
