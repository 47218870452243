import { firstColunmWidth } from "../../utils";

export const columnsCtacte = [
  {
    width: 80,
    text: "FECHA",
  },
  {
    width: 30,
    text: "TIPO",
  },
  {
    width: 140,
    text: "N° COMPROBANTE",
  },
  {
    width: 80,
    text: "DEBE",
  },
  {
    width: 80,
    text: "HABER",
  },
];

export const columnsFacturasAdeudadas = [
  {
    width: 75,
    text: "FECHA",
  },
  {
    width: 30,
    text: "TIPO",
  },
  {
    width: 150,
    text: "N° COMPROBANTE",
  },
  {
    width: 80,
    text: "MONTO",
  },
  {
    width: 80,
    text: "MONTO ADEUDADO",
  },
];

export const columnsPresupuestoA4 = (config) => {
  return [
    {
      width: firstColunmWidth(config),
      text: "Descripción",
      textAlign: "left",
    },
    {
      width: "5%",
      text: "Cant",
      textAlign: "center",
    },
    ...(config.incluir_precio_costo_presupuesto
      ? [
          {
            width: "10%",
            text: "Precio Costo",
            textAlign: "center",
          },
        ]
      : []),
    ...(config.incluir_precio_lista_presupuesto
      ? [
          {
            width: "10%",
            text: "Precio Lista",
            textAlign: "center",
          },
        ]
      : []),
    ...(config.incluir_precio_venta_presupuesto
      ? [
          {
            width: "10%",
            text: "Precio Unit.",
            textAlign: "center",
          },
        ]
      : []),
    ...(config.incluir_lista_precios_presupuesto
      ? [
          {
            width: "10%",
            text: "L. de Precios",
            textAlign: "center",
          },
        ]
      : []),
    {
      width: "10%",
      text: "Subtotal",
      textAlign: "right",
    },
  ];
};

export const columnsPresupuesto = (config) => {
  return [
    ...(config.incluir_lista_precios_venta
      ? [
          {
            width: 50,
            text: "L. de Precios",
            textAlign: "left",
          },
        ]
      : []),

    {
      width: 350,
      text: "Descripción",
      textAlign: "left",
    },
    {
      width: 25,
      text: "Cant",
      textAlign: "center",
    },
    {
      width: 50,
      text: "Precio Unit.",
      textAlign: "center",
    },
    {
      width: 50,
      text: "Subtotal",
      textAlign: "right",
    },
  ];
};

export const columnsVenta = [
  {
    width: 350,
    text: "Descripción",
    textAlign: "left",
  },
  {
    width: 25,
    text: "Cant",
    textAlign: "center",
  },
  {
    width: 50,
    text: "Precio Unit.",
    textAlign: "center",
  },
  {
    width: 50,
    text: "Subtotal",
    textAlign: "right",
  },
];

export const columnsVentaConIva = [
  {
    width: 350,
    text: "Descripción",
    textAlign: "left",
  },
  {
    width: 25,
    text: "Cant",
    textAlign: "center",
  },
  {
    width: 50,
    text: "Precio Unit.",
    textAlign: "center",
  },
  {
    width: 50,
    text: "IVA",
    textAlign: "center",
  },

  {
    width: 50,
    text: "Subtotal",
    textAlign: "right",
  },
];
export const columnsPagos = [
  {
    width: 250,
    text: "Descripción",
  },
  {
    width: 30,
    text: "Cant",
  },
  {
    width: 80,
    text: "Precio Unit.",
  },
  {
    width: 60,
    text: "Subtotal",
  },
];
export const columnsNCMontosConIva = [
  {
    width: 270,
    text: "Descripción",
    textAlign: "left",
  },
  {
    width: 80,
    text: "Precio Unit.",
    textAlign: "center",
  },
  {
    width: 80,
    text: "IVA",
    textAlign: "center",
  },
  {
    width: 80,
    text: "Subtotal",
    textAlign: "right",
  },
];
export const columnsNCMontos = [
  {
    width: 310,
    text: "Descripción",
    textAlign: "left",
  },
  {
    width: 100,
    text: "Precio Unit.",
    textAlign: "center",
  },
  {
    width: 100,
    text: "Subtotal",
    textAlign: "right",
  },
];

export const columnsRemito = [
  {
    width: 450,
    text: "Descripción",
    textAlign: "left",
  },
  {
    width: 65,
    text: "Cantidad",
    textAlign: "center",
  },
];
