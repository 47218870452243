import {
  Card,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function CardConfig({ item }) {
  const [open, setOpen] = useState(false);
  const { perfil } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Vendedor" || perfil === "Empleado" ? true : false;

  const toggle = () => setOpen(!open);

  return (
    <Card>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem button onClick={toggle}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {!isVendedor && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem>{item.component}</ListItem>
            </List>
          </Collapse>
        )}
      </List>
    </Card>
  );
}
