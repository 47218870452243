import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { LineA4Table, LineA4TableWhite } from "../../Components/raya";
import { formatCurrency } from "../../../../../utils/parsers";
import { firstColunmWidth, truncateText } from "../../utils";

export default function BodyTablePresupuesto({
  descripcion,
  cant,
  precioVenta,
  precioCosto,
  precioLista,
  listaPrecios,
  subtotal,
  noLine,
  config,
}) {
  return (
    <View style={{ paddingTop: 5 }}>
      <View style={styles.containerRowBodyTable}>
        <View
          style={[styles.containerColumn, { width: firstColunmWidth(config) }]}
        >
          <Text style={styles.textLeftTable}>{descripcion || " --- "}</Text>
        </View>
        <View style={[styles.containerColumn, { width: "5%" }]}>
          <Text style={styles.textCenterTable}>{cant}</Text>
        </View>

        {config.incluir_precio_costo_presupuesto ? (
          <View style={[styles.containerColumn, { width: "10%" }]}>
            <Text style={styles.textCenterTable}>
              {formatCurrency(precioCosto)}
            </Text>
          </View>
        ) : null}

        {config.incluir_precio_lista_presupuesto ? (
          <View style={[styles.containerColumn, { width: "10%" }]}>
            <Text style={styles.textCenterTable}>
              {formatCurrency(precioLista)}
            </Text>
          </View>
        ) : null}

        {config.incluir_precio_venta_presupuesto ? (
          <View style={[styles.containerColumn, { width: "10%" }]}>
            <Text style={styles.textCenterTable}>
              {formatCurrency(precioVenta)}
            </Text>
          </View>
        ) : null}

        {config.incluir_lista_precios_presupuesto ? (
          <View style={[styles.containerColumn, { width: "10%" }]}>
            <Text style={styles.textCenterTable}>
              {truncateText(listaPrecios)}
            </Text>
          </View>
        ) : null}

        <View style={[styles.containerColumn2, { width: "10%" }]}>
          <Text style={styles.textRightTable}>{formatCurrency(subtotal)}</Text>
        </View>
      </View>
      <View style={{ marginTop: -15 }}>
        {noLine ? <LineA4TableWhite /> : <LineA4Table />}
      </View>
    </View>
  );
}
