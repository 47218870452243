import { Text, View } from "@react-pdf/renderer";
import React from "react";

export const ETIQUETA_ESTILO = {
  baseFontSize: 8,
  midFontSize: 6,
  minFontSize: 4,
  maxCharBaseFontSize: 27,
  baseMarginBottom: 4,
};

export const mostrarCodigosArticulo = (configuracionEtiqueta, articulo) => {
  const codigos = [];
  if (configuracionEtiqueta.codigoProveedor && articulo.codProveedor) {
    codigos.push(articulo.codProveedor);
  }
  if (configuracionEtiqueta.codigoOriginal && articulo.codOriginal) {
    codigos.push(articulo.codOriginal);
  }
  if (configuracionEtiqueta.codigoAuxiliar && articulo.codAuxiliar) {
    codigos.push(articulo.codAuxiliar);
  }

  const fontSize = ETIQUETA_ESTILO.baseFontSize + 2;

  return (
    <View
      style={{
        width: "100%",
        flexDirection: "column",
        marginBottom: ETIQUETA_ESTILO.baseMarginBottom,
      }}
    >
      {codigos.map((codigo, index) => (
        <View
          key={index}
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 2,
          }}
        >
          <Text
            style={{
              fontSize: fontSize,
              fontWeight: "bold",
            }}
          >
            {codigo}
          </Text>
        </View>
      ))}
    </View>
  );
};
