import { Document, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import HeaderA4 from "./componentes/HeaderA4";
import { LineA4Color } from "../Components/raya";
import DataClientA4 from "./componentes/ClienteA4";
import InformacionConciliacion from "./componentes/InformacionConciliacion";
import HeaderTable from "../Components/TableGeneric/HeaderTable";
import BodyTableConciliacionVentas from "./componentes/BodyTable";
import { styles } from "../assets/css/styleA4";
import TotalesFA4 from "./componentes/TotalesA4";
import FooterA4 from "./componentes/FooterA4";
import moment from "moment";

export default function ConciliacionVentasA4({ data }) {
  const tableColumns = [
    {
      width: 102,
      text: "Comprobante",
      textAlign: "left",
    },
    {
      width: 102,
      text: "Pago N°",
      textAlign: "center",
    },
    {
      width: 102,
      text: "Observaciones de venta",
      textAlign: "center",
    },
    {
      width: 102,
      text: "Importe total",
      textAlign: "center",
    },
    {
      width: 102,
      text: "Importe saldado",
      textAlign: "right",
    },
  ];

  const razonSocial = data.cliente_sucursal.razon_social
    ? data.cliente_sucursal.razon_social
    : data.sucursal.razonSocial;

  return (
    <Document
      title={`Conciliación N° ${data.id} - ${moment(data.fecha_hora).format("DD/MM/YYYY")} - ${razonSocial ? razonSocial : "---"}`}
    >
      <Page
        style={{
          paddingBottom: 200,
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        size={"A4"}
      >
        <HeaderA4 conciliacion={data} />
        <LineA4Color />
        <DataClientA4 conciliacion={data} />
        <LineA4Color />
        <InformacionConciliacion conciliacion={data} />
        <HeaderTable columns={tableColumns} />
        {data.ventas_saldadas.map((venta, index) => (
          <BodyTableConciliacionVentas key={index} venta={venta} />
        ))}
        <TotalesFA4 conciliacion={data} />
        <View
          style={{
            position: "absolute",
            bottom: 100,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <LineA4Color />
        </View>
        <FooterA4 conciliacion={data} />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
