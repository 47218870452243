import { Text, View } from "@react-pdf/renderer";
import React from "react";
import { mostrarDivisorDeCodigos } from "./utils";

export const ETIQUETA_ESTILO = {
  baseFontSize: 10,
  midFontSize: 8,
  minFontSize: 6,
  maxCharBaseFontSize: 33,
  baseMarginBottom: 4,
};

export const mostrarCodigosArticulo = (configuracionEtiqueta, articulo) => {
  const codigos = [];
  if (configuracionEtiqueta.codigoProveedor && articulo.codProveedor) {
    codigos.push(articulo.codProveedor);
  }
  if (configuracionEtiqueta.codigoOriginal && articulo.codOriginal) {
    codigos.push(articulo.codOriginal);
  }
  if (configuracionEtiqueta.codigoAuxiliar && articulo.codAuxiliar) {
    codigos.push(articulo.codAuxiliar);
  }

  const fontSize =
    codigos.length > 1
      ? ETIQUETA_ESTILO.baseFontSize
      : ETIQUETA_ESTILO.baseFontSize + 8;

  const format = codigos.length > 1 ? "space-between" : "center";

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: format,
        marginBottom: ETIQUETA_ESTILO.baseMarginBottom,
      }}
    >
      {codigos.map((codigo, index) => (
        <React.Fragment key={index}>
          <Text
            style={{
              fontSize: fontSize,
              fontWeight: "bold",
            }}
          >
            {codigo}
          </Text>
          {index < codigos.length - 1 &&
            mostrarDivisorDeCodigos(ETIQUETA_ESTILO.baseFontSize)}
        </React.Fragment>
      ))}
    </View>
  );
};
