import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CargaArticulos from "./BuscadorArticulo";
import { Box } from "@material-ui/core";
import ListaArticulos from "./ListaArticulos";
import FormArticuloSelected from "./FormArticuloSelected";
import {
  addArticulo,
  removeArticuloFromCart,
  cambiarCantidadArticulo,
  cambiarPreciosCostoArticulo,
} from "../../../../Redux/Actions/pedidoActions";

export default function AddProducto({
  setLoading,
  vattTypes,
  dataPedido,
  idPedido,
}) {
  const { pedidos } = useSelector((state) => state.pedido);
  const [articulosFiltrados, setArticulosFiltrados] = useState([]);
  const dispatch = useDispatch();
  const [articuloSelected, setArticuloSelected] = useState(null);

  const handleChangeCantidad = (event, index) => {
    dispatch(cambiarCantidadArticulo(event.target.value, index, pedidos));
  };

  const handleChangePreciosCosto = (event, index) => {
    dispatch(cambiarPreciosCostoArticulo(event.target.value, index, pedidos));
  };

  const addDetalle = (articulo) => {
    dispatch(addArticulo(pedidos, articulo));
    setArticuloSelected(null);
  };

  const removeArticulo = (articulo, index) => {
    dispatch(removeArticuloFromCart(pedidos, { articulo, index }));
  };

  return (
    <div>
      <Box mb={2}>
        <CargaArticulos
          selected={(newValue) => setArticuloSelected(newValue)}
          setLoading={setLoading}
        />
      </Box>
      <FormArticuloSelected
        articulo={articuloSelected}
        addDetalle={addDetalle}
        vattTypes={vattTypes}
      />
      <ListaArticulos
        articulos={pedidos}
        remove={removeArticulo}
        handleChangeCantidad={handleChangeCantidad}
        vattTypes={vattTypes}
        handleChangePreciosCosto={handleChangePreciosCosto}
        dataPedido={dataPedido}
        articulosFiltrados={articulosFiltrados}
        setArticulosFiltrados={setArticulosFiltrados}
        idPedido={idPedido}
      />
    </div>
  );
}
