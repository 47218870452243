import { StyleSheet, Font } from "@react-pdf/renderer";
import BoldFont from "../styles/Roboto-Bold.ttf";
Font.register({
  family: "BoldFont",
  src: BoldFont,
});

export const styles = StyleSheet.create({
  page: {
    paddingLeft: 5,
    paddingRight: 13,
    paddingBottom: 10,
    paddingTop: 10,
  },
  containerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    paddingHorizontal: 10,
  },
  containerRowTable: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: "bolder",
  },
  containerRowBilletera: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 10,
  },
  containerColumn: {
    flexDirection: "column",
  },
  containerColumn2: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
  container: {
    textAlign: "center",
    backgroundColor: "#f6f6f5",
    display: "flex",
    flexDirection: "row",
    padding: 2,
  },
  text: {
    paddingBottom: 3,
    fontSize: 8,
    textAlign: "justify",
  },
  textFooter: {
    paddingBottom: 3,
    fontSize: 5,
    maxWidth: 110,
  },
  textbold: {
    paddingBottom: 2,
    fontSize: 9,
    // textAlign: "justify",
    fontWeight: "extrabold",
  },
  textDescription: {
    paddingBottom: 3,
    fontSize: 7,
    textAlign: "justify",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 50,
  },
  textDescriptionItem: {
    paddingBottom: 3,
    fontSize: 8,
  },
  textAditionalItem: {
    paddingBottom: 3,
    fontSize: 6,
  },
  textTotalesFactura: {
    paddingBottom: 3,
    fontSize: 9,
    textAlign: "justify",
  },
  textTotalesFacturaAlignLeft: {
    paddingBottom: 3,
    fontSize: 9,
    textAlign: "left",
  },
  boldTextRight: {
    paddingBottom: 3,
    fontSize: 8,
    textAlign: "right",
    fontFamily: "BoldFont",
  },
  boldTextLeft: {
    paddingBottom: 3,
    fontSize: 8,
    textAlign: "left",
    fontFamily: "BoldFont",
  },
});
