import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { parseCurrency } from "../../../../../utils/parsers";
import RegimenTransparenciaFiscalA4 from "../../Components/RegimenTransparenciaFiscalA4";

export default function TotalesFA4({ factura, vat, receipt, fixed }) {
  return (
    <View style={fixed ? styles.footerTotal : { fontSize: 10 }}>
      <View
        style={{
          marginRight: 20,
          marginLeft: 20,
          color: "#1B71BA",
          fontWeight: "bolder",
        }}
      >
        <View style={styles.containerColumn} />
        <View style={styles.containerColumn}>
          <View style={styles.containerRowTableEnd}>
            <View style={(styles.containerColumn, { width: 200 })}>
              <Text style={styles.textTotalesFactura}>
                {factura.idTipoFactura.nombre === "A" ||
                factura.idTipoFactura.nombre === "M"
                  ? "SUBTOTAL"
                  : "TOTAL"}
              </Text>

              <Text style={styles.textTotalesFactura}>
                {(factura.idTipoFactura.nombre === "A" ||
                  factura.idTipoFactura.nombre === "M") &&
                  `IVA ${"21%"}`}
              </Text>

              {(factura.idTipoFactura.nombre === "A" ||
                factura.idTipoFactura.nombre === "M") &&
                receipt.datos_afip.taxes.map((tax, index) => (
                  <Text key={index} style={styles.textTotalesFactura}>
                    {`${tax.tax_type.description.toUpperCase()} - ${Number(
                      tax.aliquot,
                    )}%`}
                  </Text>
                ))}

              {(factura.idTipoFactura.nombre === "A" ||
                factura.idTipoFactura.nombre === "M") && (
                <Text style={styles.textTotalesFactura}>TOTAL</Text>
              )}
            </View>
            <View style={(styles.containerColumn, { width: 70 })}>
              <Text style={styles.textTotalesFactura}>
                {factura.idTipoFactura.nombre === "A" ||
                factura.idTipoFactura.nombre === "M"
                  ? `${parseCurrency(Number(receipt.datos_afip.net_taxed))}`
                  : `${parseCurrency(Number(receipt.monto))}`}
              </Text>

              <Text style={styles.textTotalesFactura}>
                {(factura.idTipoFactura.nombre === "A" ||
                  factura.idTipoFactura.nombre === "M") &&
                  `${parseCurrency(Number(vat.amount))}`}
              </Text>

              {(factura.idTipoFactura.nombre === "A" ||
                factura.idTipoFactura.nombre === "M") &&
                receipt.datos_afip.taxes.map((tax, index) => (
                  <Text key={index} style={styles.textTotalesFactura}>
                    {parseCurrency(tax.amount)}
                  </Text>
                ))}

              {(factura.idTipoFactura.nombre === "A" ||
                factura.idTipoFactura.nombre === "M") && (
                <Text style={styles.textTotalesFactura}>
                  {`${parseCurrency(Number(receipt.datos_afip.total_amount))}`}
                </Text>
              )}
            </View>
          </View>
        </View>
      </View>
      {factura.idTipoFactura.nombre === "B" && (
        <View style={fixed ? { marginLeft: 20 } : {}}>
          <RegimenTransparenciaFiscalA4 ivaAmount={vat.amount} />
        </View>
      )}
    </View>
  );
}
