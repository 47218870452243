import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import FormularioClienteTercero from "./FormularioClienteTercero";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonGeneral from "../../../../components/ait-reusable/Button/ButtonGeneral";
import {
  facturarATerceros,
  listEstadoTributario,
  readComprobantes,
} from "../../../../requests/urls";
import request from "../../../../requests/request";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import ModalReutilizable from "../../../../components/ait-reusable/Modal";
import moment from "moment";
import useSearchClients from "../../../../customHooks/useSearchClients";
import ConfirmacionDataCliente from "./ConfirmacionDataCliente";
import useValidacionMensajeErrorAFIP from "../../../../customHooks/useValidacionMensajeErrorAFIP";

const ModalFacturarClienteTercero = ({
  open,
  ventaSelected,
  cerrarModal,
  montoMaximo,
  setOpenModalFacturarATerceros,
  setDataErrorAFIP,
}) => {
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [comprobantes, setComprobantes] = React.useState([]);
  const [estadosTributario, setEstadosTributario] = React.useState([]);
  const [estadoTributarioValue, setEstadoTributarioValue] =
    React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [comprobanteSelected, setComprobanteSelected] = React.useState(null);
  const [vencimientoDelPago, setVencimientoDelPago] = useState(new Date());
  const [opcionesTransferencia, setOpcionesTransferencia] = useState([]);
  const [isCuit, setCuit] = useState(true);
  const [clienteAfip, setClienteAfip] = useState(null);
  const [actionCargaCliente, setActionCargaCliente] = React.useState(0); //0 = Consulta de clientes - 1 = Carga de cliente
  const { useClient, useQuery } = useSearchClients();
  const [validateDateClient, setValidateDateClient] = useState(false);
  const [dataFacturacion, setDataFacturacion] = useState(null); // Sirve para guardar los datos de facturacion cuando se valida el cliente
  const { getMensajeErrorValidacionFactura, getDataInfoErrorDevolucion } =
    useValidacionMensajeErrorAFIP();

  const formRef = React.createRef();
  const formRefNuevo = React.createRef();
  React.useEffect(() => {
    handleGetComprobantes();
    getEstadosTributarios();
  }, []);

  const validacionDataCliente = async (values, _) => {
    //Si es una consulta de cliente y selecciono un cliente existente debe validar los datos
    if (actionCargaCliente === 0 && useClient.client) {
      setDataFacturacion(values);
      setValidateDateClient(true);
    } else {
      factuarATerceros(values, _);
    }
  };

  const factuarATerceros = async (values, _) => {
    let idEstTributario =
      actionCargaCliente === 1
        ? values.estadoTributario.idEstadoTributario
        : values.estado_tributario_id;
    let newComproSelected =
      actionCargaCliente === 1
        ? values.comprobanteSelected
        : comprobanteSelected;

    let data =
      newComproSelected.nombre === "FCEM A"
        ? {
            cuit: values.cuit,
            dni: values.dni,
            estado_tributario_id: idEstTributario,
            razon_social: values.razon_social,
            domicilio: values.domicilio,
            tipo_factura_id:
              actionCargaCliente === 1
                ? values.comprobanteSelected.idTipoFactura
                : comprobanteSelected.idTipoFactura,
            tipo_comprobante_id:
              actionCargaCliente === 1
                ? values.comprobanteSelected.idTipoComprobante
                : comprobanteSelected.idTipoComprobante,
            venta_id: ventaSelected.id || ventaSelected.idVenta,
            fecha_vencimiento: values.fecha_vencimiento,
            data_fcem: {
              fecha_vencimiento_pago: moment(
                actionCargaCliente === 1
                  ? values.vencimientoDelPago
                  : vencimientoDelPago,
              ).format("YYYY-MM-DD"),
              cbu: values.cbu,
              alias: values.alias,
              opcion_transferencia: values.opcion_transferencia,
            },
            id_client_update:
              actionCargaCliente === 1
                ? null
                : useClient.client
                  ? useClient.client.cliente.domicilio !== values.domicilio ||
                    useClient.client.cliente.estadoTributario
                      .idEstadoTributario !== idEstTributario
                    ? useClient.client.idClienteDeSucursal
                    : null
                  : null,
            new_client: actionCargaCliente === 1 ? true : false,
          }
        : {
            cuit: values.cuit,
            dni: values.dni,
            estado_tributario_id: idEstTributario,
            razon_social: values.razon_social,
            domicilio: values.domicilio,
            tipo_factura_id:
              actionCargaCliente === 1
                ? values.comprobanteSelected.idTipoFactura
                : comprobanteSelected.idTipoFactura,
            tipo_comprobante_id:
              actionCargaCliente === 1
                ? values.comprobanteSelected.idTipoComprobante
                : comprobanteSelected.idTipoComprobante,
            venta_id: ventaSelected.id || ventaSelected.idVenta,
            fecha_vencimiento: values.fecha_vencimiento,
            data_fcem: null,
            id_client_update:
              actionCargaCliente === 1
                ? null
                : useClient.client
                  ? useClient.client.cliente.domicilio !== values.domicilio ||
                    useClient.client.cliente.estadoTributario
                      .idEstadoTributario !== idEstTributario
                    ? useClient.client.idClienteDeSucursal
                    : null
                  : null,
            new_client: actionCargaCliente === 1 ? true : false,
          };
    values.dni = values.dni === "" ? null : values.dni;

    try {
      setLoading(true);
      setOpenBackdrop(true);

      const response = await request({
        method: "POST",
        url: facturarATerceros,
        data: data,
      });
      cerrarModal();
      successNotification("La venta se facturó correctamente");
      setOpenBackdrop(false);
      setLoading(false);
    } catch (error) {
      let msgError = JSON.stringify(error.response.data);
      if (msgError.includes("Actualización de Factura de Boxer fallida")) {
        successNotification(
          "Factura a tercero generada con éxito, pero no se pudo actualizar la misma en Boxer. Comuníquese con soporte.",
          6000,
        );
        cerrarModal();
      } else if (
        msgError.includes("Errores encontrados en las siguientes devoluciones")
      ) {
        let errorDevoluciones = getDataInfoErrorDevolucion(msgError);
        let errorDevolucionesARCA = errorDevoluciones.data.filter((f) =>
          f.mensajeError.includes("Error de ARCA"),
        );

        closeModalErrorAFIP(
          errorDevolucionesARCA,
          errorDevoluciones.idFactura,
          "devoluciones",
          "",
        );
        errorNotification(
          "Factura a tercero generada con éxito pero ocurrieron errores en las validaciones de AFIP de notas de crédito.",
          6000,
        );
      } else if (msgError.includes("Error al validar la factura")) {
        let errorValidacionAFIP = getMensajeErrorValidacionFactura(msgError);

        closeModalErrorAFIP([], null, "factura", errorValidacionAFIP);
        errorNotification(
          "Factura a tercero generada con éxito pero está pendiente de CAE ya que ocurrió el siguiente error en ARCA: " +
            errorValidacionAFIP,
          6000,
        );
      } else if (
        msgError.includes("no se encuentra caracterizado como GRANDE")
      ) {
        errorNotification(
          "El cliente no se encuentra caracterizado como GRANDE, por lo que no puede generar facturas de este tipo.",
        );
      } else if (
        msgError.includes(
          "Ya existe un cliente con ese CUIT o DNI en la sucursal",
        )
      ) {
        errorNotification(msgError);
      } else {
        errorNotification("Error al facturar la venta, por favor reintente");
      }
      setLoading(false);
      setOpenBackdrop(false);
    }
  };

  const closeModalErrorAFIP = (dev, nroFactura, type, errorFactura) => {
    let idVentaError = ventaSelected.id || ventaSelected.idVenta || null;
    setDataErrorAFIP((prev) => ({
      ...prev,
      devoluciones: dev,
      nroFactura: nroFactura,
      type: type,
      open: true,
      venta: idVentaError,
      errorFactura: errorFactura,
    }));
    setOpenModalFacturarATerceros(false);
  };

  const handleSetComprobanteSelected = (comp, setFieldValue) => {
    const newComprobSelected = comprobantes.filter(
      (comprobante) => comprobante.nombre === comp,
    )[0];
    setComprobanteSelected(
      comprobantes.filter((comprobante) => comprobante.nombre === comp)[0],
    );
    setFieldValue("tipo_comprobante_id", newComprobSelected.idTipoComprobante);
  };

  const handleGetComprobantes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readComprobantes,
      });
      hanldeResponseGetComprobante(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getEstadosTributarios = async () => {
    try {
      const response = await request({
        method: "GET",
        url: listEstadoTributario,
      });
      setEstadosTributario(filtrarEstadosTributarios(response.data));
    } catch (error) {
      console.error(error);
      setEstadosTributario([]);
    }
  };

  const hanldeResponseGetComprobante = (response) => {
    if (response.status !== 204) {
      if (configGeneral.es_m) {
        let comprobantesGet = response.data.data
          .reverse()
          .filter((c) => c.nombre !== "Comprobante interno");
        setComprobantes(comprobantesGet);
      } else {
        let comprobantesGet = response.data.data
          .reverse()
          .filter(
            (c) => c.nombre !== "Comprobante interno" && c.nombre !== "M",
          );
        setComprobantes(comprobantesGet);
      }
    }
  };

  const filtrarEstadosTributarios = (estados) => {
    return estados.filter(
      (e) =>
        e.descripcion === "Monotributista" ||
        e.descripcion === "Responsable inscripto" ||
        e.descripcion === "Exento" ||
        e.descripcion === "IVA no alcanzado" ||
        e.descripcion === "Sin especificar" ||
        e.descripcion === "Consumidor final",
    );
  };
  if (!open) {
    return null;
  }

  const validateFcemA = () => {
    let tieneNc =
      ventaSelected && ventaSelected.total_nc && ventaSelected.total_nc > 0;
    let tieneNc2 =
      ventaSelected &&
      ventaSelected.nc_list &&
      ventaSelected.nc_list.length > 0;

    if (
      (comprobanteSelected &&
        comprobanteSelected.nombre === "FCEM A" &&
        tieneNc) ||
      (comprobanteSelected &&
        comprobanteSelected.nombre === "FCEM A" &&
        tieneNc2)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <ModalReutilizable
        open={open}
        openBackdrop={openBackdrop}
        title={"Facturar a terceros"}
        subtitle={`Venta N°${
          ventaSelected.id || ventaSelected.idVenta
        } - Monto: $ ${Number(ventaSelected.montoTotal).toLocaleString(
          "es-AR",
        )}`}
        content={
          validateDateClient ? (
            <ConfirmacionDataCliente
              values={dataFacturacion}
              estadosTributario={estadosTributario}
            />
          ) : (
            <FormularioClienteTercero
              onSubmit={validacionDataCliente}
              comprobantes={comprobantes}
              formRef={formRef}
              estadosTributario={estadosTributario}
              ventaSelected={ventaSelected}
              estadoTributarioValue={estadoTributarioValue}
              setEstadoTributarioValue={setEstadoTributarioValue}
              comprobanteSelected={comprobanteSelected}
              handleSetComprobanteSelected={handleSetComprobanteSelected}
              opcionesTransferencia={opcionesTransferencia}
              setOpcionesTransferencia={setOpcionesTransferencia}
              vencimientoDelPago={vencimientoDelPago}
              setVencimientoDelPago={setVencimientoDelPago}
              setComprobanteSelected={setComprobanteSelected}
              validateFcemA={validateFcemA}
              setCuit={setCuit}
              isCuit={isCuit}
              montoMaximo={montoMaximo}
              clienteAfip={clienteAfip}
              setClienteAfip={setClienteAfip}
              actionCargaCliente={actionCargaCliente}
              setActionCargaCliente={setActionCargaCliente}
              useClient={useClient}
              useQuery={useQuery}
              formRefNuevo={formRefNuevo}
            />
          )
        }
        handleClose={cerrarModal}
        actions={
          validateDateClient ? (
            <>
              <ButtonCancelar
                click={() => setValidateDateClient(false)}
                message={"Cancelar"}
                disabled={false}
              />
              <ButtonGeneral
                disabled={isLoading || validateFcemA()}
                click={() => factuarATerceros(dataFacturacion)}
                message={"Confirmar y facturar"}
              />
            </>
          ) : (
            <>
              <ButtonCancelar
                click={cerrarModal}
                message={"Cancelar"}
                disabled={isLoading}
              />
              <ButtonGeneral
                disabled={isLoading || validateFcemA()}
                click={() =>
                  actionCargaCliente === 0
                    ? formRef.current.handleSubmit()
                    : formRefNuevo.current.handleSubmit()
                }
                message={"Facturar"}
              />
            </>
          )
        }
      />
    </>
  );
};

export default ModalFacturarClienteTercero;
