import { Document, Page, View } from "@react-pdf/renderer";
import React from "react";
import { LabelComponent } from "../SingleEtiqueta/SizeLabel";
import { styles } from "../SingleEtiqueta/utils";

export default function EtiquetasA4({
  articulo,
  sucursal,
  ancho,
  alto,
  filasArray,
  columnasArray,
  configGeneral,
  configuracionEtiqueta,
}) {
  const Component = LabelComponent(ancho + "x" + alto);

  return (
    //A4 => 584,2px/210mm(ancho) x 840,08px/297mm(alto)
    <Document title={`Etiqueta-${ancho}x${alto}-A4`}>
      <Page size="A4" style={styles.pageA4}>
        <View style={styles.contenedor}>
          {filasArray.array.map((_x, iF) => (
            <View style={styles.fila} key={iF}>
              {columnasArray.array.map((_x, iC) => (
                <View style={{ padding: 5 }} key={iC}>
                  <Component
                    sucursal={sucursal}
                    articulo={articulo}
                    configGeneral={configGeneral}
                    configuracionEtiqueta={configuracionEtiqueta}
                  />
                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}
