import React from "react";
import { Grid, Card, CardContent, CardActions } from "@material-ui/core";
import Pagination from "react-js-pagination";
import ReusableTable from "./ReusableTable";
import Spinner from "../../Spinner";

const ReportTable = ({
  billetera,
  items,
  columns,
  loading,
  handleClickRow,
  pagination,
  setPagination,
  children,
  className,
  noResults,
  checkbox,
  heightStyle,
  colorRow,
  itemsCountPerPage = 10, // 10 por defecto, si se le pasa la prop, la cantidad va ser la especificada en la prop
  exclude = [], // It's an array of keys to ignore in the render process
}) => {
  return (
    <Card
      className={className + " pd-5"}
      style={{ boxShadow: billetera !== undefined && "none" }}
    >
      <CardContent
        style={{ padding: billetera !== undefined && 0 }}
        className={"pb-0"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {children}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} className={"text-center"}>
            <ReusableTable
              columns={columns}
              items={items}
              checkbox={checkbox ? checkbox : undefined}
              handleClickRow={handleClickRow}
              noResults={noResults}
              heightStyle={heightStyle}
              colorRow={colorRow ? colorRow : undefined}
              exclude={exclude}
              loading={loading}
            />
          </Grid>
        </Grid>
      </CardContent>
      {pagination && (
        <CardActions>
          {!loading && items.length !== 0 && (
            <Grid
              container
              spacing={2}
              className="d-flex mt-3 mb-0 justify-content-center"
            >
              <Pagination
                activePage={pagination.page}
                itemsCountPerPage={itemsCountPerPage}
                totalItemsCount={pagination.num_items}
                pageRangeDisplayed={3}
                onChange={(event) => {
                  setPagination({ ...pagination, page: event });
                }}
                itemClass="page-item"
                linkClass="page-link"
              />
            </Grid>
          )}
        </CardActions>
      )}
    </Card>
  );
};

export default ReportTable;
