import { View } from "@react-pdf/renderer";
import React from "react";
import { ETIQUETA_ESTILO, mostrarCodigosArticulo } from "./Design44x55";
import {
  mostrarDescripcionArticuloExtendida,
  mostrarFechaImpresion,
  mostrarLogoSucursal,
  mostrarNombreProveedor,
  mostrarNombreSucursal,
  mostrarPrecioArticulo,
  mostrarUbicacionArticulo,
  styles,
  viewCodBarra,
} from "./utils";

export default function Label44x55({
  sucursal,
  articulo,
  configGeneral,
  configuracionEtiqueta,
}) {
  //1px = 0.36 mm
  return (
    <View
      style={{
        ...styles.contenedorTicket,
        width: 122.2,
        minWidth: 122.2,
        maxWidth: 122.2,
        height: 152.7,
        minHeight: 152.7,
        maxHeight: 152.7,
        padding: 7,
      }}
    >
      {/* NOMBRE SUCURSAL */}
      {configuracionEtiqueta.nombreNegocio && sucursal
        ? mostrarNombreSucursal(
            sucursal.razonSocial,
            ETIQUETA_ESTILO.midFontSize,
            ETIQUETA_ESTILO.maxCharBaseFontSize,
            ETIQUETA_ESTILO.baseMarginBottom,
          )
        : null}

      {/* CODIGOS ARTICULO */}
      {configuracionEtiqueta &&
        articulo &&
        mostrarCodigosArticulo(configuracionEtiqueta, articulo)}

      {/* DESCRIPCION */}
      {configuracionEtiqueta.descripcion && articulo
        ? mostrarDescripcionArticuloExtendida(
            articulo.descripcionProveedor,
            ETIQUETA_ESTILO.baseFontSize,
          )
        : null}

      {/* PROVEEDOR Y PRECIO */}
      <View style={styles.viewProveedorPrecio}>
        {configuracionEtiqueta.nombreProveedor ||
        configuracionEtiqueta.numeroProveedor
          ? mostrarNombreProveedor(
              articulo.proveedor,
              configuracionEtiqueta.nombreProveedor,
              articulo.numero_proveedor,
              configuracionEtiqueta.numeroProveedor,
              ETIQUETA_ESTILO.minFontSize,
            )
          : null}
        {configuracionEtiqueta.precio
          ? mostrarPrecioArticulo(
              configuracionEtiqueta,
              articulo,
              ETIQUETA_ESTILO.baseMarginBottom,
              ETIQUETA_ESTILO.minFontSize,
            )
          : null}
      </View>

      {/* CODIGO BARRA + LOGO */}
      <View style={styles.viewCodeBar}>
        {configuracionEtiqueta.logotipo
          ? mostrarLogoSucursal(configGeneral.url_imagen)
          : null}

        {configuracionEtiqueta
          ? viewCodBarra(
              configuracionEtiqueta,
              articulo,
              Boolean(configuracionEtiqueta.logotipo),
            )
          : null}
      </View>

      {/* UBICACION + FECHA DE IMPRESION */}
      <View style={styles.viewUbicacionFecha}>
        {configuracionEtiqueta.ubicacion
          ? mostrarUbicacionArticulo(
              articulo.repuesto_sucursal,
              ETIQUETA_ESTILO.minFontSize,
              40,
            )
          : null}

        {configuracionEtiqueta.fechaImpresion
          ? mostrarFechaImpresion(ETIQUETA_ESTILO.minFontSize)
          : null}
      </View>
    </View>
  );
}
