import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { parseCurrency } from "../../../../utils/parsers";

export default function DetalleArticulo({ articulo }) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">Detalles del Artículo:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid item container display="flex" alignItems="center" xs={12}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
            }}
            className="pr-1"
          >
            Descripción:
          </Typography>
          <Typography color="textSecondary">
            {articulo.descripcion ||
              articulo.articuloClicked.descripcionProveedor}
          </Typography>
        </Grid>
        <Grid item xs={12} container display="flex" alignItems="center">
          <Typography
            style={{ fontSize: "1rem", fontWeight: "bold" }}
            className="pr-1"
          >
            Precio Costo:
          </Typography>
          <Typography color="textSecondary">
            {parseCurrency(
              articulo.precios
                ? articulo.precios.costo
                : articulo.articuloClicked
                  ? articulo.articuloClicked.precios.costo
                  : 0,
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid item xs={12} container display="flex" alignItems="center">
          <Typography
            style={{ fontSize: "1rem", fontWeight: "bold" }}
            className="pr-1"
          >
            Stock disponible:
          </Typography>
          <Typography color="textSecondary">
            {articulo.repuesto_sucursal
              ? articulo.repuesto_sucursal.stockDisponible
              : articulo.articuloClicked &&
                  articulo.articuloClicked.repuesto_sucursal
                ? articulo.articuloClicked.repuesto_sucursal.stockDisponible
                : "No maneja stock"}
          </Typography>
        </Grid>
        <Grid item xs={12} container display="flex" alignItems="center">
          <Typography
            style={{ fontSize: "1rem", fontWeight: "bold" }}
            className="pr-1"
          >
            Stock deseado:
          </Typography>
          <Typography color="textSecondary">
            {articulo.repuesto_sucursal
              ? articulo.repuesto_sucursal.stock_deseado
              : articulo.articuloClicked &&
                  articulo.articuloClicked.repuesto_sucursal
                ? articulo.articuloClicked.repuesto_sucursal.stock_deseado
                : "No maneja stock"}
          </Typography>
        </Grid>
        <Grid item container xs={12} display="flex" alignItems="center">
          <Typography
            style={{ fontSize: "1rem", fontWeight: "bold" }}
            className="pr-1"
          >
            Código Prov.:
          </Typography>
          <Typography color="textSecondary">
            {articulo.articuloClicked
              ? articulo.articuloClicked.codProveedor
              : articulo.codProveedor || "---"}
          </Typography>
        </Grid>
        <Grid item container xs={12} display="flex" alignItems="center">
          <Typography
            style={{ fontSize: "1rem", fontWeight: "bold" }}
            className="pr-1"
          >
            Código Original:
          </Typography>
          <Typography color="textSecondary">
            {articulo.articuloClicked
              ? articulo.articuloClicked.codOriginal
              : articulo.codOriginal || "---"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
