import { Document, Page } from "@react-pdf/renderer";
import React from "react";
import { LabelComponent } from "../SingleEtiqueta/SizeLabel";
import { getPageSize, styles } from "../SingleEtiqueta/utils";

export default function EtiquetasTicket({
  alto,
  ancho,
  sucursal,
  articulo,
  configGeneral,
  configuracionEtiqueta,
}) {
  const Component = LabelComponent(ancho + "x" + alto);

  return (
    <Document title={`Etiqueta-${ancho}x${alto}-Ticket`}>
      <Page size={getPageSize(ancho, alto)} style={styles.page}>
        <Component
          alto={alto}
          ancho={ancho}
          sucursal={sucursal}
          articulo={articulo}
          configGeneral={configGeneral}
          configuracionEtiqueta={configuracionEtiqueta}
        />
      </Page>
    </Document>
  );
}
